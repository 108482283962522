import { Tabs } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { SectionSetting } from '../../../core/json/SectionSetting';
import { addSectionSettings, getSectionSetting } from '../../../services/MasterApiServices';
import toast from 'react-hot-toast';
import{ SectionSettingViewModel } from '../../../core/json/SectionSettingViewModal'
import { SectionClass } from '../../../core/json/SectionClass';
import { TaxClass } from '../../../core/json/TaxClass';
const { TabPane } = Tabs;

function SectionSettings({ data }) {
  const {taxList} = useSelector((state) => state.usersetting);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } =useSelector((state)=>state.userauth);
  const { register, handleSubmit, reset ,setValue, watch,  formState: { errors },} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
useEffect(() => {
  if(data){
    handlesectionsetting()
  }
}, [data])

const handlesectionsetting = async () => {
  const objCounter = new SectionSetting();
  const objsection = new SectionClass();
  objsection.Branch = branch;
  objsection.guid = data.guid;
  objsection.name = data.name;
  objsection.id = data.id;
  objCounter.Section = objsection;
  try {

    const response = await getSectionSetting(objsection)
    
   if(response){

     setValue('enableKOTPrinting',response.enableKOTPrinting)
     setValue('printKOTInSalesFormat',response.printKOTInSalesBillFormat)
     setValue('printKOTInSalesBillPrinter',response.printKOTInSalesBillPrinter)
     setValue('printBothSalesAndKOT',response.printBothSalesAndKOTPrint)
     setValue('showpopuptocapturecustomerdetail',response.showPopupToCaptureCustomerDetail)
     setValue('showpopuptoselectwaiter',response.showPopupToSelectWaiter)
     setValue('showpopuptoselecttable',response.showPopupToSelectTable)
     setValue('printfilename',response.printFileName)
     setValue('numberOfInvoicePrints',response? response.numberOfInvoicePrints : 0)
     setValue('billstartnumber',response.billStartNumber || 0)
     setValue('invoiceprefix',response.invoicePrefix || ' ') 
     setValue('tax',response?.tax? response?.tax.guid : ' ') 
  
   }
    //setValue('branch',response.branch.guid)
    
  }
  catch (error) {
    console.error("Error handling Section:", error?.response?.data?.Message);
  }
}
  const handleReset = () => {
    reset();
  };


  console.log(watch().numberOfInvoicePrints);
  
  const handleFormSubmit = async (formData) => {
    const objsectionsettings = new SectionSetting();
    const objsection = new SectionClass();
    const objtax = new TaxClass();
    objsection.Branch = branch;
    objsection.guid = data.guid;
    objsection.name = data.name;
    objsectionsettings.Section = objsection; 
    objsectionsettings.id = data.id; 
    objsectionsettings.EnableKOTPrinting = formData.enableKOTPrinting;
    objsectionsettings.PrintKOTInSalesBillFormat = formData.printKOTInSalesFormat;
    objsectionsettings.PrintKOTInSalesBillPrinter = formData.printKOTInSalesBillPrinter;
    objsectionsettings.PrintBothSalesAndKOTPrint = formData.printBothSalesAndKOT;
    objsectionsettings.ShowPopupToCaptureCustomerDetail = formData.showpopuptocapturecustomerdetail;
    objsectionsettings.ShowPopupToSelectWaiter = formData.showpopuptoselectwaiter;
    objsectionsettings.ShowPopupToSelectTable = formData.showpopuptoselecttable;
    objsectionsettings.PrintFileName = formData.printfilename;
    objsectionsettings.NumberOfInvoicePrints = Number(formData.numberOfInvoicePrints);
    objsectionsettings.BillStartNumber = Number(formData.billstartnumber);
    objsectionsettings.InvoicePrefix = formData.invoiceprefix;
    objsectionsettings.Tax = taxList?.find(e=>e.guid==formData.tax) || objtax;
    objsectionsettings.TaxGuid = formData.tax || null;
    objsectionsettings.PrintTempFile = null;
    objsectionsettings.updatedUser = `{${userId?.id}}`;
    objsectionsettings.createdUser = `{${userId?.id}}`;

    try {
      const response = await addSectionSettings(objsectionsettings);
      if (response == true) {
        // fields.forEach((_, index) => remove(index))
        toast.success("success")
        closemodal()
      }
      else {
        toast.error(response)
        console.log(response);
        
      }

    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling Section:", error?.response?.data?.Message);
    }
  };
  const closeButtonRef = useRef();

     const closemodal = () => {
       if (closeButtonRef.current) {
         closeButtonRef.current.click();
        
       }
     };
  return (
    <div className="modal fade" tabIndex="-1"  id="sectionsettings">
      <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h3>Section settings ({data?.name})</h3>
                </div>
                <button
                  type="button"
                  className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ref={closeButtonRef}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <form
                className="col-12 align-items-center justify-content-between p-5"
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                {errors?.printfilename?.type === "required" && (
                            <span className="text-danger">* Print Filename is required</span>
                          )}
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Printing Settings" key="1">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="checkbox"
                          {...register('enableKOTPrinting')}
                        />
                        Enable KOT Printing
                      </div>
                      <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                        When this setting is enabled, a KOT print will be
                        generated in the kitchen.
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="checkbox"
                          {...register('printKOTInSalesFormat')}
                        />
                        Print KOT in sales bill format
                      </div>
                      <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                        A KOT print will contain only the name and quantity of
                        food to be prepared. If the option Print KOT in sales
                        bill format is selected, KOT print will contain
                        additional details similar to the sales bill.
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="checkbox"
                          {...register('printKOTInSalesBillPrinter')}
                        />
                        Print KOT in sales bill printer
                      </div>
                      <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                        Selecting this option will print both KOT and sales bill
                        on the receipt printer.
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="checkbox"
                          {...register('printBothSalesAndKOT')}
                        />
                        Print both sales and KOT print
                      </div>
                      <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                        This option will print both sales and KOT when an order
                        is saved or cashed for the section, commonly used for
                        delivery sections.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                          No.of invoice prints
                        </label>
                       <input
                          className='form-control'
                            type="number"
                            min={0}
                            defaultValue={0}
                            id="name"
                            {...register("numberOfInvoicePrints")}
                        
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Print Format Name<span className='text-danger'> *</span></label>
                          <input
                            className={`form-control ${errors.printfilename ? ' border-danger' : ''}`}
                            type="text"
                            min={0}
                            {...register('printfilename', { required: true })}
                          />
                        </div>
                      </div>
                      </div>

                  </TabPane>

                 
                  <TabPane tab="Others" key="2">
                  <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="checkbox"
                          {...register('showpopuptocapturecustomerdetail')}
                        />
                        Show popup to capture customer detail
                      </div>
                      <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                      If enabled, a popup is shown to capture the customer detail or vehicle numbers whom the food to be delivered. This is normally used when customers cannot walkin/enter into the restaurant
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="checkbox"
                          {...register('showpopuptoselectwaiter')}
                        />
                       Show popup to enter waiter information
                      </div>
                      <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                      If enabled, a popup is shown to capture the waiter information. Detail of waiter who takes the order
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="checkbox"
                          {...register('showpopuptoselecttable')}
                        />
                       Show popup to enter table information
                      </div>
                      <div className="col-lg-9 col-md-6 col-sm-12 general_decription">
                      If enabled, a popup is shown to capture information (number or other identifiers) of the table where the order being taken for
                      </div>
                    </div>

                   
                  
                      <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                          <label>
                          Bill Start Number
                        </label>
                       <input
                          className='form-control'
                            type="number"
                            min={0}
                            defaultValue={0}
                            id="name"
                            {...register("billstartnumber")}
                            
                          />
                        </div>
                      </div>
                      
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                          <label>
                          Invoice Prefix
                        </label>
                       <input
                          className='form-control'
                            type="text"
                            min={0}
                            id="name"
                            {...register("invoiceprefix")}
                            
                          />
                        </div>
                      </div>

                      
                      </div>
                      <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                          <label>
                          Tax
                        </label>
                        <select
                  {...register("tax")}
 
                  className=" form-control"
                  
                >
                  <option value="t" disabled>
                    Select Tax
                  </option>
                  {taxList?.map((category) => (
                    <option key={category.guid} value={category.guid}>
                      {category.name}
                    </option>
                  ))}
                </select>
                        </div>
                      </div>
                      </div>
                  </TabPane>
                </Tabs>
               <div className="col-lg-12">
                  <div className="modal-footer-btn">
                    <button
                      type="button"
                      className="btn btn-cancel me-2"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                    <button type="submit" className="btn btn-save">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionSettings;
