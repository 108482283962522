import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  addNewReasonType,
  editReasonType,
} from "../../../services/MasterApiServices";
import toast from "react-hot-toast";
import { ReasonTypes } from "../../../core/json/ReasonTypes";
import { useSelector } from "react-redux";

const ReasonTypeModal = ({ mode, data, handleClose, handleRefresh }) => {
  const {register, handleSubmit, setValue, reset} = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  function resetmodal(){
    if (mode === "edit" && data) {
      // Populate form with data for editing
      setValue("name", data.name);
    }else{reset();}

  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);
  console.log(data,"daata");
  

  // Handle form submission for adding or editing a modifier category
  const handleFormSubmit = async (formData) => {
    const objreasontype = new ReasonTypes();
    objreasontype.name = formData.name;
    objreasontype.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}";
    if (mode === "edit") {
      objreasontype.guid = data.guid;
      objreasontype.id = data.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editReasonType(objreasontype)
          : await addNewReasonType(objreasontype);

      if (response) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      }else if(response==false){
        toast.error("false")
       }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling category:", error?.response?.data?.Message);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit Reason Type" : "Add Reason type"}
                    </h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReasonTypeModal;
