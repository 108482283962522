import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import Select from "react-select";
import "react-folder-tree/dist/style.css";
import {
  addNewPayment,
  addNewReceipt,
  getAccountledger,
  getcashorbank,
  getPaymentByGuid,
  getReceiptByGuid,
} from "../../services/AccountApiServices.js";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ReceiptEntry } from "../../core/json/ReceiptEntry.js";
import { getCurrencyList } from "../../services/MasterApiServices.js";
import {
  Calendar,
  PlusCircle,
} from "feather-icons-react/build/IconComponents/index.js";
import { FinancialYear } from "../../core/json/FinancialYear.js";
import { PaymentEntry } from "../../core/json/PaymentEntry.js";
import { Currency } from "../../core/json/Currency.js";
import { fetchBranchSettings } from "../../redux/settingbyBranch";
import dayjs from "dayjs";
import { DatePicker } from "antd";

const PaymentModal = ({ mode, data, handleClose, handleRefresh }) => {
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { companyInfolist } = useSelector((state) => state.companyInfo);
  const { userId } = useSelector((state) => state.userauth);
  const [ledgerData, setLedgerData] = useState();
  const [cashorbank, setcashorbank] = useState();
  const [currencyData, setCurrencyData] = useState();
  // const [selectedOption, setselectedOption] = useState(null);
  const [isRowAdded, setIsRowAdded] = useState(false);
  const { branchsettings } = useSelector((state) => state.settings);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  useEffect(() => {
    resetmodal();
  }, [data, setValue]);
  useEffect(() => {
    if (mode === "add") {
      handleEntryNo();
      if (!isRowAdded && fields?.length === 0) {
        handleAddRow();
        setIsRowAdded(true);
      }
    }
    handleLedgerList();
    handleCurrencyList();
    handlecashorbankList();
    // handleAddRow();
    dispatch(fetchBranchSettings(branch.guid));
  }, []);

  const handleAddRow = () => {
    append({
      narration: "",
      amount: "0",
      ledgerID: null,
    });
  };
  function handleRemove(index) {
    if (fields.length > 1) {
      remove(index);
    } else {
      toast.error("At least one product entry is required");
    }
  }
  //Calculations ---------------
  let totalNet = 0;
  fields.forEach((field, index) => {
    const amount = watch(`items[${index}].amount`, 0);
    console.log("amount", amount);
    totalNet += parseFloat(amount);
  });
  //end-------------
  function resetmodal() {
    if (mode === "edit" && data) {
      const resetData = {
        no: data?.no,
        date: dayjs(data?.date),
        cash: data?.ledgerID,
        narration: data?.narration,
        items: data?.paymentDetails?.map((detail) => ({
          ledgerID: detail?.ledgerID,
          amount: detail?.amount,
          narration: detail?.narration,
        })),
      };
      reset(resetData);
    } else {
      reset();
      handleEntryNo();
    }
  }

  const handleEntryNo = async () => {
    try {
      const response = await getPaymentByGuid(branch);

      const receiptData = response;
      // Find the maximum number from the existing entries
      const maxEntryNumber =
        receiptData?.length > 0
          ? receiptData
              ?.map((data) => parseInt(data.no))
              ?.reduce((max, num) => Math.max(max, num), 0) // Find the maximum number
          : 0;
      let counter = maxEntryNumber + 1;
      setValue(`no`, counter);
      counter++;
    } catch (error) {
      console.error("Failed to fetch payments data", error);
    }
  };

  const handleFormSubmit = async (formdata) => {
    const objpayment = new PaymentEntry();
    if (mode === "edit") {
      console.log("edit mode on--------");
      console.log(data.guid, "data.guid");

      objpayment.guid = data.guid;
      objpayment.id = data.id;
    }
    const objfinancialyear = new FinancialYear();
    const objcurrency = new Currency();
    objcurrency.guid = branchsettings?.primaryCurrencyGUID;
    objfinancialyear.guid = branchsettings?.defaultFinancialYearGuID;
    objpayment.FinancialYear = objfinancialyear;
    objpayment.No = formdata.no;
    objpayment.Date = formdata.date
      ? dayjs(formdata.date).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");
    objpayment.LedgerID = formdata.cash;
    objpayment.Currency = objcurrency;
    objpayment.Narration = formdata.narration;

    objpayment.CurrencyRate = 0;
    objpayment.Branch = branch;
    objpayment.updatedUser = `{${userId?.id}}`;
    objpayment.createdUser = `{${userId?.id}}`;
    objpayment.Company = companyInfolist[0];
    objpayment.totalAmt = parseFloat(totalNet);
    const detailObj = formdata?.items?.map((e) => ({
      ledgerID: e.ledgerID,
      Amount: e.amount,
      Narration: e.narration,
    }));

    objpayment.paymentDetails = detailObj;

    try {
      const response = await addNewPayment(objpayment);

      if (response.data === true) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "Something went wrong");
      console.error("Error handling payment:", error);
    }
  };
  const handleLedgerList = async () => {
    try {
      const response = await getAccountledger();

      if (Array.isArray(response)) {
        setLedgerData(
          response?.map((e) => ({
            value: e.guid,
            label: e.name,
          }))
        );
      }
    } catch (error) {
      console.error(
        error?.response?.data?.Message || "Error fetching ledger data"
      );
      setLedgerData([]);
    }
  };
  const handlecashorbankList = async () => {
    try {
      const response = await getcashorbank();
      if (Array.isArray(response?.data)) {
        setLedgerData(
          response?.data?.map((e) => ({
            value: e.guid,
            label: e.name,
          }))
        );
      } else {
        setcashorbank([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "Something went wrong");
      setcashorbank([]);
    }
  };
  const handleCurrencyList = async () => {
    try {
      const response = await getCurrencyList();

      if (Array.isArray(response)) {
        setCurrencyData(response);
      } else {
        setCurrencyData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message);
      setCurrencyData([]);
    }
  };

  return (
    <div className="modal fade show" style={{ display: "block" }} tabIndex="-1">
      <div className="modal-dialog add-centered">
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
                <h4> {mode === "edit" ? "Edit Payment" : "Add Payment"}</h4>
                <button type="button" className="close" onClick={handleClose}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12 mb-3">
                      <label>
                        No <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("no")}
                        required
                        disabled
                      />
                    </div>

                    <div className="col-lg-4 col-sm-6 col-12 mb-3">
                      <div className="input-blocks">
                        <label>Date</label>
                        <div className="input-groupicon calender-input">
                          <Calendar className="info-img" />
                          <Controller
                            control={control}
                            name="date"
                            render={({ field: { onChange, value } }) => (
                              <DatePicker
                                className="datetimepicker"
                                value={value ? dayjs(value) : dayjs()} // Set value or default to today
                                format="DD-MM-YYYY"
                                onChange={(date) =>
                                  onChange(
                                    date ? date.format("YYYY-MM-DD") : null
                                  )
                                } // Update on change
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 mb-3">
                      <label>Remarks</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("narration")}
                      />
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12 mb-3">
                      <div className="input-blocks">
                        <label className="form-label">
                          cash/bank<span className="text-danger">*</span>
                        </label>
                        <div className="row">
                          <Controller
                            control={control}
                            name={`cash`}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                classNamePrefix="react-select"
                                options={cashorbank}
                                isClearable
                                placeholder="Cash/Bank"
                                value={
                                  cashorbank?.find(
                                    (cashorbank) => cashorbank?.value === value
                                  ) || null
                                }
                                onChange={(selectedOption) =>
                                  onChange(selectedOption?.value || null)
                                }
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                required
                              />
                            )}
                          />
                        </div>
                      </div>
                      {/* <select
                        {...register("cash", { required: true })}
                        className="form-control"
                      >
                        <option value="">Select</option>
                        {ledgerData?.map((category) => (
                          <option key={category.value} value={category.value}>
                            {category.label}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                  <div className="table-responsive mb-3 table-container  ">
                    <table className="table table-striped table-bordered ">
                      <thead className="sticky-header">
                        <tr>
                          <th className="sticky-header">
                            Ledger<span className="text-danger">*</span>
                          </th>
                          <th className="sticky-header">
                            Amount<span className="text-danger">*</span>
                          </th>
                          <th className="sticky-header">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields?.map((obj, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Controller
                                  control={control}
                                  name={`items[${index}].ledgerID`}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      classNamePrefix="react-select"
                                      options={ledgerData}
                                      isClearable
                                      value={
                                        ledgerData?.find(
                                          (option) => option?.value === value
                                        ) || null
                                      }
                                      onChange={(selectedOption) => {
                                        onChange(selectedOption?.value) || null;
                                      }}
                                      styles={{
                                        menu: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                          position: "relative",
                                        }),
                                      }}
                                      required
                                    />
                                  )}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register(`items[${index}].amount`, {
                                    required: "amount is required",
                                    validate: (value) =>
                                      (!isNaN(value) && value > 0) ||
                                      "amount must be a positive number",
                                  })}
                                />
                                {errors.items &&
                                  errors.items[index]?.amount && (
                                    <span className="text-danger small">
                                      {errors.items[index].amount.message}
                                    </span>
                                  )}
                              </td>

                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register(`items[${index}].narration`)}
                                />
                              </td>
                              <td>
                                <Link
                                  className="confirm-text p-2"
                                  onClick={() => handleRemove(index)}
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  ></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div></div>
                  <div className="row">
                    <div className="col-lg-2 col-sm-2 col-2 ">
                      <button
                        className=" btn btn-dark add-icon tab  "
                        type="button"
                        onClick={handleAddRow}
                      >
                        <PlusCircle className="feather-plus-circles" />
                      </button>
                    </div>
                    <div className="col-lg-4 ms-auto">
                      <div className="total-order w-100 max-widthauto m-auto mb-4">
                        <ul>
                          <li>
                            <h4>Net Total</h4>

                            <h5> {totalNet?.toFixed(2)}</h5>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label>Net Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("total")}
                    />
                  </div> */}
                  <div className="col-lg-12 text-end">
                    <button
                      type="button"
                      className="btn btn-cancel add-cancel me-3"
                      onClick={() => resetmodal()}
                    >
                      Reset
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
