import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  addNewProduct,
  getGroupEntryByGuid,
  getUnitguid,
} from "../../../services/MasterApiServices";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AddProductProfile from "./Product/AddProductProfile";
import AddProductSection from "./Product/AddProductSection";
import AddOtherDescription from "./Product/AddOtherDescription";
import toast from "react-hot-toast";
import { ComboProductDetail } from "../../../core/json/ComboProductDetail";
import { ProductClass } from "../../../core/json/Product";
import { SectionClass } from "../../../core/json/SectionClass";
import { fetchProduct } from "../../../redux/userSetting";

const AddProduct = ({ handleClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const {
    sectionList,
    taxList,
    categoryList,
    deliveyTypes,
    productList,
    unitList,
    groupList,
  } = useSelector((state) => state.usersetting);

  const section = sectionList?.map((e, i) => ({
    IsActive: false,
    Section: e?.guid,
    Price: "",
    Tax: null,
    IsTaxIncludedInPrice: false,
  }));
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    control,
    trigger
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      items: [{ name: "", maxItems: "", comments: "" }],
      sectionWiseProductDetailList: section,
      name: "",
      shortName: "",
      code: "",
      barCode: "",
      category: "",
      type: "",
      group: "",
      unit: "",
      cost: "",
      basePrice: "",
      otherDiscription: "",
      arabicDescription: "",
      hsnCode: "",
      isRateVaryProduct: false,
      isDailyStockItem: false,
      isMenuItem: false,
    },
  });

  const { userId } = useSelector((state) => state.userauth);
  const { branch } = useSelector((state) => state.branchlist);
 
 
  // Handle form submission for adding or editing a category

  const comboType = deliveyTypes?.find((type) => type.name === "Combo"); // Find Combo type
  const COMBO_TYPE_ID = comboType?.guid;

  const handleFormSubmit = async (formData) => {
    const objproduct = new ProductClass();
    const objsection = new SectionClass();
    const objcombodetail = new ComboProductDetail();
    const sectionWiseProductDetailList = getValues()
      ?.sectionWiseProductDetailList
      ? getValues()?.sectionWiseProductDetailList?.map((e) => ({
        Section:
          sectionList?.find((item) => item.guid == e.Section) || objsection,
        IsActive: e.IsActive,
        IsTaxIncludedInPrice: e.IsTaxIncludedInPrice,
        Price: Number(e.Price),
        tax: taxList?.find((item) => item.guid == e.Tax),
      }))
      : getValues()?.sectionWiseProductDetail;

    const ComboProductarray =
      watch().type === COMBO_TYPE_ID
        ? formData?.items?.map((e, i) => ({
          sideChoiceNumber: i,
          products: productList?.filter((item) =>
            e?.name?.some((pro) => item.guid === pro.value)
          ),
          quantity: Number(e.maxItems),
        }))
        : null;
    const FixedItem = ComboProductarray?.[0] || null;
    const SideChoices = ComboProductarray?.slice(1) || [];
    objcombodetail.FixedItem = FixedItem;
    objcombodetail.SideChoices = SideChoices;
    objproduct.code = formData.code;
    objproduct.name = formData.name;
    objproduct.shortName = formData.shortName;
    objproduct.barCode = formData.barCode;
    objproduct.category = categoryList?.find(
      (item) => item.guid == formData.category
    );
    objproduct.type = deliveyTypes?.find((item) => item.guid == formData.type);
    objproduct.group = groupList?.find((item) => item.guid == formData.group);
    objproduct.unit = unitList?.find((item) => item.guid == formData.unit);
    objproduct.cost = parseFloat(formData.cost);
    objproduct.basePrice = parseFloat(formData.basePrice);
    objproduct.otherDescription = formData.otherDescription;
    objproduct.isActive = formData.isActive;
    objproduct.sectionWiseProductDetailList = sectionWiseProductDetailList;
    objproduct.comboProductDetail =
      watch().type === COMBO_TYPE_ID ? objcombodetail : null;
    objproduct.arabicDescription = formData.arabicDescription;
    objproduct.isRateVaryProduct = formData.isRateVaryProduct;
    objproduct.isDailyStockItem = formData.isDailyStockItem;
    objproduct.hsnCode = formData.hsnCode;
    objproduct.isMenuItem = formData.isMenuItem;
    objproduct.updatedUser = `{${userId?.id}}`;
    objproduct.createdUser = `{${userId?.id}}`;
    objproduct.branch = branch;

    try {
      const response = await addNewProduct(objproduct);
      if (response == true) {
        handleClose();
        dispatch(fetchProduct(branch));
        toast.success("Success");
      } else if (response == false) {
        toast.error("false");
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling :", error);
    }
  };
  function resetmodal() {
    reset();
  }
  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Product</h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="branch-tabs col-12 align-items-center justify-content-between">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Tabs
                      selectedIndex={tabIndex}
                      onSelect={async (index) => {
                        const isValid = await trigger(); // Validate current tab fields
                        if (isValid) {
                          setTabIndex(index); // Switch tabs if validation passes
                        } else {
                          const firstErrorField = document.querySelector(".error");
                          if (firstErrorField) {
                            firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
                            firstErrorField.focus();
                          }
                          // alert("Please fix the errors in this tab before switching.");
                          toast.error("Please fill ther required fields to continue.");
                        }
                      }}
                    >
                      <TabList>
                        <Tab>Profile</Tab>
                        <Tab>Section Price</Tab>
                        <Tab>Other Description</Tab>
                      </TabList>

                      <TabPanel>
                        <AddProductProfile
                          register={register}
                          watch={watch}
                          control={control}
                          errors={errors}
                          
                        />
                      </TabPanel>
                      <TabPanel>
                        <AddProductSection
                          register={register}
                          getValues={getValues}
                          watch={watch}
                          setValue={setValue}
                          errors={errors}
                          
                        />
                      </TabPanel>
                      <TabPanel>
                        <AddOtherDescription
                          errors={errors}
                          
                          register={register}
                        />
                      </TabPanel>
                    </Tabs>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => {
                            resetmodal();
                            setTabIndex(0); // Reset to the first tab
                          }}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-submit"
                          disabled={!!Object.keys(errors || {}).length}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
