import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector,useDispatch } from 'react-redux';
import Select from 'react-select';
import User from '../../../core/json/User';
import makeAnimated from 'react-select/animated';
import toast from 'react-hot-toast';
import { addUser, updateuser } from '../../../services/AdminApiServices';
import { fetchUser, fetchUserByBranch } from '../../../redux/userServices';
import { label } from 'yet-another-react-lightbox';
import { UserBranchDetail } from '../../../core/json/UserBranchDetail';

const AddUser = ({ mode, data, handleClose }) => {
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        setValue,
        setError,
        values,
        reset,
    } = useForm({
        mode: "onChange", 
        reValidateMode: "onChange", 
    });
    const animatedComponents = makeAnimated();
    const [branchList, setbranchList] = useState([])
    const [branches, setbranches] = useState([])
    const [defaultbranch, setdefaultbranch] = useState()
    const { userRoles } = useSelector((state) => state.usersetting);
    const { userId } =useSelector((state)=>state.userauth)
    const {branch,branchlist} = useSelector((state) => state.branchlist);
    const dispatch = useDispatch()
    useEffect(() => {
        const newlist = branchlist?.map(e => ({
            label: e?.name,
            value: e?.guid
        }))
        setbranchList(newlist)
    }, [])
    useEffect(() => {
        if (mode === 'edit' && data) {
          setValue("name", data.name);
          setValue("contact1", data.primaryContactNo);
          setValue("contact2", data.secondaryContactNo);
          setValue("address1", data.address1);
          setValue("address2", data.address2);
          setValue("address3", data.address3);
          setValue("userrole", data.userRole.guid);
          setValue("username", data.userName);
        //   setValue("password", data.password);
          setValue("email", data.email);
          setValue("usercode", data.userCode);
          setValue("weblogin", data.canAccessWeb);
          const newlist = data?.assignedBranch?.map(e => ({
            label: e.branch.name,
            value: e.branch.guid
        }))
          setbranches(newlist)
          console.log(getValues())       
          const DefaultBranch = data?.assignedBranch?.find(e => e.isDefaultBranch == true);
            const newbranch = (DefaultBranch ? DefaultBranch.branch : data?.assignedBranch[0].branch);
            setdefaultbranch({
              label: newbranch.name,
              value: newbranch.guid
            })
        }
        else{
            setValue("userrole", "");
        }

      }, [data, mode]);
      console.log(branchlist);
      
    const handleFormSubmit = async (formData) => {
        const AssignedBranch = branchlist?.filter(e => 
            branches?.map(branch => branch.value)?.includes(e.guid)
          );
          
       const assgnBranches = AssignedBranch[0]? AssignedBranch : [branch]
       const defBranch = branchlist?.find(e=>defaultbranch?.value== e.guid) || branch
       
        
        const userObj = new User();
        const userclass = new User()
        console.log(assgnBranches);
        
        for (const row of assgnBranches) {
            const branchdetailObj = new UserBranchDetail();
            branchdetailObj.User = userclass;
            branchdetailObj.branch = row;
            branchdetailObj.IsDefaultBranch = row?.guid == defBranch.guid ? true : false;
            userObj.AssignedBranch.push(branchdetailObj);
          }
        userObj.id = data.id;
        userObj.guid = data.guid;
        userObj.Name = formData.name;
        userObj.PrimaryContactNo = formData.contact1;
        userObj.SecondaryContactNo = formData.contact2;
        userObj.Address1 = formData.address1;
        userObj.Address2 = formData.address2;
        userObj.Address3 = formData.address3;
        userObj.UserRole = userRoles?.find(e=>formData.userrole== e.guid); // UserRole Class
        userObj.UserName = formData.username;
        userObj.Password = formData.password;
        userObj.Email = formData.email;
        userObj.UserCode = formData.usercode;  
        userObj.CanAccessWeb = formData.weblogin;
        userObj.Branch = defBranch;
        userObj.updatedUser = `{${userId?.id}}`;
        userObj.createdUser = `{${userId?.id}}`;

        try {
            const response = mode === 'edit'
        ? await updateuser(userObj)
        : await addUser(userObj);
 
      if (response==true) {
        toast.success("Success");
    dispatch(fetchUserByBranch(branch.guid))
    dispatch(fetchUser())
        handleClose(); 
      }
      else
      {
        console.log(response);
      }
           
          } catch (error) {
            toast.error(error?.response?.data?.Message);
            console.error("Error handling Account Type:", error);
            console.log(error)
          }
    }
   
const handlebranches=(e)=>{
console.log(e);
setbranches(e)
if(e?.length>0){
    setdefaultbranch(e[0])
}
}
    return (
        <div>
            <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
                <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                    <h4>{mode === 'edit' ? "Edit User" : "Add User"}</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        onClick={handleClose}
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="col-12 align-items-center justify-content-between">
                                    <div className="modal-body custom-modal-body d-flex">
                                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            Name <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            {...register("name", {required: true })}
                                                            
                                                        />
                                                        {errors.name?.type == "required" && (
                                                            <span className="text-danger">Name is required</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            Primary Contact No <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                        className='form-control'
                                                            type="number"
                                                            id="name"
                                                            {...register("contact1", {required: true })}
                                                            
                                                        />
                                                        {errors.contact1?.type == "required" && (
                                                            <span className="text-danger">Primary Contact is required</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            Secondary Contact No <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            type="number"
                                                            id="name"
                                                            {...register("contact2", {required: true })}
                                                            
                                                        />
                                                         {errors.contact2?.type == "required" && (
                                                            <span className="text-danger">Secondary Contact is required</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            Address 1<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            {...register("address1", {required: true })}
                                                            
                                                        />
                                                         {errors.address1?.type == "required" && (
                                                            <span className="text-danger">Address 2 is required</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            Address 2 <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            {...register("address2", {required: true })}
                                                            
                                                        />
                                                         {errors.address2?.type == "required" && (
                                                            <span className="text-danger">Address 2 is required</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            Address 3
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            {...register("address3")}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            Email
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            {...register("email", {
                                                                required: false,
                                                                pattern:
                                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                                                            })}
                                                        />
                                                        {errors?.email?.type === "pattern" && (
                                                            <span className="text-danger">Email is not valid</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            User Role <span className="text-danger">*</span>
                                                        </label>
                                                        <select name="" id="" {...register("userrole", {required: true })} className='form-control'>
                                                            <option value="" key='' disabled>--select--</option>
                                                            {userRoles?.map((e) => {
                                                                return (

                                                                    <option value={e.guid} key={e.id}>{e.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {errors.userrole?.type == "required" && (
                                                            <span className="text-danger">User role is required</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            User Name <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            {...register("username", {required: true })}
                                                            
                                                        />
                                                         {errors.username?.type == "required" && (
                                                            <span className="text-danger">Username is required</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks">
                                                        <label>
                                                            Password <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            id="name"
                                                            {...register("password", {required: true })}


                                                        />
                                                        {errors.password?.type == "required" && (
                                                            <span className="text-danger">Password is required</span>
                                                        )}
                                                       


                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12 ">
                                                    <div className="input-blocks ">
                                                        <label>
                                                            User Code <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            {...register("usercode", {required: true })}
                                                            
                                                        />
                                                        {errors.usercode?.type == "required" && (
                                                           <span className="text-danger">User Code is required</span>
                                                       )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="input-blocks d-flex mt-lg-4">
                                                        <input
                                                            type="checkbox"
                                                            id="name"
                                                            {...register("weblogin")}

                                                        />
                                                        <label className="m-2">Enable web login

                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-12 ">
                                                    <div className="input-blocks">
                                                        <label>
                                                            Select Branch
                                                        </label>
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            components={animatedComponents}
                                                            placeholder="Select Branch"
                                                            value={branches}
                                                            onChange={handlebranches}
                                                            options={branchList}
                                                        />
                                                    </div>
                                                </div>
                                                {branches?.length > 1 &&
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div className="input-blocks">
                                                            <label>
                                                                Default Branch
                                                            </label>
                                                            <Select
                                                                // defaultValue={defaultbranch}
                                                                value={defaultbranch}
                                                                onChange={setdefaultbranch}
                                                                placeholder="Select Default Branch"

                                                                options={branches}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                              
                                            </div>
                                            <div className="col-lg-12">
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        onClick={handleClose}
                      >
                        Reset
                      </button>
                      <button type="submit" className="btn btn-submit">
                      {mode === 'edit' ? "Save Changes" : "Save"}
                      </button>
                    </div>
                  </div>
                                        </form>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddUser
