import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { savesettings } from '../../services/AdminApiServices';
import toast from 'react-hot-toast';

const SetttingDayClose = () => {
  const { register, handleSubmit, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
 
  const { branchsettings } = useSelector((state) => state.settings);
  const { branch } = useSelector((state) => state.branchlist);


  useEffect(() => {
    setValue("general", {
      timeToClearPoleDisplay: branchsettings?.timeToClearPoleDisplay,
      timeToRefreshPayScreen: branchsettings?.timeToRefreshPayScreen,
      totalAmountForDiscount: branchsettings?.totalAmountForDiscount,
      enableBillSearchByTable: branchsettings?.enableBillSearchByTable,
      tableAlignmentRowNumber: branchsettings?.tableAlignmentRowNumber,
      tableAlignmentColumnNumber: branchsettings?.tableAlignmentColumnNumber,
      showSaleAmountInPoleDisplay: branchsettings?.showSaleAmountInPoleDisplay,
      enableLoyalty: branchsettings?.enableLoyalty,
      enableChairInSale: branchsettings?.enableChairInSale,
      orderProductByReferenceCode: branchsettings?.orderProductByReferenceCode,
      showChangeSectionButtonInPay: branchsettings?.showChangeSectionButtonInPay,
      showSplitButtonInPay: branchsettings?.showSplitButtonInPay,
      showMergeButtonInPay: branchsettings?.showMergeButtonInPay,
      showPaxPopupInSale: branchsettings?.showPaxPopupInSale,
      numberOfKOTPrints: branchsettings?.numberOfKOTPrints,
      applicationBackgroundImageNumber: branchsettings?.applicationBackgroundImageNumber,
      changeApplicationDesign: branchsettings?.changeApplicationDesign,
      hideModifierDetailsInKOTPrint: branchsettings?.hideModifierDetailsInKOTPrint,
      enableDataAutoSyncWithServer: branchsettings?.enableDataAutoSyncWithServer,
      enableVirtualKeyboard: branchsettings?.enableVirtualKeyboard,
      enableCess: branchsettings?.enableCess,
      enableCounterSection: branchsettings?.enableCounterSection,
      isStockProduct: branchsettings?.isStockProduct,
      showUnitPriceOfProductInSale: branchsettings?.showUnitPriceOfProductInSale,
      enablePrintInKitchenManager: branchsettings?.enablePrintInKitchenManager,
      kitchenManagerPrinter: branchsettings?.kitchenManagerPrinter,
      disableMultipleOrderInTable: branchsettings?.disableMultipleOrderInTable,
      viewSalesOrderFromTable: branchsettings?.viewSalesOrderFromTable,
      showCategoryAsCoursewiseInKOT: branchsettings?.showCategoryAsCoursewiseInKOT,
      enableWaiterwiseLogin: branchsettings?.enableWaiterwiseLogin,
      generateQrCodeInBillPrint: branchsettings?.generateQrCodeInBillPrint,
      sendMailWhileDayClose: branchsettings?.sendMailWhileDayClose,
      emailToSendDailyCloseReport: branchsettings?.emailToSendDailyCloseReport,
      arrangePhoneNumberAboveNameInCustomerMenu: branchsettings?.arrangePhoneNumberAboveNameInCustomerMenu,
      disableTaxEditInCreditBill: branchsettings?.disableTaxEditInCreditBill,
      currencyFormat: branchsettings?.currencyFormat,
      dateFormat: branchsettings?.dateFormat,
      defaultUserType: branchsettings?.defaultUserType,
      enableGeneralKeypad: branchsettings?.enableGeneralKeypad,
      generateSaleBillPrint: branchsettings?.generateSaleBillPrint,
      numberOfDaysExtendForBillingAfterDailyClose: branchsettings?.numberOfDaysExtendForBillingAfterDailyClose,
      numberOfDaysForEditBill: branchsettings?.numberOfDaysForEditBill,
      generateSalesInvoiceInDotMatrixPrinter: branchsettings?.generateSalesInvoiceInDotMatrixPrinter,
      hideItemwiseSummaryInDailyClose: branchsettings?.hideItemwiseSummaryInDailyClose,
      hideSectionSummaryInDailyClose: branchsettings?.hideSectionSummaryInDailyClose,
      showDateInDailyClose: branchsettings?.showDateInDailyClose,
      billNumberSeriesType: branchsettings?.billNumberSeriesType,
      enableArabicMenuDescription: branchsettings?.enableArabicMenuDescription,
      defaultState: branchsettings?.defaultState,
      enableProductNameBilling: branchsettings?.enableProductNameBilling,
      generateKOTOnCashInsale: branchsettings?.generateKOTOnCashInsale,
      showAllProductsInKOTPrint: branchsettings?.showAllProductsInKOTPrint,
      generateKOTPrint: branchsettings?.generateKOTPrint,
      showCancelReasonPopupInPay: branchsettings?.showCancelReasonPopupInPay,
      disableTaxInSale: branchsettings?.disableTaxInSale,
      disableLastSaleBillPrintOnEnter: branchsettings?.disableLastSaleBillPrintOnEnter,
      hideInvoiceRePrintMenu: branchsettings?.hideInvoiceRePrintMenu,
      showWaiterPopupOnSaveSale: branchsettings?.showWaiterPopupOnSaveSale,
      enableContinuousBillNumberInSale: branchsettings?.enableContinuousBillNumberInSale,
      enableVAT: branchsettings?.enableVAT,
      generateDailyClosePrint: branchsettings?.generateDailyClosePrint,
      showCreditButtonInSales: branchsettings?.showCreditButtonInSales,
      showTaxButtonInSaleForSpecialCustomer: branchsettings?.showTaxButtonInSaleForSpecialCustomer,
      changeProductDetailsWhileBilling: branchsettings?.changeProductDetailsWhileBilling,
      enableTokenNumber: branchsettings?.enableTokenNumber,
      enableSectionWiseTokenNumber: branchsettings?.enableSectionWiseTokenNumber,
      enableOtherLanguage: branchsettings?.enableOtherLanguage,
      showSaleBillInAllCounters: branchsettings?.showSaleBillInAllCounters,
      showSaleBillInAllCounterAndSections: branchsettings?.showSaleBillInAllCounterAndSections,
      showSaleBillInAllSections: branchsettings?.showSaleBillInAllSections,
      deliverySection: branchsettings?.deliverySection,
      showTablePopupInSection: branchsettings?.showTablePopupInSection,
      enableDepartmentInSection: branchsettings?.enableDepartmentInSection,
      disableSalePrintInPayScreen: branchsettings?.disableSalePrintInPayScreen,
      enableShiftClose: branchsettings?.enableShiftClose,
      generatePrintInCashButton: branchsettings?.generatePrintInCashButton,
      enableDailyCloseSummaryPrint: branchsettings?.enableDailyCloseSummaryPrint,
      showWaiterPopupInSection: branchsettings?.showWaiterPopupInSection,
      showCustomerPopupInSection: branchsettings?.showCustomerPopupInSection,
      dailyClosePrintFormat: branchsettings?.dailyClosePrintFormat,
      showFieldToEnterArabicDescriptionInProduct: branchsettings?.showFieldToEnterArabicDescriptionInProduct,
      roundOffInSale: branchsettings?.roundOffInSale,
      comboType: branchsettings?.comboType,
      showKeyBoardForEnterQuantityManually: branchsettings?.showKeyBoardForEnterQuantityManually,
      ledDisplayPort: branchsettings?.ledDisplayPort,
      enablePoleDisplayAutoClear: branchsettings?.enablePoleDisplayAutoClear,
      primaryCurrencyGUID: branchsettings?.primaryCurrencyGUID,
      defaultFinancialYearGuID: branchsettings?.defaultFinancialYearGuID,
    })
  }, [branchsettings,branch])
  const closeButtonRef = useRef();

const closemodal = () => {
  if (closeButtonRef.current) {
    closeButtonRef.current.click();
  }
};
  const handleGeneralSettings=async(data)=>{
    
    try {
      const response = await savesettings({setting: data.general, branch: branch});
      if(response==true){
        toast.success("success")
        closemodal()
      }
   
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  }
  return (
    <div className="modal-body custom-modal-body">
      <form onSubmit={handleSubmit(handleGeneralSettings)}>
        <div className='form_body'>
                    <div className="row">
                          <label><b>Email: </b> </label>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-blocks add-product">
                          <label>
                          Email to send daily close report </label>
                         
                        <input type="email"  placeholder='example@gmail.com'
                        {...register("general.emailToSendDailyCloseReport")}
                        />
                      
                            </div>
                            </div>
                             <div className="col-lg-6 col-md-6 col-sm-12">
                      </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="d-flex">
                         <input type="checkbox" {...register("general.sendMailWhileDayClose")} />
                          <label className='m-3'>Send Mail While DayClose</label>
                            </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                      When this setting enabled, a mail including details of day close will send to user when do day close
                      </div>
                    <hr />
                    </div>
                    <div className="row">
                          <label><b>Day close print: </b> </label>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="input-blocks add-product">
                          <label>
                          Daily Close Print Format </label>
                    
                          <select name="" id="" className="form-control" {...register("general.dailyClosePrintFormat")}>
                                <option value="" disabled >Select</option>
                                <option value="1" >Section wise detail</option>
                                <option value="2" >Section wise summary</option>
                                <option value="3" >Consolidated</option>
                                <option value="4" >Section wise detail with tax</option>
                               
                                
                              </select>
                            </div>
                            </div>
                             <div className="col-lg-6 col-md-6 col-sm-12">
                      </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="d-flex">
                         <input type="checkbox" {...register("general.generateDailyClosePrint")} />
                          <label className='m-3'>Generate daily close print</label>
                            </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                      Enabling this setting will allow you to generate a print when day closes sales in the application
                      </div>
                    <hr />
                    </div>
                    <div className="row">
            <label><b>Day close menu : </b> </label>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.hideItemwiseSummaryInDailyClose")} />
                                <label className='m-3'>Hide item wise summary in day close</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        We can see day close screen as two sections, item wise summary and section wise summary. When this setting enabled, item wise summary grid will hide in day close screen</div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.hideSectionSummaryInDailyClose")} />
                                <label className='m-3'>Hide section summary in day close</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        We can see day close screen as two sections, item wise summary and section wise summary. When this setting enabled, section wise summary grid will hide in day close screen</div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showDateInDailyClose")} />
                                <label className='m-3'>Show date in day close</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When this setting enabled, date column will show in dayclose screen</div>
                           
                        <hr />
          </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                          ref={closeButtonRef}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
    </div>
  )
}

export default SetttingDayClose
