import axios from "axios";
import { getBaseUrl } from "../config";

// const base_path = getBaseUrl();
const base_path = window.APP_CONFIG?.BASE_URL
// const base_path = process.env.REACT_APP_API_URL;

//Masters.......

export const getTaxList = async () => {
  const response = await axios.get(`${base_path}/api/Tax/GetAll`);
  return response.data;
};

export const addNewTax = async (taxdata) => {
  const response = await axios.post(`${base_path}/Api/Tax/Save`, taxdata);
  return response;
};

export const editTax = async (taxdata) => {
  const response = await axios.post(`${base_path}/Api/Tax/Update`, taxdata);
  return response;
};

export const deleteTax = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/Tax/Delete`,
    JSON.stringify(id)
  );
  return response;
};
export const getTaxguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/Tax/GetByGuid?guid=${guid}`
  );
  return response;
};

export const getAccountTypeList = async () => {
  const response = await axios.get(`${base_path}/api/AccountType/GetAll`);
  return response.data;
};
export const addAccountType = async (typedata) => {
  const response = await axios.post(
    `${base_path}/Api/AccountType/Save`,
    typedata
  );
  return response;
};

export const getAccountTypeguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/AccountType/GetByGuid?guid=${guid}`
  );
  return response;
};

export const editAccountType = async (accounttypedata) => {
  const response = await axios.post(
    `${base_path}/Api/AccountType/Update`,
    accounttypedata
  );
  return response;
};

export const deleteAccountType = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/AccountType/Delete`,
    JSON.stringify(id)
  );
  return response;
};

export const getProductTypeList = async () => {
  const response = await axios.get(`${base_path}/Api/ProductType/GetAll`);
  return response.data;
};
export const getUnitList = async () => {
  const response = await axios.get(`${base_path}/Api/unit/GetAll`);
  return response.data;
};
export const addUnit = async (unitdata) => {
  const response = await axios.post(`${base_path}/Api/Unit/Save`, unitdata);
  return response;
};

export const getUnitguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/Unit/GetByGuid?guid=${guid}`
  );
  return response.data;
};

export const getUnitbyId = async ({ id }) => {
  const response = await axios.get(
    `${base_path}/Api/Unit/GetByGuid?guid=${id}`
  );
  return response.data;
};

export const editUnit = async (unitdata) => {
  const response = await axios.post(`${base_path}/Api/Unit/Update`, unitdata);
  return response;
};

export const deleteUnit = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/Unit/Delete`,
    JSON.stringify(id)
  );
  return response;
};

export const getPromotionList = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Promotion/GetAll?globalBranchID=${branch?.guid}`
  );
  return response.data;
};

export const addPromotion = async (promotiondata) => {
  console.log("promotiondata on add", promotiondata);
  const response = await axios.post(
    `${base_path}/Api/Promotion/Save`,
    promotiondata
  );
  return response;
};
export const deletepromotion = async (id) => {
  const response = await axios.post(
    // `${base_path}/Api/Promotion/Delete?globalBranchID=${branch?.guid}`,
    `${base_path}/Api/Promotion/Delete`,
    JSON.stringify(id)
  );
  return response;
};

export const editPromotion = async (promotiondata) => {
  console.log("promotiondata on edit", promotiondata);
  const response = await axios.post(
    `${base_path}/Api/Promotion/Update`,
    promotiondata
  );
  return response;
};

export const getPromoProductsList = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Promotion/GetAllPromoProducts?globalBranchID=${branch.guid}`
  );

  return response;
};
export const addNewPromoProduct = async (objpromoproduct) => {
  const response = await axios.post(
    `${base_path}/Api/Promotion/SavePromoProducts`,
    objpromoproduct
  );
  return response;
};

export const editPromoProduct = async (objpromoproduct) => {
  console.log(objpromoproduct);
  const response = await axios.post(
    `${base_path}/Api/Promotion/UpdatePromoProducts`,
    objpromoproduct
  );
  return response;
};
//  export const getPromoProductguid= async (guid) => {
//   const response = await axios.get(`${base_path}?guid=${guid}`);
//   return response;
// };
export const deletePromoproduct = async (objpromo) => {
  const response = await axios.post(
    `${base_path}/Api/Promotion/DeletePromoProducts`,
    JSON.stringify(objpromo)
  );
  return response;
};

export const getProductList = async (branch) => {
  const response = await axios.get(
    `${base_path}/api/Product/GetAllByBranch?globalBranchID=${branch?.guid}`
  );

  return response.data;
};
export const addNewProduct = async (productdata) => {
  console.log(productdata, "zzzzzuhhh");

  const response = await axios.post(
    `${base_path}/api/Product/Save`,
    productdata
  );

  return response.data;
};
export const editProduct = async (productdata) => {
  const response = await axios.post(
    `${base_path}/api/Product/Update`,
    productdata
  );

  return response.data;
};

export const deleteProduct = async (objproduct) => {
  const response = await axios.post(
    `${base_path}/api/Product/Delete`,
    objproduct
  );
  return response;
};
export const getProductType = async () => {
  const response = await axios.get(`${base_path}/Api/ProductType/GetAll`);
  return response.data;
};
export const getPaymentCardList = async () => {
  const response = await axios.get(`${base_path}/Api/PaymentCard/GetAll`);
  return response.data;
};
export const getReasonTypeList = async () => {
  const response = await axios.get(`${base_path}/Api/ReasonType/GetAll`);
  return response.data;
};
export const getSectionList = async () => {
  const response = await axios.get(`${base_path}/api/Section/GetAll`);
  return response.data;
};
export const getSectionListbybranch = async (branch) => {
  const response = await axios.get(
    `${base_path}/api/Section/GetAllByBranch?globalBranchID=${branch}`
  );
  return response.data;
};

export const getProductBOQlist = async (branch) => {
  const response = await axios.get(
    `${base_path}/api/Product/GetAllBOQPRODUCTS?globalBranchID=${branch?.guid}`
  );
  return response.data;
};
export const getProductBOQIngredients = async (branch) => {
  const response = await axios.get(
    `${base_path}/api/Product/GetAllProductIngredients?globalBranchID=${branch?.guid}`
  );
  return response.data;
};
export const getProductBoqListView = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/InvProductBOQ/GetAllByBranchID?globalBranchID=${branch?.guid}`
  );
  return response.data;
};
export const AddProductBOQ = async (objproduct) => {
  const response = await axios.post(
    `${base_path}/api/InvProductBOQ/SaveOrUpdate`,
    objproduct
  );
  return response.data;
};
export const EditTaxList = async () => {
  const response = await axios.get(`${base_path}/Api/Tax/Update`);
  return response.data;
};
export const updateModifierCategory = async (modifierCategoryData) => {
  const response = await axios.post(
    `${base_path}/api/ModifierCategory/Update`,
    modifierCategoryData
  );
  return response;
};
export const delteModifierCategory = async (modifierCategoryData) => {
  const response = await axios.post(
    `${base_path}/api/ModifierCategory/Delete`,
    modifierCategoryData
  );
  return response;
};

export const addModifierCategoryList = async ({ modifiercategorydata }) => {
  const response = await axios.get(`${base_path}/api/modifiercategory/Save`, {
    modifiercategorydata,
  });
  return response;
};

export const addNewPayment = async (paymentdata) => {
  const response = await axios.post(
    `${base_path}/Api/PaymentCard/Save`,
    paymentdata
  );
  return response;
};

export const addNewProductType = async (producttypedata) => {
  const response = await axios.post(
    `${base_path}/Api/ProductType/Save`,
    producttypedata
  );
  return response;
};

export const addNewReasonType = async (reasontypedata) => {
  const response = await axios.post(
    `${base_path}/Api/ReasonType/Save`,
    reasontypedata
  );
  return response;
};
export const addNewSection = async (sectiondata) => {
  const response = await axios.post(
    `${base_path}/api/Section/Save`,
    sectiondata
  );
  return response;
};

export const editPaymentCard = async (paymentcarddata) => {
  const response = await axios.post(
    `${base_path}/Api/PaymentCard/Update`,
    paymentcarddata
  );
  return response;
};
export const getPaymentCardbyguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/PaymentCard/GetByGuid?guid=${guid}`
  );
  return response;
};

export const deletePaymentCard = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/PaymentCard/Delete`,
    JSON.stringify(id)
  );
  return response;
};

export const editProductType = async (producttypedata) => {
  const response = await axios.post(
    `${base_path}/Api/ProductType/Update`,
    producttypedata
  );
  return response;
};
export const getProductTypeguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/ProductType/GetByGuid?guid=${guid}`
  );
  return response;
};

export const deleteProductType = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/ProductType/Delete`,
    JSON.stringify(id)
  );
  return response;
};

export const editReasonType = async (reasontypedata) => {
  const response = await axios.post(
    `${base_path}/Api/ReasonType/Update`,
    reasontypedata
  );
  return response;
};
export const getReasonTypeguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/ReasonType/GetByGuid?guid=${guid}`
  );
  return response;
};

export const deleteReasonType = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/ReasonType/Delete`,
    JSON.stringify(id)
  );
  return response;
};

export const editSection = async (sectiondata) => {
  const response = await axios.post(
    `${base_path}/api/Section/update`,
    sectiondata
  );
  return response;
};
export const getSectionguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/api/Section/GetByGuid?guid=${guid}`
  );
  return response;
};
export const deleteSection = async (objSection) => {
  const response = await axios.post(
    `${base_path}/api/Section/Delete`,
    objSection
  );
  return response;
};

export const getTypeList = async () => {
  const response = await axios.get(`${base_path}/Api/Type/GetAll`);
  return response.data;
};

//----------modifier api calls----------------------

// get modifier list
export const getModifierList = async () => {
  const response = await axios.get(`${base_path}/Api/Modifier/GetAll`);
  return response.data;
};

// create modifier
export const addModifier = async (Modifierdata) => {
  const response = await axios.post(
    `${base_path}/api/Modifier/Save`,
    Modifierdata
  );

  return response;
};

// get modifier by guid
export const getModifierByGuid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/Modifier/GetByGuid?guid=${guid}`
  );
  return response.data;
};

// update modifier
export const editModifier = async (Modifierdata) => {
  const response = await axios.post(
    `${base_path}/Api/Modifier/Update`,
    Modifierdata
  );

  return response;
};

// delete modifier
export const deleteModifier = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/Modifier/Delete`,
    JSON.stringify(id)
  );
  return response;
};

//----------modifier category api calls-------------

//get list of modifier category
export const getModifierCategoryList = async () => {
  const response = await axios.get(`${base_path}/Api/ModifierCategory/GetAll`);
  return response.data;
};

//create Modifier category
export const addModifierCategory = async (objmodifierCategoryData) => {
  const response = await axios.post(
    `${base_path}/api/ModifierCategory/Save`,
    objmodifierCategoryData
  );
  return response;
};

//get modifier category by guid
export const getModifierCategorybyguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/ModifierCategory/GetByGuid?guid=${guid}`
  );
  return response;
};

//update modifier category
export const editModifierCategory = async (ModifierCategorydata) => {
  const response = await axios.post(
    `${base_path}/Api/ModifierCategory/Update`,
    ModifierCategorydata
  );
  return response;
};

//delete modifier category
export const deleteModifierCategory = async (id) => {
  let data = JSON.stringify(id);
  const response = await axios.post(
    `${base_path}/Api/ModifierCategory/delete`,
    data
  );
  return response;
};

//----------discount api calls-------------

//get list of discount
export const getDiscountList = async () => {
  const response = await axios.get(`${base_path}/Api/Discount/GetAll`);
  return response.data;
};

//create discount
export const addDiscount = async (discountdata) => {
  const response = await axios.post(
    `${base_path}/Api/Discount/Save`,
    discountdata
  );
  return response;
};

//get discount by guid
export const getDiscountbyguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/discount/GetByGuid?guid=${guid}`
  );

  return response;
};

//update discount
export const editDiscount = async (discountdata) => {
  const response = await axios.post(
    `${base_path}/Api/discount/Update`,
    discountdata
  );
  return response;
};

//delete discount
export const deleteDiscount = async (id) => {
  let discId = JSON.stringify(id);
  const response = await axios.post(`${base_path}/Api/Discount/Delete`, discId);
  return response;
};

//----------customer api calls-------------

//get list of customers
export const getCustomersList = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Customer/GetAllbyBranch?globalBranchID=${branch?.guid}`
  );

  return response.data;
};

//create customer
export const addCustomer = async (customerData) => {
  const response = await axios.post(
    `${base_path}/Api/Customer/Save`,
    customerData
  );

  return response;
};

//get customer by guid
export const getCustomerByGuid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/Customer/GetByGuid?guid=${guid}`
  );
  return response;
};

//update customer
export const editCustomer = async (customerData) => {
  const response = await axios.post(
    `${base_path}/Api/Customer/Update`,
    customerData
  );

  return response;
};

//delete customer
export const deleteCustomer = async (id) => {
  let custId = JSON.stringify(id);
  const response = await axios.post(`${base_path}/Api/Customer/Delete`, custId);

  return response;
};

//----------groupEntry api calls-------------

// getAll groupEntry
export const getGroupEntryList = async () => {
  const response = await axios.get(`${base_path}/Api/GroupEntry/GetAll`);
  return response.data;
};
export const getGroupEntryListbybranch = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/GroupEntry/GetAllByBranch?globalBranchID=${branch}`
  );
  return response?.data;
};
// create groupEntry
export const addGroupEntry = async (groupEntryData) => {
  const response = await axios.post(
    `${base_path}/Api/GroupEntry/Save`,
    groupEntryData
  );
  return response;
};

// get groupEntry by guid
export const getGroupEntryByGuid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/GroupEntry/GetByGuid?guid=${guid}`
  );
  return response.data;
};

// update groupEntry
export const editGroupEntry = async (groupEntryData) => {
  const response = await axios.post(
    `${base_path}/Api/GroupEntry/Update`,
    groupEntryData
  );
  return response;
};

// delete groupEntry
export const deleteGroupEntry = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/GroupEntry/Delete`,
    JSON.stringify(id)
  );
  return response;
};

//----------KotPrinter api calls-------------

// getAll KotPrinter
export const getKOTPrinterList = async () => {
  const response = await axios.get(`${base_path}/Api/KotPrinter/GetAll`);

  return response.data;
};

// create KotPrinter
export const addKotPrinter = async (kotPrinterData) => {
  const response = await axios.post(
    `${base_path}/Api/KotPrinter/Save`,
    kotPrinterData
  );
  return response;
};

// get KotPrinter by guid
export const getKotPrinterByGuid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/KotPrinter/GetByGuid?guid=${guid}`
  );

  return response;
};

// update KotPrinter
export const editKotPrinter = async (kotPrinterData) => {
  const response = await axios.post(
    `${base_path}/Api/KotPrinter/Update`,
    kotPrinterData
  );
  return response;
};

// delete KotPrinter
export const deleteKotPrinter = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/KotPrinter/Delete`,
    JSON.stringify(id)
  );
  return response;
};

//----------Counter api calls-------------

// getAll Counter
export const getCounterList = async () => {
  const response = await axios.get(`${base_path}/Api/Counter/GetAll`);
  return response.data;
};
export const getCounterListbyBranch = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Counter/GetAllByBranch?globalBranchID=${branch}`
  );
  return response.data;
};

// create Counter
export const addCounter = async (counterdata) => {
  const response = await axios.post(
    `${base_path}/Api/Counter/Save`,
    counterdata
  );
  return response;
};

// get Counter by guid
export const getCounterbyguid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/Counter/GetByGuid?guid=${guid}`
  );
  return response;
};

// update counter
export const editCounter = async (counterdata) => {
  const response = await axios.post(
    `${base_path}/Api/Counter/Update`,
    counterdata
  );
  return response;
};

// delete counter
export const deleteCounter = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/Counter/Delete`,
    JSON.stringify(id)
  );
  return response;
};

//----------category api calls-------------

// getAll category
export const getCategoryList = async () => {
  const response = await axios.get(`${base_path}/Api/Category/GetAll`);
  return response.data;
};
export const getCategoryListbybranch = async (branch) => {
  const response = await axios.get(
    `${base_path}/Api/Category/GetAllByBranch?globalBranchID=${branch}`
  );
  return response.data;
};

// create category
export const addCategory = async (categorydata) => {
  const response = await axios.post(
    `${base_path}/Api/Category/Save`,
    categorydata
  );
  return response;
};

// update category
export const editCategory = async (categorydata) => {
  const response = await axios.post(
    `${base_path}/Api/Category/Update`,
    categorydata
  );
  return response;
};

// delete category
export const deleteCategory = async (categorydata) => {
  const response = await axios.post(
    `${base_path}/Api/Category/Delete`,
    categorydata
  );
  return response;
};
export const getCategorybyGuid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/Category/GetByGuid?guid=${guid}`
  );
  return response;
};

//----------------coupencode api calls-----------------

// Get list of coupon codes
export const getCouponCodeList = async () => {
  const response = await axios.get(`${base_path}/Api/CouponCode/GetAll`);
  return response.data;
};

// Create a new coupon code
export const addCouponCode = async (couponData) => {
  const response = await axios.post(
    `${base_path}/api/CouponCode/Save`,
    couponData
  );
  return response;
};

// get coupon code by guid
export const getCoupencodeByGuid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/CouponCode/getbyguid?guid=${guid}`
  );
  return response;
};

// Update a coupon code
export const editCoupencode = async (couponData) => {
  const response = await axios.post(
    `${base_path}/Api/CouponCode/Update`,
    couponData
  );
  return response;
};

// Delete a coupon code
export const deleteCouponCode = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/CouponCode/Delete`,
    JSON.stringify(id)
  );
  return response;
};
//----------Currency api calls-------------

// getAll Currencies
export const getCurrencyList = async () => {
  const response = await axios.get(`${base_path}/Api/Currency/GetAll`);

  return response.data;
};

// create Currency
export const addCurrency = async (currencyData) => {
  const response = await axios.post(
    `${base_path}/Api/Currency/Save`,
    currencyData
  );
  return response;
};

// get Currency by guid
export const getCurrencyByGuid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/Currency/GetByGuid?guid=${guid}`
  );

  return response;
};

// update Currency
export const editCurrency = async (currencyData) => {
  const response = await axios.post(
    `${base_path}/Api/Currency/Update`,
    currencyData
  );
  return response;
};

// delete Currency
export const deleteCurrency = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/Currency/Delete`,
    JSON.stringify(id)
  );
  return response;
};
// add denomination
export const SaveCurrencyDenomination = async (objcurrency) => {
  const response = await axios.post(
    `${base_path}/Api/Currency/Denominations/Save`,
    objcurrency
  );
  return response;
};
//----------Reason API calls-------------
export const GetDenominations = async (updateData) => {
  var criteriaJsonString = JSON.stringify(updateData);
  const response = await axios.get(
    `${base_path}/Api/Currency/GetDenominations?criteria=${criteriaJsonString}`
  );
  return response.data;
};
//----------Reason API calls-------------

// Get all Reasons
export const getReasonList = async () => {
  const response = await axios.get(`${base_path}/Api/Reason/GetAll`);

  return response.data;
};

// Create Reason
export const addReason = async (reasonData) => {
  const response = await axios.post(`${base_path}/Api/Reason/Save`, reasonData);
  return response;
};

// Get Reason by guid
export const getReasonByGuid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/Reason/GetByGuid?guid=${guid}`
  );

  return response;
};

// Update Reason

export const editReason = async (reasonData) => {
  const response = await axios.post(
    `${base_path}/Api/Reason/Update`,
    reasonData
  );
  return response;
};

// Delete Reason
export const deleteReason = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/Reason/Delete`,
    JSON.stringify(id)
  );
  return response;
};
export const getSupplierList = async () => {
  const response = await axios.get(`${base_path}/Api/Supplier/GetAll`);
  return response.data;
};
export const addNewSupplier = async (supplierdata) => {
  console.log(supplierdata, "supplier");

  const response = await axios.post(
    `${base_path}/Api/Supplier/Save`,

    supplierdata
  );

  return response;
};
export const deleteSupplier = async (id) => {
  const response = await axios.post(
    `${base_path}/Api/Supplier/Delete`,
    JSON.stringify(id)
  );
  return response;
};
export const editSupplier = async (supplierdata) => {
  const response = await axios.post(
    `${base_path}/Api/Supplier/Update`,
    supplierdata
  );
  return response;
};
//get all states
export const getstates = async () => {
  const response = await axios.get(`${base_path}/Api/state/GetAll`);

  return response.data;
};

//get state by guid
export const getstatesByGuid = async (guid) => {
  const response = await axios.get(
    `${base_path}/Api/state/GetByGuid?guid=${guid}`
  );

  return response.data;
};

export const addCounterSection = async (counterSectiondata) => {
  console.log(counterSectiondata);

  const response = await axios.post(
    `${base_path}/Api/Counter/SaveCounterSections`,

    counterSectiondata
  );

  return response;
};

export const addNewCounterSection = async (countersectiondata) => {
  console.log(countersectiondata, "counterrrrrr");
  const response = await axios.post(
    `${base_path}/Api/Counter/SaveCounterSections`,

    countersectiondata
  );

  return response.data;
};

export const getCounterSection = async (objCounter) => {
  const response = await axios.post(
    `${base_path}/Api/Counter/GetCounterSection`,
    objCounter
  );
  return response.data;
};

export const addNewCounterSettings = async (countersettings) => {
  const response = await axios.post(
    `${base_path}/Api/Counter/Settings/Save`,

    countersettings
  );

  return response;
};
export const getCounterSettings = async (objCounter) => {
  const response = await axios.post(
    `${base_path}/Api/Counter/GetCounterSettings`,
    objCounter
  );
  return response.data;
};

export const addSectionTable = async (sectionTable) => {
  const response = await axios.post(
    `${base_path}/api/Section/Tables/Save`,
    sectionTable
  );

  return response.data;
};
export const getSectionTable = async (objsection) => {
  const response = await axios.post(
    `${base_path}/api/Section/GetTablesBySectionId`,
    objsection
  );

  return response.data;
};
export const addSectionSettings = async (sectionsettings) => {
  const response = await axios.post(
    `${base_path}/api/Section/Settings/Save`,
    sectionsettings
  );

  return response.data;
};
export const getSectionSetting = async (objCounter) => {
  const response = await axios.post(
    `${base_path}/api/Section/GetSectionSettings`,
    objCounter
  );

  return response.data;
};
export const getfinancialyear = async () => {
  const response = await axios.get(`${base_path}/api/FinancialYear/GetAll`);

  return response.data;
};
