import Select from "react-select";
import React, { useEffect, useRef, useState } from 'react'
import { savesettings } from "../../services/AdminApiServices";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const SettingSales = () => {
    const { register, handleSubmit, setValue } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
      });
     
      const { branchsettings } = useSelector((state) => state.settings);
      const { branch } = useSelector((state) => state.branchlist);
    
    
      useEffect(() => {
        setValue("general", {
          timeToClearPoleDisplay: branchsettings?.timeToClearPoleDisplay,
          timeToRefreshPayScreen: branchsettings?.timeToRefreshPayScreen,
          totalAmountForDiscount: branchsettings?.totalAmountForDiscount,
          enableBillSearchByTable: branchsettings?.enableBillSearchByTable,
          tableAlignmentRowNumber: branchsettings?.tableAlignmentRowNumber,
          tableAlignmentColumnNumber: branchsettings?.tableAlignmentColumnNumber,
          showSaleAmountInPoleDisplay: branchsettings?.showSaleAmountInPoleDisplay,
          enableLoyalty: branchsettings?.enableLoyalty,
          enableChairInSale: branchsettings?.enableChairInSale,
          orderProductByReferenceCode: branchsettings?.orderProductByReferenceCode,
          showChangeSectionButtonInPay: branchsettings?.showChangeSectionButtonInPay,
          showSplitButtonInPay: branchsettings?.showSplitButtonInPay,
          showMergeButtonInPay: branchsettings?.showMergeButtonInPay,
          showPaxPopupInSale: branchsettings?.showPaxPopupInSale,
          numberOfKOTPrints: branchsettings?.numberOfKOTPrints,
          applicationBackgroundImageNumber: branchsettings?.applicationBackgroundImageNumber,
          changeApplicationDesign: branchsettings?.changeApplicationDesign,
          hideModifierDetailsInKOTPrint: branchsettings?.hideModifierDetailsInKOTPrint,
          enableDataAutoSyncWithServer: branchsettings?.enableDataAutoSyncWithServer,
          enableVirtualKeyboard: branchsettings?.enableVirtualKeyboard,
          enableCess: branchsettings?.enableCess,
          enableCounterSection: branchsettings?.enableCounterSection,
          isStockProduct: branchsettings?.isStockProduct,
          showUnitPriceOfProductInSale: branchsettings?.showUnitPriceOfProductInSale,
          enablePrintInKitchenManager: branchsettings?.enablePrintInKitchenManager,
          kitchenManagerPrinter: branchsettings?.kitchenManagerPrinter,
          disableMultipleOrderInTable: branchsettings?.disableMultipleOrderInTable,
          viewSalesOrderFromTable: branchsettings?.viewSalesOrderFromTable,
          showCategoryAsCoursewiseInKOT: branchsettings?.showCategoryAsCoursewiseInKOT,
          enableWaiterwiseLogin: branchsettings?.enableWaiterwiseLogin,
          generateQrCodeInBillPrint: branchsettings?.generateQrCodeInBillPrint,
          sendMailWhileDayClose: branchsettings?.sendMailWhileDayClose,
          emailToSendDailyCloseReport: branchsettings?.emailToSendDailyCloseReport,
          arrangePhoneNumberAboveNameInCustomerMenu: branchsettings?.arrangePhoneNumberAboveNameInCustomerMenu,
          disableTaxEditInCreditBill: branchsettings?.disableTaxEditInCreditBill,
          currencyFormat: branchsettings?.currencyFormat,
          dateFormat: branchsettings?.dateFormat,
          defaultUserType: branchsettings?.defaultUserType,
          enableGeneralKeypad: branchsettings?.enableGeneralKeypad,
          generateSaleBillPrint: branchsettings?.generateSaleBillPrint,
          numberOfDaysExtendForBillingAfterDailyClose: branchsettings?.numberOfDaysExtendForBillingAfterDailyClose,
          numberOfDaysForEditBill: branchsettings?.numberOfDaysForEditBill,
          generateSalesInvoiceInDotMatrixPrinter: branchsettings?.generateSalesInvoiceInDotMatrixPrinter,
          hideItemwiseSummaryInDailyClose: branchsettings?.hideItemwiseSummaryInDailyClose,
          hideSectionSummaryInDailyClose: branchsettings?.hideSectionSummaryInDailyClose,
          showDateInDailyClose: branchsettings?.showDateInDailyClose,
          billNumberSeriesType: branchsettings?.billNumberSeriesType,
          enableArabicMenuDescription: branchsettings?.enableArabicMenuDescription,
          defaultState: branchsettings?.defaultState,
          enableProductNameBilling: branchsettings?.enableProductNameBilling,
          generateKOTOnCashInsale: branchsettings?.generateKOTOnCashInsale,
          showAllProductsInKOTPrint: branchsettings?.showAllProductsInKOTPrint,
          generateKOTPrint: branchsettings?.generateKOTPrint,
          showCancelReasonPopupInPay: branchsettings?.showCancelReasonPopupInPay,
          disableTaxInSale: branchsettings?.disableTaxInSale,
          disableLastSaleBillPrintOnEnter: branchsettings?.disableLastSaleBillPrintOnEnter,
          hideInvoiceRePrintMenu: branchsettings?.hideInvoiceRePrintMenu,
          showWaiterPopupOnSaveSale: branchsettings?.showWaiterPopupOnSaveSale,
          enableContinuousBillNumberInSale: branchsettings?.enableContinuousBillNumberInSale,
          enableVAT: branchsettings?.enableVAT,
          generateDailyClosePrint: branchsettings?.generateDailyClosePrint,
          showCreditButtonInSales: branchsettings?.showCreditButtonInSales,
          showTaxButtonInSaleForSpecialCustomer: branchsettings?.showTaxButtonInSaleForSpecialCustomer,
          changeProductDetailsWhileBilling: branchsettings?.changeProductDetailsWhileBilling,
          enableTokenNumber: branchsettings?.enableTokenNumber,
          enableSectionWiseTokenNumber: branchsettings?.enableSectionWiseTokenNumber,
          enableOtherLanguage: branchsettings?.enableOtherLanguage,
          showSaleBillInAllCounters: branchsettings?.showSaleBillInAllCounters,
          showSaleBillInAllCounterAndSections: branchsettings?.showSaleBillInAllCounterAndSections,
          showSaleBillInAllSections: branchsettings?.showSaleBillInAllSections,
          deliverySection: branchsettings?.deliverySection,
          showTablePopupInSection: branchsettings?.showTablePopupInSection,
          enableDepartmentInSection: branchsettings?.enableDepartmentInSection,
          disableSalePrintInPayScreen: branchsettings?.disableSalePrintInPayScreen,
          enableShiftClose: branchsettings?.enableShiftClose,
          generatePrintInCashButton: branchsettings?.generatePrintInCashButton,
          enableDailyCloseSummaryPrint: branchsettings?.enableDailyCloseSummaryPrint,
          showWaiterPopupInSection: branchsettings?.showWaiterPopupInSection,
          showCustomerPopupInSection: branchsettings?.showCustomerPopupInSection,
          dailyClosePrintFormat: branchsettings?.dailyClosePrintFormat,
          showFieldToEnterArabicDescriptionInProduct: branchsettings?.showFieldToEnterArabicDescriptionInProduct,
          roundOffInSale: branchsettings?.roundOffInSale,
          comboType: branchsettings?.comboType,
          showKeyBoardForEnterQuantityManually: branchsettings?.showKeyBoardForEnterQuantityManually,
          ledDisplayPort: branchsettings?.ledDisplayPort,
          enablePoleDisplayAutoClear: branchsettings?.enablePoleDisplayAutoClear,
          primaryCurrencyGUID: branchsettings?.primaryCurrencyGUID,
          defaultFinancialYearGuID: branchsettings?.defaultFinancialYearGuID,
        })
      }, [branchsettings,branch])
      const closeButtonRef = useRef();

      const closemodal = () => {
        if (closeButtonRef.current) {
          closeButtonRef.current.click();
        }
      };
      const handleGeneralSettings=async(data)=>{
        
        try {
          const response = await savesettings({setting: data.general, branch: branch});
          if(response==true){
            toast.success("success")
            closemodal()
          }
       
        } catch (error) {
          console.error("Error fetching states:", error);
        }
      }
    return (
        <div className="modal-body custom-modal-body">
            <form onSubmit={handleSubmit(handleGeneralSettings)}>
                <div className='form_body'>
                    <div className="row">
                        <label><b>Bill number series: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks add-product">
                                <label>Bill number series type</label>
                          
                                <select name="" id="" className="form-control" {...register("general.timeToClearPoleDisplay")}>
                                <option value="" disabled >Select</option>
                                <option value="0" >Reset Daily</option>
                                <option value="1" >Continuous</option>
                               
                                
                              </select>
                            </div>


                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            This setting is used to change sales bill number generation pattern. It wiill work only if Enable continuous bill number setting is false. Otherwise bill number will generate continuously.<br />

                            Set Enable continuous bill number setting as false to work below types.<br />

                            If &apos;Reset Daily&apos; selected, bill number will generate section wise from 1 (Eg : For AC bill number will be 1,2,3 etc. NONAC will also starts from 1,2,3 etc). After dayclose bill number resets from 1 as section wise.<br />

                            If &apos;Continuous&apos; selected, bill number will generate section wise, in addition to this if there is any prefix and start number saved for a section then it will be added with bill number. (Eg : For AC section there is a prefix AC and start number 10 in section, then the starting bill number will be AC!0, AC11..). After dayclose bill number increments from last bill number as section wise.                     </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.enableContinuousBillNumberInSale")} />
                                <label className='m-3'>Enable continuous bill number in sale</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            When this setting enabled, sale bill number will generate continuously by combining series type setting for the pattern
                        </div>
                        <hr />
                    </div>


                    <div className="row">
                        <label><b>Round off: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.roundOffInSale")} />
                                <label className='m-3'>Apply round off</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            When this setting enabled, roundoff will be applied to the amount in sale. If set to false, no roundoff will be there</div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Credit button: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showCreditButtonInSales")} />
                                <label className='m-3'>Show credit button in Pay</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Enabling this setting will show credit button in sales screen</div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Change product details: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.changeProductDetailsWhileBilling")} />
                                <label className='m-3'>Change product details while billing</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When this setting enabled, we can rename a product or change the rate of product while billing in sale screen</div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Split bill: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showSplitButtonInPay")} />
                                <label className='m-3'>Show split button in pay</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When you want to split one sales bill into two or more sale bill then enable this setting, a split button will see in pay screen</div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Merge bill: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showMergeButtonInPay")} />
                                <label className='m-3'>Show merge button in pay</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When you want to merge two or more sales bill into one bill then enable this setting, a merge button will see in pay screen</div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Waiter selection: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showWaiterPopupOnSaveSale")} />
                                <label className='m-3'>Show waiter popup on save</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When this setting enabled, a popup to select waiter will show in sale screen when clicks save button</div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Order in table : </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.disableMultipleOrderInTable")} />
                                <label className='m-3'>Disable multiple order in table</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        It will not allow to take multiple orders in a table when this setting set true</div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.viewSalesOrderFromTable")} />
                                <label className='m-3'>View sales order from table</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        This setting is used to view the orders taken on a particular table by clicking on that table in sales screen</div>                    
                    </div>

                    <div className="row">
                        <label><b>Search by table: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.enableBillSearchByTable")} />
                                <label className='m-3'>Enable bill search by table</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When this setting enabled a search box for table will show in payment screen, which will allow you to search sale by table name</div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Bill print: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.disableSalePrintInPayScreen")} />
                                <label className='m-3'>Disable sale print in pay screen</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When this setting set true, you cannot generate sale bill print from payment screen</div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.generatePrintInCashButton")} />
                                <label className='m-3'>Generate print in cash Button</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Enabling this setting will print sale bill when clicking cash button in sale screen</div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.generateSaleBillPrint")} />
                                <label className='m-3'>Generate sale bill print</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Enabling this setting will generate a print while billing in sale screen</div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.disableLastSaleBillPrintOnEnter")} />
                                <label className='m-3'>Disable last sale bill print on enter</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Last sale bill will print when enter key clicks in sale screen. When this setting set true, it will disable the print on enter</div>                        
                    <label><b>QR code: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.generateQrCodeInBillPrint")} />
                                <label className='m-3'>Generate QR code in bill print</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        This setting is used to generate QR code in sale bill print</div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Bill visibility: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showSaleBillInAllCounters")} />
                                <label className='m-3'>Show sale bill in all counters</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Normally sale bill of one counter will show only in that counter. When this setting enabled sale bill from any counter can view from all counters</div>                        
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showSaleBillInAllCounterAndSections")} />
                                <label className='m-3'>Show sale bill in all counters and sections</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When this setting enabled, bill from any section and counter can view from all sections and counters</div>               
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showSaleBillInAllSections")} />
                                <label className='m-3'>Show sale bill in all sections</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Enabling this setting will allow to see sale bill of one section in all other sections</div>
                        <hr />
                    </div>
                 
                    <div className="row">
                        <label><b>Change section: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showChangeSectionButtonInPay")} />
                                <label className='m-3'>Show change section button in pay</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        You can see a Change section button in payment screen when this setting is enabled</div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Pax: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showPaxPopupInSale")} />
                                <label className='m-3'>Show pax popup in sale</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        This setting will use to show a popup to enter number of pax in sale screen</div>
                        <hr />
                    </div>
                 

                   
                    <div className="row">
                        <label><b>Loyality: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.enableLoyalty")} />
                                <label className='m-3'>Enable loyalty</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Enabling this setting will add loyalty point for specified products for a customer. This points can redeem by the customers</div>
                        <hr />
                    </div>
                    <div className="row">
                        <label><b>Product name billing: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.enableProductNameBilling")} />
                                <label className='m-3'>Enable product name billing </label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Usually default billing method is by using code of products. When this setting enabled name can be use instead of code of products for billing </div>
                        <hr />
                    </div>
                    <div className="row">
                        <label><b>Cancel reason: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showCancelReasonPopupInPay")} />
                                <label className='m-3'>Show cancel reason popup in pay </label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        This setting is used to generate a popup to type the reason for cancelling an order in payment screen </div>
                        <hr />
                    </div>
                    <div className="row">
                        <label><b>Unit price of product: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showUnitPriceOfProductInSale")} />
                                <label className='m-3'>Show unit price of product in sale </label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        This setting is used to show unit price of product in billing screen </div>
                        <hr />
                    </div>
                    <div className="row">
                        <label><b>Token number in sale: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.enableTokenNumber")} />
                                <label className='m-3'>Enable token number </label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Usually a sale bill contains bill number, when this setting enabled a token number generate for a bill </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.enableSectionWiseTokenNumber")} />
                                <label className='m-3'>Enable section wise token number </label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        To generate section wise token number for a bill in addition to bill number </div>
                        <hr />
                    </div>
                    <div className="row">
                        <label><b>Chair selection: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.enableChairInSale")} />
                                <label className='m-3'>Enable chair in Sale </label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When this setting enabled, you can select chair in a table based on the capacity of that table in sale screen </div>
                        <hr />
                    </div>
                

                </div>


                <div className="col-lg-12">
                    <div className="modal-footer-btn">
                        <button
                            type="button"
                            className="btn btn-cancel me-2"
                            data-bs-dismiss="modal"
                            ref={closeButtonRef}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SettingSales
