import axios from "axios";

const base_path = process.env.REACT_APP_API_URL;

export const productValidation = async (data) => {
  const response = await axios.post(
    `${base_path}/api/Product/ImportedProducts/GetProductImportValidation`,
    JSON.stringify(data)
  );
  return response;
};
// Save product import
export const productSave = async (data) => {
  const response = await axios.post(
    `${base_path}/api/Product/ImportedProducts/Save`,
    JSON.stringify(data)
  );
  return response;
};
//   /api/Product/ProductImport/Save, sir gave
//         /api /Product /ImportedProducts /Save
