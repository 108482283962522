
// /src/core/utils/reportUtils.js
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

// Utility function to generate table headers
const generateTableHeaders = (columns) => {
    return columns.map((col) => col.dataIndex); // Use dataIndex for unique headers
  };
  
  const generateReadableHeaders = (columns) => {
    return columns.map((col) => col.title); // For display purposes
  };
  
  const generateTableBody = (data, tableHeaders) => {
    return data?.map((item) => {
      return tableHeaders?.map((header) => {
        let value = item[header];
        return value;
      });
    });
  };
  
  export const exportpandlExcel = (getActiveTabData, footerTotals, TfooterTotals, title) => {
    const { data1, data2, columns } = getActiveTabData();
  
    const tableHeaders1 = generateTableHeaders(columns);
    const readableHeaders1 = generateReadableHeaders(columns); // For display
    const tableBody1 = generateTableBody(data1, tableHeaders1);
    const footerRow1 = tableHeaders1.map((header) => TfooterTotals[header] || "");
  
    const tableHeaders2 = generateTableHeaders(columns);
    const readableHeaders2 = generateReadableHeaders(columns); // For display
    const tableBody2 = generateTableBody(data2, tableHeaders2);
    const footerRow2 = tableHeaders2.map((header) => footerTotals[header] || "");
  
    const worksheetData = [
      ["Trading Account"],
      readableHeaders1,
      ...tableBody1,
      footerRow1,
      [],
      ["Profit or Loss Account"],
      readableHeaders2,
      ...tableBody2,
      footerRow2,
    ];
  
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
  
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };
  

  export const printpandlReport = (getActiveTabData, footerTotals, TfooterTotals, title) => {
    const { data1, data2, columns } = getActiveTabData();
  
    // Generate readable headers for display
    const readableHeaders = columns.map((col) => col.title);
    const dataIndexHeaders = columns.map((col) => col.dataIndex);
  
    // Function to generate table HTML
    const generateTableHTML = (title, data, footerTotals) => {
      let tableHTML = `<h2>${title}</h2><table><thead><tr>`;
      readableHeaders.forEach((header) => {
        tableHTML += `<th>${header}</th>`;
      });
      tableHTML += "</tr></thead><tbody>";
  
      data.forEach((row) => {
        tableHTML += "<tr>";
        dataIndexHeaders.forEach((header) => {
          tableHTML += `<td>${row[header] !== undefined ? row[header] : ""}</td>`;
        });
        tableHTML += "</tr>";
      });
  
      const footerRowHTML = dataIndexHeaders
        .map((header) => `<td>${footerTotals[header] || ""}</td>`)
        .join("");
      tableHTML += `<tr>${footerRowHTML}</tr>`;
  
      tableHTML += "</tbody></table>";
      return tableHTML;
    };
  
    // Generate HTML for both tables
    const tradingTableHTML = generateTableHTML("Trading Account", data1, TfooterTotals);
    const profitTableHTML = generateTableHTML("Profit or Loss Account", data2, footerTotals);
  
    // Combine and print
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print Report</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(`
          table {
              width: 100%;
              border-collapse: collapse;
          }
          th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
          }
          th {
              background-color: #f2f2f2;
          }
      `);
    printWindow.document.write("</style></head><body>");
    printWindow.document.write(`<h1>${title}</h1>`);
    printWindow.document.write(tradingTableHTML);
    printWindow.document.write(profitTableHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };
  
export const exportpandlPDF = (getActiveTabData, footerTotals, TfooterTotals, title) => {
    const { data1, data2, columns } = getActiveTabData();
  
    const doc = new jsPDF();
  
    // Trading Account
    doc.text("Trading Account", 14, 20);
    const tableHeaders1 = generateTableHeaders(columns);
    const readableHeaders1 = generateReadableHeaders(columns);
    const tableBody1 = generateTableBody(data1, tableHeaders1);
    const footerRow1 = tableHeaders1.map((header) => TfooterTotals[header] || "");
    autoTable(doc, {
      head: [readableHeaders1],
      body: tableBody1,
      foot: [footerRow1],
      startY: 25,
    });
  
    // Profit or Loss Account
    const startY = doc.lastAutoTable.finalY + 10;
    doc.text("Profit or Loss Account", 14, startY);
    const tableHeaders2 = generateTableHeaders(columns);
    const readableHeaders2 = generateReadableHeaders(columns);
    const tableBody2 = generateTableBody(data2, tableHeaders2);
    const footerRow2 = tableHeaders2.map((header) => footerTotals[header] || "");
    autoTable(doc, {
      head: [readableHeaders2],
      body: tableBody2,
      foot: [footerRow2],
      startY: startY + 5,
    });
  
    doc.save(`${title}.pdf`);
  };
  
