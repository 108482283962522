import React, { useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Archive,
  User,
  Calendar,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { Table } from "antd";
import { setheadertoggle } from "../../../redux/toggle";
import { useDispatch, useSelector } from "react-redux";
import {
  getledgerreport,
  getSalesReport,
} from "../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  AccountFilterclass,
} from "../../../core/json/DatatablePagingParameters";
import { renderTooltip } from "../../../redux/helper";
import { Checkbox } from "antd";
import { DatePicker } from "antd";
// import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { fetchCounter, fetchSection } from "../../../redux/userSetting";
import { fetchUser } from "../../../redux/userServices";
import {
  exportreportExcel,
  exportreportPDF,
  printreportReport,
} from "../../../core/utils/MainReportUtils";
import LoadingUtils from "../../../core/utils/LoadingUtils";

const BankbookReport = () => {
  const [searchText, setSearchText] = useState("");
  const [salesData, setsalesData] = useState();
  const [paymenttype, setPaymenttype] = useState();
  const [waiter, setwaiter] = useState();
  const [counterval, setcounterval] = useState();
  const [sectionval, setsectionval] = useState();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [totalcount, settotalcount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { counterList, sectionList } = useSelector(
    (state) => state.usersetting
  );
  const { UserList } = useSelector((state) => state.userservice);
  const tableRef = useRef(null);

  useEffect(() => {
    handlegetsalesReport(currentPage, pageSize);
    dispatch(fetchSection(branch.guid));
    dispatch(fetchCounter(branch.guid));
    dispatch(fetchUser());
  }, [branch]);

  const handlegetsalesReport = async (page, size) => {
    const DatatablePagingParameters = new DatatablePagingParameter();
    DatatablePagingParameters.PageNumber = 1;
    DatatablePagingParameters.PageSize = -1;
    // const formattedfromDate = "2024-10-01T00:00:00";
    // const formattedtoDate = "2024-11-30T00:00:00";
    const formattedfromDate = dayjs(fromDate).format("YYYY-MM-DD");
    const formattedtoDate = dayjs(toDate).format("YYYY-MM-DD");
    const Filters = new AccountFilterclass();
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    try {
      const response = await getledgerreport({
        DatatablePagingParameters,
        Filters,
      });
      console.log(response?.data);
      const consolidated = response?.data?.ledgerReportDetails?.filter(
        (item) => {
          return item.group === "BANK ACCOUNTS"; // Filter items with the group "CASH-IN-HAND"
        }
      );

      setsalesData(consolidated);

      settotalcount(consolidated?.length);
    } catch (error) {
      console.error(
        "Error Fetching Account Type!",
        error?.response?.data?.Message
      );
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const printTable = () => {
    window.print();
  };

  const menuItems = [
    { value: "1", label: "Sl.No", checked: true },
    { value: "2", label: "Date", checked: true },
    { value: "3", label: "Particulars", checked: true },
    { value: "4", label: "Type", checked: true },
    { value: "5", label: "No", checked: true },
    { value: "6", label: "Debit", checked: true },
    { value: "7", label: "Credit", checked: true },
    { value: "8", label: "Narration", checked: true },
  ];

  const columns = [
    {
      title: "Sl No", // The title of the column, displayed in the table header.
      key: "1", // A unique identifier for this column (important for React's reconciliation process).
      render: (text, _, index) => index + 1, // A custom render function to calculate and display the serial number.
    },
    {
      title: "Date",
      key: "2",
      dataIndex: "date",
      render: (text) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });
        };

        return <span>{formatDate(text)}</span>;
      },
    },

    {
      title: "Particulars",
      key: "3",
      dataIndex: "particulars",
    },

    {
      title: "Type",
      key: "4",
      dataIndex: "type",
    },
    {
      title: "No",
      key: "5",
      dataIndex: "no",
    },
    {
      title: "Debit",
      key: "6",
      dataIndex: "debit",
      render: (text) => <span>{text.toFixed(2)}</span>,
    },
    {
      title: "Credit",
      key: "7",
      dataIndex: "credit",
      render: (text) => <span>{text.toFixed(2)}</span>,
    },
    {
      title: "Narration",
      key: "8",
      dataIndex: "narration",
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState();

  useEffect(() => {
    // Set default selected columns based on menuItems' checked status
    const initialSelectedColumns = menuItems
      ?.filter((item) => item.checked)
      .map((item) => columns.find((col) => col.key === item.value));

    setSelectedColumns(initialSelectedColumns);
  }, []);
  console.log(selectedColumns, "qqqqqq");

  const handleCheckboxChange = (columnKey, checked) => {
    if (checked) {
      // Add column if checkbox is checked
      setSelectedColumns((prevColumns) => {
        const updatedColumns = [
          ...prevColumns,
          columns.find((col) => col.key === columnKey),
        ];

        // Sort the array by col.key in ascending order
        return updatedColumns.sort((a, b) => (a.key > b.key ? 1 : -1));
      });
    } else {
      // Remove column if checkbox is unchecked
      setSelectedColumns((prevColumns) =>
        prevColumns?.filter((col) => col.key !== columnKey)
      );
    }
  };

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleFromDateChange = (date) => {
    console.log(date, "from date");
    setFromDate(date);

    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
    console.log(date, "to date");
  };
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };

    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);

    console.log("fieldMapping of first table:", fieldMapping);
    console.log("salesData of  table:", salesData);

    // Return the filtered data, columns, and field mapping
    return { data: salesData, columns: selectedColumns, fieldMapping };
  };
  const totalcredit = salesData?.reduce(
    (sum, record) => sum + record.credit,
    0
  );
  const totaldebit = salesData?.reduce((sum, record) => sum + record.debit, 0);
  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setSearchText("");
    setPaymenttype("");
    setwaiter("");
    setcounterval("");
    setsectionval("");
    handlegetsalesReport(currentPage, pageSize);
  };
  const footerTotals = {
    credit: totalcredit?.toFixed(3) || 0.0,
    debit: totaldebit?.toFixed(3) || 0.0,
  };

  return (
    <div>
      <LoadingUtils loading={loading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Bank Book Report</h4>
                <h6>Manage Bank Book Report here</h6>
              </div>
            </div>

            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <button
                    onClick={() =>
                      exportreportPDF(getActiveTabData, "Bank Book Report")
                    }
                    disabled={loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <button
                    onClick={() =>
                      exportreportExcel(getActiveTabData, "Bank Book Report")
                    }
                    disabled={loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="excel-icon"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <button
                    onClick={() =>
                      printreportReport(getActiveTabData, "Bank Book Report")
                    }
                    disabled={loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters rounded-2 h-100 "
                      onClick={() => {
                        setCurrentPage(1);
                        setPageSize(20);
                        handlegetsalesReport(currentPage, pageSize);
                      }}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    onClick={toggleFilterVisibility}
                    id="filter_search"
                  >
                    <Filter className="filter-icon" />
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>

                <div className="dropdown">
                  <button
                    className="btn  dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <Sliders className="info-img" />
                  </button>
                  <ul className="dropdown-menu p-2">
                    {menuItems?.map((obj, i) => {
                      return (
                        <li key={i}>
                          <Checkbox
                            onChange={(e) =>
                              handleCheckboxChange(obj.value, e.target.checked)
                            }
                            defaultChecked={obj.checked}
                          >
                            <div style={{ color: "black" }}> {obj.label}</div>
                          </Checkbox>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
                        <label>From Date</label>
                        <div className="input-groupicon calender-input">
                          <Calendar className="info-img" />
                          <DatePicker
                            className="datetimepicker"
                            id="from-date"
                            placeholderText="From Date"
                            // selected={fromDate}
                            value={fromDate}
                            onChange={handleFromDateChange}
                            startDate={fromDate}
                            selectsStart
                            // dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>To Date</label>
                        <div className="input-groupicon calender-input">
                          <Calendar className="info-img" />
                          <DatePicker
                            id="to-date"
                            placeholderText="To Date"
                            // selected={toDate}
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate}
                            startDate={fromDate}
                            selectsEnd
                            disabled={!fromDate} // disable until from date is selected
                            // dateFormat="dd/MM/yyyy"
                            // className='form-control'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              {selectedColumns && (
                <div className="table-responsive product-list">
                  <Table
                    columns={selectedColumns}
                    dataSource={salesData}
                    ref={tableRef}
                    id="table-to-export"
                    className="table datanew dataTable no-footer custom-table"
                    pagination={{
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${totalcount} items`,
                      current: currentPage,
                      pageSize: pageSize,
                      total: totalcount, // Total records count
                      onChange: (page, size) => {
                        setCurrentPage(page);
                        setPageSize(size);
                        handlegetsalesReport(page, size);
                      },
                      showSizeChanger: true, // To enable page size selection dropdown
                      pageSizeOptions: ["10", "20", "50", "100"], // Different page size options
                    }}
                    summary={() => (
                      <Table.Summary.Row>
                        {selectedColumns?.map((col) => (
                          <Table.Summary.Cell key={col.dataIndex}>
                            <b>{footerTotals[col.dataIndex] || ""}</b>
                          </Table.Summary.Cell>
                        ))}
                      </Table.Summary.Row>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};
export default BankbookReport;
