import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  addPromotion,
  editPromotion,
} from "../../../services/MasterApiServices";
import toast from "react-hot-toast";
import { PromotionClass } from "../../../core/json/PromotionClass";
import { useSelector } from "react-redux";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import { controllers } from "chart.js";
const PromotionModal = ({ mode, data, handleClose, handleRefresh }) => {
  const { branch } = useSelector((state) => state.branchlist);
  const { RangePicker } = DatePicker;

  const { userId } = useSelector((state) => state.userauth);

  const { register, handleSubmit, setValue, reset, control, watch, getValues } =
    useForm({
      mode: "onChange",
      reValidateMode: "onChange",
    });
  const isHappyHour = watch("isHappyHour", false);
  function resetmodal() {
    if (mode === "edit" && data) {
      console.log(data);
      setValue("name", data.name);
      setValue("isHappyHour", data.isHappyHour || false);
      if (data.isHappyHour) {
        setValue("fromTime", dayjs(data.fromTime).format("HH:mm") || null);
        setValue("toTime", dayjs(data.toTime).format("HH:mm") || null);
      } else {
        setValue("fromTime", null);
        setValue("toTime", null);
      }
      if (data.fromDate && data.toDate) {
        setValue("date", [
          dayjs(data.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
          dayjs(data.toDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
        ]);
      } else {
        setValue("date", []);
      }
    } else {
      reset();
    }
  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);

  const handleFormSubmit = async (formData) => {
    const objpromotion = new PromotionClass();
    objpromotion.name = formData.name;

    objpromotion.branch = branch;

    objpromotion.updatedUser = `{${userId?.id}}`;
    if (mode === "edit") {
      objpromotion.guid = data.guid;
      objpromotion.id = data.id;
    }
    objpromotion.isHappyHour = isHappyHour;
    objpromotion.fromDate = formData.date[0]
      ? dayjs(formData.date[0]).format("YYYY-MM-DD")
      : null;
    objpromotion.toDate = formData.date[1]
      ? dayjs(formData.date[1]).format("YYYY-MM-DD")
      : null;

    const setTime = (timeString) => {
      if (!timeString) return null;
      const [hours, minutes] = timeString.split(":").map(Number);
      const time = new Date();
      time.setHours(hours, minutes, 0, 0);
      console.log(time, "after setting hour");
      const localTime = dayjs(time).format("YYYY-MM-DDTHH:mm:ss");
      console.log(localTime);
      return localTime;
    };

    objpromotion.fromTime = formData.isHappyHour
      ? setTime(formData.fromTime)
      : new Date();
    console.log(objpromotion.fromTime, "obj ftyu");

    objpromotion.toTime = formData.isHappyHour
      ? setTime(formData.toTime)
      : new Date();

    try {
      const response =
        mode === "edit"
          ? await editPromotion(objpromotion)
          : await addPromotion(objpromotion);

      if (response) {
        toast.success("Success");
        handleRefresh();
        handleClose();
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "something went wrong");
      console.error(
        "Error handling category:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {mode === "edit" ? "Edit Promotion" : "Add Promotion"}
                    </h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleClose}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Name"
                            {...register("name")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Date <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="date"
                            control={control}
                            defaultValue={[]}
                            rules={{ required: "Date is required" }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <>
                                <RangePicker
                                  value={
                                    value?.length === 2
                                      ? [
                                          dayjs(value[0], "YYYY-MM-DD"),
                                          dayjs(value[1], "YYYY-MM-DD"),
                                        ]
                                      : null
                                  }
                                  format="YYYY-MM-DD"
                                  onChange={(dates) =>
                                    onChange(
                                      dates
                                        ? dates.map((date) =>
                                            date.format("YYYY-MM-DD")
                                          )
                                        : []
                                    )
                                  }
                                />
                                {error && (
                                  <span className="text-danger">
                                    {error.message}
                                  </span>
                                )}{" "}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Happy Hour <span className="text-danger">*</span>
                          </label>
                          <input type="checkbox" {...register("isHappyHour")} />
                        </div>
                      </div>
                      {isHappyHour && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks">
                              <label>
                                From Time <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="fromTime"
                                control={control}
                                rules={{
                                  required: "From Time is required",
                                }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <TimePicker
                                      value={
                                        value ? dayjs(value, "HH:mm") : null
                                      }
                                      format="HH:mm"
                                      onChange={(time) =>
                                        onChange(
                                          time ? time.format("HH:mm") : null
                                        )
                                      }
                                    />
                                    {error && (
                                      <span className="text-danger">
                                        {error.message}
                                      </span>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks">
                              <label>
                                To Time <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="toTime"
                                control={control}
                                rules={{
                                  required: "To Time is required",
                                  validate: (value) => {
                                    const fromTime = dayjs(
                                      getValues("fromTime"),
                                      "HH:mm"
                                    );
                                    const toTime = dayjs(value, "HH:mm");
                                    return (
                                      fromTime.isBefore(toTime) ||
                                      "To Time must be greater than From Time"
                                    );
                                  },
                                }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <TimePicker
                                      value={
                                        value ? dayjs(value, "HH:mm") : null
                                      }
                                      format="HH:mm"
                                      onChange={(time) =>
                                        onChange(
                                          time ? time.format("HH:mm") : null
                                        )
                                      }
                                    />
                                    {error && (
                                      <span className="text-danger">
                                        {error.message}
                                      </span>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          onClick={() => resetmodal()}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-submit">
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionModal;
