import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronUp, Filter, RotateCcw } from "feather-icons-react";
import Table from "../../../core/pagination/datatableReports";
import { useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";

import { setheadertoggle } from "../../../redux/toggle";
import { renderTooltip } from "../../../redux/helper";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../../core/utils/reportUtils";

import { DatePicker, Select, Tabs } from "antd";
import { getConsolidatedReportItemwise } from "../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  Filterclass,
} from "../../../core/json/DatatablePagingParameters";
import dayjs from "dayjs";
import { useMemo } from "react";
import Branch from "../../../core/json/Branch";
import { Calendar } from "feather-icons-react/build/IconComponents";
import LoadingUtils from "../../../core/utils/LoadingUtils";

const ItemsReport = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [reportData, setreportData] = useState([]);
  const [modifiedList, setModifiedList] = useState();
  const [loadingDes, setLoadingDes] = useState(false);
  const [modifiedFooterTotals, setModifiedFooterTotals] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
  const { TabPane } = Tabs;

  const { togglehead } = useSelector((state) => state.headerToggle);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  useEffect(() => {
    handleReportList();
  }, [branch, toDate]);
  useEffect(() => {
    handleFilter();
  }, [searchText]);

  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSearchText("");
    setModifiedList([]);
    detailData = [];
  }
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const getActiveTabData = () => {
    let fieldMapping = {};

    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex;
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(detailColumns);

        return {
          data: modifiedList?.length > 0 ? modifiedList : detailData,
          columns: detailColumns,
          fieldMapping,
        };
      case "2":
        fieldMapping = createfieldMapping(summaryColumns);

        return {
          data: modifiedList?.length > 0 ? modifiedList : detailData,
          columns: summaryColumns,
          fieldMapping,
        };
      default:
        return { data: [], columns: [] };
    }
  };

  const handleReportList = async () => {
    setLoadingDes(true);
    if (!fromDate || !toDate) {
      setLoadingDes(false);
      return;
    }
    const DatatablePagingParameters = new DatatablePagingParameter();
    const branchobj = new Branch();
    const formattedfromDate = dayjs(fromDate)
      .startOf("day")
      .format("YYYY-MM-DD");
    const formattedtoDate = dayjs(toDate).endOf("day").format("YYYY-MM-DD");
    // const formattedfromDate = dayjs(fromDate).add(1, "day");
    // const formattedtoDate = dayjs(toDate).add(1, "day");
    const Filters = new Filterclass();
    DatatablePagingParameters.PageNumber = 1;
    DatatablePagingParameters.PageSize = -1;
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.PaymentType = "";
    Filters.Waiter = "";
    Filters.Counter = "";
    Filters.Section = "";
    try {
      const response = await getConsolidatedReportItemwise({
        DatatablePagingParameters,
        Filters,
      });

      setLoadingDes(false);
      // Check if the response contains valid array data
      const itemWiseSalesReportDetail =
        response?.data?.itemWiseSalesReportDetail || [];

      if (Array.isArray(itemWiseSalesReportDetail)) {
        const dataArray = itemWiseSalesReportDetail?.map((item) => ({
          branch: item?.branch?.name,
          product: item?.product?.name,
          netTotal: item?.total -item?.discount,
        }));

        setreportData(dataArray);
      } else {
        console.error("Data is not in the expected array format");

        setreportData([]);
      }
    } catch (error) {
      console.error(error?.response?.data?.Message || "Something went wrong");
      setreportData([]);
      setLoadingDes(false);
    }
  };
  let { branches, detailData } = useMemo(() => {
    // Ensure reportData is an array and log it for debugging
    const data = Array.isArray(reportData) ? reportData : [];

    // Extract unique branches
    const branches = [...new Set(data?.map((item) => item.branch))];

    // Accumulate product-wise totals for each branch and calculate itemTotal for each product
    let detailData = data.reduce((acc, { branch, product, netTotal }) => {
      const existingProduct = acc.find((item) => item.product === product);

      if (existingProduct) {
        // Update branch totals for existing products
        existingProduct[branch] = (existingProduct[branch] || 0) + netTotal;
      } else {
        // Create new product entry
        acc.push({
          product,
          key: product,
          [branch]: netTotal, // Dynamically add branch as a key with netTotal
        });
      }

      return acc;
    }, []);

    // After constructing detailData, calculate the itemTotal for each product
    const detailDataWithTotals = detailData?.map((item) => {
      const itemTotal = branches.reduce((sum, branch) => {
        return sum + (item[branch] || 0);
      }, 0);

      return {
        ...item,
        itemTotal, // Add itemTotal for each product
      };
    });

    return { branches, detailData: detailDataWithTotals };
  }, [reportData]);

  // Function to calculate totals for the footer
  // Function to calculate totals for the footer
  const calculateFooterTotals = (data) => {
    const footerTotals = {};

    // Iterate through each branch to calculate totals
    branches.forEach((branch) => {
      footerTotals[branch] = data?.reduce(
        (sum, item) => sum + (item[branch] || 0),
        0
      );
    });

    return footerTotals;
  };

  // Example usage
  let footerTotals = calculateFooterTotals(detailData);

  //grand grand footer totals
  const grandTotal = useMemo(() => {
    return Object.values(footerTotals).reduce((sum, total) => sum + total, 0);
  }, [footerTotals]);

  footerTotals = { ...footerTotals, itemTotal: grandTotal };

  const handleFilter = () => {
    handleReportList();

    const filteredData = detailData?.filter((value) =>
      Object?.values(value)?.some((val) => {
        const lowerCasedSearchText = searchText?.toLowerCase() || "";

        // Check if val is a string and contains the search text
        const stringMatch =
          typeof val === "string" &&
          val.toLowerCase().includes(lowerCasedSearchText);

        // Check if val is a number and matches the search text
        const numberMatch =
          typeof val === "number" &&
          val.toString().includes(lowerCasedSearchText);

        return stringMatch || numberMatch; // Return true if either matches
      })
    );

    setModifiedList(filteredData);
  };

  const detailColumns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    ...branches.map((branch) => ({
      title: branch,
      dataIndex: branch,
      key: branch,
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0.0),
    })),
    {
      title: "Total",
      dataIndex: "itemTotal",
      key: "itemTotal",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0.0),
    },
  ];

  const summaryColumns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Total",
      dataIndex: "itemTotal",
      key: "itemTotal",
      render: (value) => (value !== undefined ? value?.toFixed(2) : 0.0),
    },
  ];
  return (
    <div>
      <LoadingUtils loading={loadingDes} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Consolidated Itemwise Reports</h4>
                <h6>Manage branchwise consolidated reports here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() => exportPDF(getActiveTabData, footerTotals,"Item Reports")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(getActiveTabData, footerTotals,"Item Reports")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => printReport(getActiveTabData, footerTotals,"Item Reports")}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleReportList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters rounded-2 h-100 "
                      onClick={handleFilter}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks ms-4">
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          selected={fromDate}
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Detailed View" key="1">
                    <Table
                      columns={detailColumns}
                      dataSource={
                        modifiedList?.length > 0 ? modifiedList : detailData
                      }
                      footerTotals={footerTotals}
                    />
                  </TabPane>
                  <TabPane tab="Summary View" key="2">
                    <Table
                      columns={summaryColumns}
                      dataSource={
                        modifiedList?.length > 0 ? modifiedList : detailData
                      }
                      footerTotals={footerTotals}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ItemsReport;
