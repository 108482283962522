import { DomainBase } from "./DomainBase";

class SectionWiseProductDetail extends DomainBase {
  constructor() {
    super();
    this.ProductId = 0;
    this.Section = null;
    this.Tax = null;
    this.Price = 0;
    this.IsTaxIncludedInPrice = false;
    this.MasterId = "";
    this.Discount = null;
    this.IsActive = false;
  }
}
export default SectionWiseProductDetail;
