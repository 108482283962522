import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronUp, Filter, RotateCcw } from "feather-icons-react";
import Table from "../../../core/pagination/datatableReports";
import { useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import LoadingUtils from "../../../core/utils/LoadingUtils";
import { setheadertoggle } from "../../../redux/toggle";
import { renderTooltip } from "../../../redux/helper";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../../core/utils/reportUtils";

import { DatePicker, Select, Tabs, TimePicker } from "antd";
import {
  getConsolidatedReportItemwise,
  getTimeBasedReport,
} from "../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  Filterclass,
} from "../../../core/json/DatatablePagingParameters";
import dayjs from "dayjs";
import { useMemo } from "react";
import Branch from "../../../core/json/Branch";
import { Calendar } from "feather-icons-react/build/IconComponents";
import isBetween from "dayjs/plugin/isBetween";

const HourlySalesReport = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [groupedData, setGroupedData] = useState([]);
  const [grandTotal, setgrandTotal] = useState([]);
  const [reportData, setreportData] = useState([]);
  const [APIData, setAPIData] = useState();
  const [loadingDes, setLoadingDes] = useState(false);
  const [modifiedFooterTotals, setModifiedFooterTotals] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const { branch } = useSelector((state) => state.branchlist);
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const format = "HH:mm";
  dayjs.extend(isBetween);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);

  // Function to filter report data based on selected time range
  const filterReportDataByTime = (data, fromTime, toTime) => {
    // Format fromTime and toTime to keep only the time part
    const fromTimeStr = dayjs(fromTime).format("HH:mm");
    const toTimeStr = dayjs(toTime).format("HH:mm");

    // Filter data within the specified time range, ignoring date
    console.log(fromTimeStr, toTimeStr, data, "data");

    return data.filter((item) => {
      const billTimeStr = item.billTime
        ? dayjs(item.billTime).format("HH:mm")
        : ""; // Format billTime as "HH:mm"

      // Check if billTimeStr falls between fromTimeStr and toTimeStr (inclusive)

      return billTimeStr >= fromTimeStr && billTimeStr <= toTimeStr;
    });
  };

  // Function to filter and aggregate data based on the selected time range
  const filterAndAggregateData = (data, fromTime, toTime) => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return;
    }

    const format = "HH:mm"; // Ensure the format is consistent
    const fromTimeObj = dayjs(fromTime, format);
    const toTimeObj = dayjs(toTime, format);

    // Aggregate the filtered data
    const aggregatedData = data.reduce(
      (acc, curr) => {
        acc.netTotal += curr.netTotal || 0; // Sum net totals
        return acc;
      },
      { netTotal: 0 } // Initial accumulator with netTotal only
    );

    // Prepare the final aggregated object
    return [
      {
        billCount: data.length, // Use the length of filteredData as billCount
        netTotal: aggregatedData.netTotal, // Total net amount
        hour: `${fromTimeObj.format(format)} - ${toTimeObj.format(format)}`, // Formatted hour range
        netTotalPercent: "", // Optional: Percentage, if required
      },
    ];
  };

  function getTimeData() {
    if (fromTime && toTime && fromDate && toDate) {
      console.log("Fetching report data for:", {
        fromDate,
        toDate,
        fromTime: fromTime?.format(format),
        toTime: toTime?.format(format),
      });
      const filteredData = filterReportDataByTime(APIData, fromTime, toTime);
      console.log("Filtered Data:", filteredData);

      // Call the filter and aggregate function and store the result
      const aggregatedResult = filterAndAggregateData(
        filteredData,
        fromTime,
        toTime
      );
      setGroupedData(aggregatedResult);
      setreportData(filteredData);
      setgrandTotal({});
    }
  }
  console.log(reportData, "report");

  useEffect(() => {
    getTimeData();
  }, [fromTime, toTime, toDate]);
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    handleFilter(e.target.value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  useEffect(() => {
    handleReportList();
    if (searchText) {
      handleFilter(searchText);
    }
  }, [branch, fromDate, toDate]);

  const handleFilter = (text) => {
    const lowerCasedSearchText = text?.toLowerCase() || "";
    const filterData = (data) =>
      data.filter((item) =>
        Object.values(item)?.some((val) => {
          // Check if val is a string and contains the search text
          const stringMatch =
            typeof val === "string" &&
            val.toLowerCase().includes(lowerCasedSearchText);

          // Check if val is a number and matches the search text
          const numberMatch =
            typeof val === "number" &&
            val.toString().includes(lowerCasedSearchText);

          return stringMatch || numberMatch;
        })
      );
    const filteredGroupedData = filterData(groupedData);
    const filteredReportData = filterData(reportData);
    setGroupedData(filteredGroupedData);
    setreportData(filteredReportData);
    if (lowerCasedSearchText === "") {
      handleReportList();
    }
  };
  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setToTime(null);
    setFromTime(null);
    setSearchText("");
    setGroupedData([]);
    setreportData([]);
    setgrandTotal({});
  };
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const getActiveTabData = () => {
    let fieldMapping = {};

    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex;
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);

        return {
          data: groupedData,
          columns: columns,
          fieldMapping,
        };
      case "2":
        fieldMapping = createfieldMapping(detailedColumns);

        return {
          data: reportData,
          columns: detailedColumns,
          fieldMapping,
        };
      default:
        return { data: [], columns: [] };
    }
  };

  const handleReportList = async () => {
    setLoadingDes(true);
    if (!fromDate || !toDate) {
      setLoadingDes(false);
      setGroupedData([]);
      setgrandTotal([]);
      setreportData([]);
      return;
    }
    const DatatablePagingParameters = new DatatablePagingParameter();
    // const branchobj = new Branch();
    const formattedfromDate = dayjs(fromDate).add(1, "day");
    const formattedtoDate = dayjs(toDate).add(1, "day");
    const Filters = new Filterclass();
    DatatablePagingParameters.PageNumber = 1;
    DatatablePagingParameters.PageSize = -1;
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    Filters.PaymentType = "";
    Filters.Waiter = "";
    Filters.Counter = "";
    Filters.Section = "";
    try {
      const response = await getTimeBasedReport({
        DatatablePagingParameters,
        Filters,
      });
      setLoadingDes(false);
      const data = response?.data?.salesReportTime || [];
      setreportData(data);
      setAPIData(data);

      groupBillsByHour(data);

      setLoadingDes(false);
    } catch (error) {
      console.error(error || "Something went wrong");
      setreportData([]);
    }
  };
  function groupBillsByHour(data) {
    if (fromTime && toTime && fromDate && toDate) {
      getTimeData();
    } else {
      const result = {};

      data.forEach((bill) => {
        const hour = new Date(bill.billTime).getHours(); // Extract hour
        const hourRange = `${String(hour).padStart(2, "0")}:00 - ${String(
          hour
        ).padStart(2, "0")}:59`; // 24-hour format

        const date = bill.transactionDate; // Extract transactionDate

        if (!result[date]) {
          result[date] = {}; // Initialize an object for each date
        }

        if (!result[date][hourRange]) {
          result[date][hourRange] = {
            hour: hourRange,
            billCount: 0,
            netTotal: 0,
          };
        }

        result[date][hourRange].billCount += 1;
        result[date][hourRange].netTotal += bill.netTotal;
      });

      // Convert to an array and sort by transactionDate and hour
      const groupedArray = Object.keys(result)
        .sort() // Sort dates in ascending order
        .flatMap((date) =>
          Object.values(result[date]).sort(
            (a, b) =>
              parseInt(a.hour.split(":")[0]) - parseInt(b.hour.split(":")[0])
          )
        );

      // Calculate grand total
      const grandTotal = groupedArray.reduce(
        (acc, curr) => acc + curr.netTotal,
        0
      );

      // Calculate grand bill count
      const grandBillCount = groupedArray.reduce(
        (acc, curr) => acc + curr.billCount,
        0
      );

      const footerTotals = {
        billCount: grandBillCount,
        netTotal: grandTotal,
      };

      // Add netTotal percentage
      groupedArray.forEach((item) => {
        item.netTotalPercent = ((item.netTotal / grandTotal) * 100).toFixed(2); // Format to 2 decimals
      });
      setGroupedData(groupedArray);
      setgrandTotal(footerTotals);
      // return { groupedArray, footerTotals };
    }
  }
  const shouldShowNetTotalPercent = groupedData.some(
    (row) => row.netTotalPercent
  );

  const columns = [
    {
      title: "Time Range",
      dataIndex: "hour",
      key: "hour",
      sorter: (a, b) => {
        const [startA] = a.hour.split(" - ");
        const [startB] = b.hour.split(" - ");
        return parseInt(startA.split(":")[0]) - parseInt(startB.split(":")[0]);
      },
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Bill Count",
      dataIndex: "billCount",
      key: "billCount",
      sorter: (a, b) => a.billCount - b.billCount,
    },
    {
      title: "Sales Amount",
      dataIndex: "netTotal",
      key: "netTotal",
      sorter: (a, b) => a.netTotal - b.netTotal,
      render: (value) => value.toFixed(2),
    },

    ...(shouldShowNetTotalPercent
      ? [
          {
            title: "Sales %",
            dataIndex: "netTotalPercent",
            key: "netTotalPercent",
            sorter: (a, b) =>
              parseFloat(a.netTotalPercent) - parseFloat(b.netTotalPercent),
            render: (value) => `${value}%`,
          },
        ]
      : []),
  ];

  const detailedColumns = [
    {
      title: "Bill No.",
      dataIndex: "billNo",
      key: "billNo",
      sorter: (a, b) => a.billNo - b.billNo,
    },
    {
      title: "Date and Time",
      dataIndex: "billTime",
      key: "billTime",
      sorter: (a, b) => new Date(a.billTime) - new Date(b.billTime),
      render: (text) => {
        const date = new Date(text);
        const formattedDate = date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
        return <strong>{formattedDate}</strong>;
      },
    },
    {
      title: "User",
      dataIndex: "userName",
      key: "userName",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: "Counter",
      dataIndex: "counterName",
      key: "counterName",
      sorter: (a, b) => a.counterName.localeCompare(b.counterName),
    },
    {
      title: "Section",
      dataIndex: "sectionName",
      key: "sectionName",
      sorter: (a, b) => a.sectionName.localeCompare(b.sectionName),
    },
    {
      title: "Amount",
      dataIndex: "netTotal",
      key: "netTotal",
      sorter: (a, b) => a.netTotal - b.netTotal,
    },
  ];

  return (
    <div>
      <LoadingUtils loading={loadingDes} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Hourly Reports</h4>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(getActiveTabData, grandTotal,"Hourly Reports")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() => exportExcel(getActiveTabData, grandTotal,"Hourly Reports")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() => printReport(getActiveTabData, grandTotal,"Hourly Reports")}
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handleReportList()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body my-2 mx-2">
              {/* <div className="table-top"> */}
              <div className="row gx-2 gy-2 align-items-center">
                {/* Search Input and Time Pickers */}
                <div className="col-lg-3 col-md-6 col-12 me-1 ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm me-2 formsearch "
                    aria-controls="DataTables_Table_0"
                    value={searchText}
                    onChange={handleSearch}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-12 d-flex">
                  <TimePicker
                    className="form-control form-control-sm me-2"
                    value={fromTime}
                    onChange={setFromTime}
                    placeholder="From Time"
                    format={format}
                  />
                  {/* </div>
                  <div className="col-lg-2 col-md-6 col-12"> */}
                  <TimePicker
                    className="w-100"
                    value={toTime}
                    onChange={setToTime}
                    placeholder="To Time"
                    format={format}
                    disabled={!fromTime}
                  />
                </div>

                {/* Search and Reset Buttons Positioned on the Right */}
                <div className="col-lg-4 col-md-6 col-12  d-flex justify-content-end">
                  <button className="btn btn-submit rounded-2  me-2">
                    <i data-feather="search" className="feather-search" />{" "}
                    Search
                  </button>
                  <button
                    className="btn btn-reset rounded-2"
                    onClick={handleReset}
                  >
                    <i
                      data-feather="refresh-cw"
                      className="feather-refresh-cw"
                    />{" "}
                    Reset
                  </button>
                </div>
              </div>
              {/* </div> */}

              <div className="card-body py-3">
                <div className="row gx-2 gy-2">
                  <div className="col-lg-3 col-sm-6 mx-2">
                    <label>From Date</label>
                    <div className="input-group">
                      <DatePicker
                        className="form-control"
                        id="from-date"
                        placeholderText="From Date"
                        selected={fromDate}
                        value={fromDate}
                        onChange={handleFromDateChange}
                        startDate={fromDate}
                        selectsStart
                        dateFormat="dd/MM/yyyy"
                      />
                      <span className="input-group-text">
                        <Calendar className="info-img" />
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 mx-2">
                    <label>To Date</label>
                    <div className="input-group">
                      <DatePicker
                        className="form-control"
                        id="to-date"
                        placeholderText="To Date"
                        value={toDate}
                        onChange={handleToDateChange}
                        minDate={fromDate}
                        startDate={fromDate}
                        selectsEnd
                        disabled={!fromDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      <span className="input-group-text">
                        <Calendar className="info-img" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive mt-3">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Summary View" key="1">
                    <Table
                      columns={columns}
                      dataSource={groupedData}
                      footerTotals={grandTotal}
                    />
                  </TabPane>
                  <TabPane tab="Detailed View" key="2">
                    <Table
                      columns={detailedColumns}
                      dataSource={reportData}
                      footerTotals={grandTotal}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HourlySalesReport;
