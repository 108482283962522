import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
  Calendar,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import {
  getStockByBranch,
  deleteStock,
} from "../../services/OpeningStockApiServices";
import { OverlayTrigger, TabPane, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import dayjs from "dayjs";
import { renderTooltip } from "../../redux/helper";

import { DatePicker, Tabs } from "antd";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../core/utils/reportUtils";

import OpeningStockModal from "../../components/transactions/modal/OpeningStockModal";
import { OpeningStockMaster } from "../../core/json/OpeningStockMaster";
import LoadingUtils from "../../core/utils/LoadingUtils";

const OpeningStock = () => {
  const [searchText, setSearchText] = useState("");
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  const [stockList, setStockList] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [filteredStockList, setFilteredStockList] = useState([]);
  const [detailedData, setDetailedData] = useState([]);
  const [filDetailedData, setFilDetailedData] = useState([]);

  const { togglehead } = useSelector((state) => state.headerToggle);

  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const { menuItems } = useSelector((state) => state.amc);
  
  const [permit, setpermit] = useState();
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Opening Stock")
    );
  }, []);
  const dispatch = useDispatch();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };

  useEffect(() => {
    handlestockData();
  }, [branch]);

  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(null);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create a column mapping (column title to dataIndex)
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);
        console.log("fieldMapping of first table:", fieldMapping);
        return { data: filteredStockList, columns: columns, fieldMapping };
      case "2":
        fieldMapping = createfieldMapping(detailedColumns);
        console.log("fieldMapping of second table:", fieldMapping);
        return {
          data: filDetailedData,
          columns: detailedColumns,
          fieldMapping,
        };

      default:
        return { data: [], columns: [] };
    }
  };

  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSearchText("");
    handlestockData();
  }
  const modifiedDetDataFn = (data) => {
    return data?.openingStockDetail?.map((item, index) => {
      return {
        key: index,
        date: data?.date,
        no: data?.entryNo,
        guid: data?.guid,
        product: item?.product?.name,
        unit: item?.unit?.name,
        batch: item?.batch,
        quantity: item?.quantity,
        price: item?.price,
      };
    });
  };
  const handlestockData = async () => {
    setloading(true)
    try {
      const response = await getStockByBranch(branch);
      const stockData = Array.isArray(response) ? response : [];

      setStockList(stockData);
setloading(false)
      const modifiedData =
        stockData?.map((entry, index) => {
          return {
            key: index,
            date: entry?.date,
            financialYear: "",
            no: entry?.entryNo || 0,
            branch: branch,
            remarks: entry?.remarks || "",
            guid: entry?.guid,
          };
        }) || [];

      const modifiedDetailData = stockData.flatMap((stockItem) =>
        modifiedDetDataFn(stockItem)
      );

      setFilDetailedData(modifiedDetailData);
      setDetailedData(modifiedDetailData);
      setStockData(modifiedData);
      setFilteredStockList(modifiedData);
    } catch (error) {
      setloading(false)
      console.error(
        "Failed to fetch Stock adjustments",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleSearch = (e) => {
    setSearchText(e?.target?.value);
  };
  useEffect(() => {
    handleFilter();
  }, [searchText, stockData]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },

    {
      title: "Entry No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Financial Year",
      dataIndex: "financialYear",
      render: (text) => text.name,
      sorter: (a, b) => a.financialYear - b.financialYear,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks?.localeCompare(b?.remarks),
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                 
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const detailedColumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },

    {
      title: "Entry No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Product",
      dataIndex: "product",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Batch",
      dataIndex: "batch",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Price",
      dataIndex: "price",
      sorter: (a, b) => a.no - b.no,
    },
  ];

  const MySwal = withReactContent(Swal);

  // Handle the click event to delete a Stock adjustments
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objStockTrn = new OpeningStockMaster();
          objStockTrn.id = obj?.id;
          objStockTrn.guid = obj?.guid;
          objStockTrn.branch = branch;
          objStockTrn.isDeleted = true;
          objStockTrn.updatedUser = `${userId?.id}`;
          const response = await deleteStock(objStockTrn);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The opening stock has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlestockData();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(
            "Error deleting reason:",
            error?.response || "something went wrong"
          );
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.title || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handleEditClick = async (guid) => {
    try {
      const response = getStockTrnByGuid(guid);

      setUpdateData(response);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching Stock adjustment by GUID",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  function getStockTrnByGuid(guid) {
    const filteredStock = stockList?.filter(
      (stockTrn) => stockTrn.guid === guid
    );

    return filteredStock[0];
  }
  // Function to format the date into day, month, and year components
  function formatDate(dateInput) {
    const dateObj = new Date(dateInput);

    const day = Number(dateObj.getDate());
    const month = Number(dateObj.getMonth() + 1);
    const year = Number(dateObj.getFullYear() % 100); // Last two digits of year

    return { day, month, year };
  }

  // Function to compare two dates (returns true if date1 >= date2)
  function compareDates(fromDate, toDate) {
    const {
      day: fromDay,
      month: fromMonth,
      year: fromYear,
    } = formatDate(fromDate);
    const { day: toDay, month: toMonth, year: toYear } = formatDate(toDate);

    if (fromYear > toYear) return true;
    if (fromYear === toYear && fromMonth > toMonth) return true;
    if (fromYear === toYear && fromMonth === toMonth && fromDay >= toDay)
      return true;

    return false;
  }

  function handleFilter() {
    const lowerCasedSearchText = searchText?.toLowerCase() || "";
    console.log(lowerCasedSearchText, "lowerCasedSearchText");

    const filterData = (data) => {
      return data.filter((entry) => {
        const fromDateMatch = !fromDate || compareDates(entry.date, fromDate);
        const toDateMatch = !toDate || compareDates(toDate, entry.date);

        const searchTextMatch = Object.entries(entry).some(([key, val]) => {
          // Log the key, value, and type of the current entry being checked
          console.log(`Key: ${key}, Value: ${val}, Type: ${typeof val}`);

          const stringMatch =
            typeof val === "string" &&
            val.toLowerCase().includes(lowerCasedSearchText);

          const numberMatch =
            (typeof val === "number" &&
              val.toString() === lowerCasedSearchText) ||
            (key === "entryNo" &&
              typeof val === "string" &&
              val === lowerCasedSearchText);

          return stringMatch || numberMatch;
        });

        return fromDateMatch && toDateMatch && searchTextMatch;
      });
    };

    const filteredStockList = filterData(stockData);
    setFilteredStockList(filteredStockList);

    const filteredDetData = filterData(detailedData);
    setFilDetailedData(filteredDetData);
  }

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div>
      <LoadingUtils loading={loading}/>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Opening Stock</h4>
                <h6>Manage Opening stock here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(getActiveTabData,"Opening stock")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link onClick={() => exportExcel(getActiveTabData,"Opening stock")}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link onClick={() => printReport(getActiveTabData,"Opening stock")}>
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={handleReset}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            {permit?.add && (
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  onClick={() => {
                    setModalMode("add"); // Set the modal mode to 'add'
                    setModalOpen(true); // Open the modal
                  }}
                >
                  <PlusCircle className="me-2" />
                  New
                </Link>
              </div>
            )}
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset btn-sm rounded-2">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters btn-sm rounded-2 h-100"
                      onClick={handleFilter}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body ms-4">
                <div className="row">
                  {/* From Date Filter */}
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>From Date</label>
                      <div className="input-groupicon ">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* To Date Filter */}
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon ">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Opening Stock" key="1">
                    <Table columns={columns} dataSource={filteredStockList} />
                  </TabPane>
                  <TabPane tab="Detailed Reports" key="2">
                    <Table
                      columns={detailedColumns}
                      dataSource={filDetailedData}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen && (
        <OpeningStockModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlestockData();
            setSearchText("");
          }}
        />
      )}
    </div>
  );
};

export default OpeningStock;
