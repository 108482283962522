import axios from "axios";

import { getBaseUrl } from "../config";

// const base_path = getBaseUrl();
const base_path = window.APP_CONFIG?.BASE_URL
// const base_path = process.env.REACT_APP_API_URL;

//---------opening stock apis-----------------

export const getStockByBranch = async (branch) => {
  console.log("branch", branch);
  console.log("inside getstockbtybranch");
  const response = await axios.get(
    `${base_path}/Api/OpeningStock/GetAllByBranchID?globalBranchID=${branch?.guid}`
  );
  console.log(response.data, "response.data");
  console.log(branch?.guid, "branch?.guid");

  return response.data;
};

// Create a new or edit opening stock
export const addEditStock = async (stockData) => {
  console.log(JSON.stringify(stockData), "---------stockData--");
  console.log(stockData, "---------stockData--");
  const response = await axios.post(
    `${base_path}/Api/OpeningStock/SaveOrUpdate`,
    JSON.stringify(stockData)
  );
  return response;
};

//delete opening stock
export const deleteStock = async (stockData) => {
  console.log(stockData, "xxxxxxx");
  const response = await axios.post(
    `${base_path}/Api/OpeningStock/Delete`,
    stockData
  );
  return response;
};
