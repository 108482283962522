import axios from "axios";

import { getBaseUrl } from "../config";

// const base_path = getBaseUrl();
const base_path = window.APP_CONFIG?.BASE_URL
// const base_path = process.env.REACT_APP_API_URL;

//Get sales invoice by transaction type and branch
export const getSalesByTransType = async (branch, transType) => {
  const response = axios.get(
    `${base_path}/Api/InvSale/GetAllByTransType?globalBranchID=${branch?.guid}&TransType=${transType}`
  );
  console.log("got resoponse", response.data);

  return response;
};

export const deleteSales = async (salesData) => {
  console.log(JSON.stringify(salesData));
  const response = await axios.post(
    `${base_path}/Api/InvSale/Delete`,
    JSON.stringify(salesData)
  );
  console.log("deleted resoponse", response.data);
  return response.data;
};
// Create a new sales
export const addEditSales = async (salesData) => {
  console.log(JSON.stringify(salesData), "---------salesData--");
  console.log(salesData, "---------salesData--");
  const response = await axios.post(
    `${base_path}/Api/InvSale/SaveOrUpdate`,
    JSON.stringify(salesData)
  );
  console.log("addEditSales", salesData);
  return response;
};

//Get sales invoice by branch
export const getSalesByBranch = async (branch) => {
  const response = axios.get(
    `${base_path}/Api/InvSale/GetAll?globalBranchID=${branch?.guid}`
  );
  return response;
};
//Get sales invoice by branch
