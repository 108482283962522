
import React, { useEffect, useRef, useState } from "react";
import { useForm,Controller, useFieldArray } from "react-hook-form";
import { editProduct} from "../../../services/MasterApiServices";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import EditProductProfile from "./Product/EditProductPrtofile";
import EditProductSection from "./Product/EditProductSection";
import EditOtherDescription from "./Product/EditProductOtherDiscription";
import { ProductClass } from "../../../core/json/ProductClass";
import toast from "react-hot-toast";
import { TaxClass } from "../../../core/json/TaxClass";
import { ComboProductDetail } from "../../../core/json/ComboProductDetail";
import { fetchProduct } from "../../../redux/userSetting";



const EditProduct = ({ data}) => {

  const { register,formState: { errors, isValid, isDirty }, handleSubmit, setValue, getValues,watch, control,reset ,trigger } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      items: [{ name: '', maxItems: '', comments: '' }],
    },
  });
  const dispatch= useDispatch();
  const { userId } =useSelector((state)=>state.userauth)
  const { sectionList,taxList,categoryList,deliveyTypes,productList,unitList, groupList  } = useSelector((state) => state.usersetting);
  const { branch } = useSelector((state) => state.branchlist);
  const comboType = deliveyTypes?.find(type => type.name === 'Combo'); // Find Combo type
  const COMBO_TYPE_ID = comboType?.guid; // Get Combo type GUID
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    resetmodal()
  }, [data,branch])
  function resetmodal() {
    setValue('profile',{
      name: data?.name,
      shortName:data?.shortName,
      code:data?.code,
      barCode:data?.barcode,
      cost:data?.cost,
      basePrice:data?.basePrice,
      otherDescription:data?.otherDescription,
      isActive:data?.isActive,
      category:data?.category?.guid,
      type:data?.type?.guid,
      group:data?.group?.guid,
      unit:data?.unit?.guid,
    })
   // Transform the existing data to a mapped array
const sectionwise = data?.sectionWiseProductDetailList?.map(e => ({
  IsActive: e.isActive,
  Section: e.section.guid,
  Price: e.price,
  Tax: e.tax.guid,
  IsTaxIncludedInPrice: e.isTaxIncludedInPrice,
})) || [];

// Find sections in `sectionList` not present in `sectionWiseProductDetailList`
const additionalSections = sectionList?.filter(e =>
  !data?.sectionWiseProductDetailList?.some(item => item.section.guid === e.guid)
)?.map(e => ({
  IsActive: false,
  Section: e.guid,
  Price: null,
  Tax: null,
  IsTaxIncludedInPrice: false,
})) || [];


// Combine both arrays
const combinedSections = [...sectionwise, ...additionalSections];

// Set the combined result in the form or state
setValue("sectionWiseProductDetailList", combinedSections);

    const comboProductDetail = data?.comboProductDetail?.map(e=>({
      sideChoiceNumber : e.sideChoiceNumber,
      name : e.product.guid,
      maxItems : e.quantity
    }))
    setValue("items",comboProductDetail)
    setValue("otherDescription",{
      arabicDescription: data?.arabicDescription,
      hsnCode: data?.hsnCode,
      isRateVaryProduct: data?.isRateVaryProduct,
      isDailyStockItem: data?.isDailyStockItem,
      isMenuItem: data?.isMenuItem,
    })
  }
 

   const handleUpdateProduct=async(formData)=>{
    const taxobj = new TaxClass()
    console.log(formData);
    const sectionWiseProductDetailList = getValues()?.sectionWiseProductDetailList?.map(e=>({
          Section: sectionList?.find((item) => item.guid == e.Section),
          IsActive: e.IsActive,
          IsTaxIncludedInPrice: e.IsTaxIncludedInPrice,
          Price: Number(e.Price),
          tax:e.Tax? taxList?.find((item) => item.guid == e.Tax): taxobj
            }))
    const objcombodetail = new ComboProductDetail();
    const ComboProductarray = watch().profile?.type === COMBO_TYPE_ID ? formData?.profile.items?.map((e,i) => ({
      sideChoiceNumber : i,
      products : productList?.filter(item =>e?.name?.some(pro => item.guid === pro.value)),
      quantity : e.maxItems
    })) : null
    const FixedItem = ComboProductarray?.[0] || null;  
    const SideChoices = ComboProductarray?.slice(1) || [];
    objcombodetail.FixedItem= FixedItem;
    objcombodetail.SideChoices= SideChoices;
    const objproduct = new ProductClass();
    objproduct.id = data.id;
    objproduct.guid = data.guid;
    objproduct.code = formData?.profile.code; 
     objproduct.name = formData?.profile.name;
     objproduct.shortName = formData?.profile.shortName; 
     objproduct.barCode = formData?.profile.barCode; 
     objproduct.category = categoryList?.find((item) => item.guid == formData?.profile.category);
     objproduct.type = deliveyTypes?.find((item) => item.guid == formData?.profile.type);
     objproduct.group = groupList?.find((item) => item.guid == formData?.profile.group);
     objproduct.unit = unitList?.find((item) => item.guid == formData?.profile.unit);
     objproduct.cost = parseFloat(formData?.profile.cost); 
     objproduct.basePrice = parseFloat(formData?.profile.basePrice);
     objproduct.otherDescription = formData?.profile.otherDescription;
     objproduct.isActive = formData?.profile.isActive;
     objproduct.sectionWiseProductDetailList = sectionWiseProductDetailList;
     objproduct.comboProductDetail = formData?.profile.type === COMBO_TYPE_ID ? objcombodetail :null;
     objproduct.arabicDescription = formData?.otherDescription.arabicDescription;
     objproduct.isRateVaryProduct = formData?.otherDescription.isRateVaryProduct;
     objproduct.isDailyStockItem =formData?.otherDescription.isDailyStockItem;
     objproduct.hsnCode =formData?.otherDescription.hsnCode;
     objproduct.branch=branch;
     objproduct.isMenuItem =formData?.otherDescription.isMenuItem;
     objproduct.updatedUser =  `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}"; //userId

   
   try {
   const response = await editProduct(objproduct);
     console.log(response);
     if (response==true) {
     toast.success("Success");
     closemodal()
     dispatch(fetchProduct(branch))
   }else if(response==false){
    toast.error("false")
   }
   } catch (error) {
    toast.error(error?.response?.data?.Message);
   console.error("Error handling :", error);
   }
   }
   const closeButtonRef = useRef();

   const closemodal = () => {
     if (closeButtonRef.current) {
       closeButtonRef.current.click();
     }
   };

  return (
    <div>

      <div className="modal fade" tabIndex="-1" id="edit-product">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Edit Product</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
               data-bs-dismiss="modal"
                        aria-label="Close"
                        ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <form onSubmit={handleSubmit(handleUpdateProduct)}>
  <div className="branch-tabs col-12 align-items-center justify-content-between">
    <Tabs
      selectedIndex={tabIndex}
      onSelect={async (index) => {
        const isValid = await trigger(); // Validate current tab fields
        if (isValid) {
          setTabIndex(index); // Allow tab switch if valid
        } else {
          alert("Please fix the errors in this tab before switching.");
        }
      }}
    >
      <TabList>
        <Tab>Profile</Tab>
        <Tab>Section Price</Tab>
        <Tab>Other Description</Tab>
      </TabList>
      <TabPanel>
        <EditProductProfile register={register} watch={watch} control={control} errors={errors} />
      </TabPanel>
      <TabPanel>
        <EditProductSection register={register} getValues={getValues} errors={errors} />
      </TabPanel>
      <TabPanel>
        <EditOtherDescription register={register} errors={errors} />
      </TabPanel>
    </Tabs>
  </div>
  <div className="col-lg-12">
    <div className="modal-footer-btn">
      <button
        type="button"
        className="btn btn-cancel me-2"
        onClick={() => {
          resetmodal();
          reset(); // Reset react-hook-form
          setTabIndex(0); // Reset to the first tab
        }}
      >
        Reset
      </button>
      <button
        type="submit"
        className="btn btn-submit"
        disabled={!!Object.keys(errors || {}).length} // Disable if there are errors
      >
        Update
      </button>
    </div>
  </div>
</form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;