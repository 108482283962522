import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
  Calendar,
  Layers,
  User,
  Smile,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import Table from "../../core/pagination/datatable";
import {
  deleteSales,
  getjournalentry,
  getSalesByTransType,
} from "../../services/SalesApiServices";
import SalesQuotationModal from "../../components/transactions/modal/SalesQuotationModal";
import { InvSales } from "../../core/json/invSales";
import { DatePicker, Tabs } from "antd";
import Select from "react-select";
import {
  fetchCustomer,
  fetchProduct,
  fetchSettings,
} from "../../redux/userSetting";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../core/utils/AccountingUtils";
import moment from "moment";
import Billreceiptmodal from "../../components/accounting/Billreceiptmodal";
import JournalEntryModal from "../../components/accounting/JournalEntryModal";
import { fetchledger } from "../../redux/ledger";
import { getJournal, deleteJournal } from "../../services/AccountApiServices";
import { Journalentry } from "../../core/json/JournalEntry";
import { FinancialYear } from "../../core/json/FinancialYear";
import { Company } from "../../core/json/Company";
import LoadingUtils from "../../core/utils/LoadingUtils";
import OpeningBalanceModal from "../../components/accounting/OpeningBalanceModal";
import {
  getOpeningBalance,
  saveOpeningBalance,
  deleteOpeningBalance,
} from "../../services/AccountApiServices";
const OpeningBalance = () => {
  const navigate = useNavigate();
  const { TabPane } = Tabs;
  const [loading, setloading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [journalEntry, setJournalEntry] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const { togglehead } = useSelector((state) => state.headerToggle);
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const [filteredData, setFilteredData] = useState([]);
  const [itemwiseData, setitemwiseData] = useState([]);
  const [filtereditemData, setFiltereditemData] = useState([]);
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const { formatedLedger } = useSelector((state) => state.ledger);
  const MySwal = withReactContent(Swal);
  const [selectedLedger, setSelectedLedger] = useState(null);

  const renderTooltip = (message) => (
    <Tooltip id="pdf-tooltip">{message}</Tooltip>
  );
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Journal")
    );
    dispatch(fetchledger());
  }, []);
  useEffect(() => {
    dispatch(fetchledger());
    dispatch(fetchSettings(branch));
    handleOpeningbal();
    // handleFilter();
  }, [branch]);

  function itemwiseDataFn(invoice) {
    return invoice.journalDetails?.map((item, index) => {
      // return invoice.ledgerBranchOpeningDetails?.map((item, index) => {
      return {
        key: index || `row-${Math.random()}`,
        date: invoice.date,
        no: invoice.no,
        remarks: invoice.remarks,
        ledgerID: item.ledgerID || "Unknown Ledger",
        debit: item.debit,
        credit: item.credit,
        narration: item.remarks,
        refNo: item.refNo,
      };
    });
  }
  const handleOpeningbal = async () => {
    setloading(true);
    try {
      const response = await getOpeningBalance(branch);
      const invoice = Array.isArray(response) ? response : [];
      const filterInvoiceByBranch = invoice.filter(
        (invoice) => invoice?.branch?.guid === branch?.guid
      );
      setJournalEntry(filterInvoiceByBranch);
      setFilteredData(filterInvoiceByBranch);
      const allItemwiseData = invoice.flatMap((invoice) =>
        itemwiseDataFn(invoice)
      );

      setitemwiseData(allItemwiseData);
      setFiltereditemData(allItemwiseData);
      setloading(false);
    } catch (error) {
      console.error(
        "Failed to fetch journal entry",
        error?.response?.data?.Message || "something went wrong"
      );
      setloading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    handleFilter();
  }, [searchText, journalEntry]);

  function handleFilter() {
    const filteredData = journalEntry?.filter((entry) => {
      const ledgerMatch =
        !selectedLedger ||
        entry?.ledgerBranchOpeningDetails?.some(
          (detail) => detail?.ledgerID === selectedLedger
        );
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);

      const searchTextMatch =
        dayjs(entry?.date)
          .format("YYYY-MM-DD")
          ?.includes(searchText?.toLowerCase()) ||
        entry?.remarks?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.no?.toString().includes(searchText?.toLowerCase());

      return ledgerMatch && fromDateMatch && toDateMatch && searchTextMatch;
    });

    setFilteredData(filteredData);
    handleFilteritem();
  }
  function handleFilteritem() {
    const filteredData = itemwiseData?.filter((entry) => {
      const ledgerMatch =
        !selectedLedger || entry.ledgerID === selectedLedger.value;
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);

      const searchTextMatch =
        dayjs(entry?.date)
          .format("YYYY-MM-DD")
          ?.includes(searchText?.toLowerCase()) ||
        entry?.credit?.toString().includes(searchText?.toLowerCase()) ||
        entry?.debit?.toString().includes(searchText?.toLowerCase()) ||
        formatedLedger
          .find((ledger) => ledger.value === entry?.ledgerID)
          ?.label?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        entry?.no?.toString().includes(searchText?.toLowerCase());

      return ledgerMatch && fromDateMatch && toDateMatch && searchTextMatch;
    });

    setFiltereditemData(filteredData);
  }
  const handleEditClick = async (guid) => {
    try {
      const response = journalEntry?.find((sales) => sales.guid === guid);
      setUpdateData(response);

      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching sales invoice by GUID",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleDeleteClick = (obj) => {
    const objpayment = new Journalentry();
    objpayment.Branch = branch;
    objpayment.guid = obj.guid;
    objpayment.id = obj.id;
    objpayment.updatedUser = userId?.id;
    objpayment.createdUser = userId?.id;
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteJournal(objpayment);
          if (response.data == true) {
            MySwal.fire({
              title: "Deleted!",
              text: "The journal has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handleOpeningbal();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting sales quotation:", error);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handleTabChange = (key) => {
    console.log("key", key);

    setActiveTab(key);
    if (key == 3) {
      setIsFilterVisible(true);
    } else {
      setIsFilterVisible(false);
    }
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create a column mapping (column title to dataIndex)
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);
        console.log("fieldMapping of first table:", fieldMapping);
        return { data: filteredData, columns: columns, fieldMapping };
      case "2":
        fieldMapping = createfieldMapping(Reportcolumns);
        console.log("fieldMapping of second table:", fieldMapping);
        return { data: filteredData, columns: Reportcolumns, fieldMapping };
      case "3":
        fieldMapping = createfieldMapping(Itemwisecolumns);
        console.log("fieldMapping of fourth table:", fieldMapping);
        return {
          data: filtereditemData,
          columns: Itemwisecolumns,
          fieldMapping,
        };
      default:
        return { data: [], columns: [] };
    }
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Entry#",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },

    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const Reportcolumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Bill No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },
  ];

  const Itemwisecolumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Bill No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Ledger",
      dataIndex: "ledgerID",
      render: (record) => formatedLedger?.find((e) => e.value == record)?.label,
      sorter: (a, b) => a.ledgerID.localeCompare(b.ledgerID),
    },
    {
      title: "Debit",
      dataIndex: "debit",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },
    {
      title: "Ref No.",
      dataIndex: "refNo",
      sorter: (a, b) => a.refNo.localeCompare(b.refNo),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks),
    },
  ];

  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSelectedLedger(null);
    setSearchText("");
    handleOpeningbal();
  }

  const handleLedgerChange = (selectedOption) => {
    setSelectedLedger(selectedOption);
  };
  return (
    <div>
      <LoadingUtils loading={loading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Opening Balance</h4>
                <h6>Manage opening balance here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Pdf")}
                  >
                    <Link
                      onClick={() =>
                        exportPDF(
                          getActiveTabData,
                          formatedLedger,
                          "Opening Stock"
                        )
                      }
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/pdf.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportExcel(
                        getActiveTabData,
                        formatedLedger,
                        "Opening Stock"
                      )
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      printReport(
                        getActiveTabData,
                        formatedLedger,
                        "Opening Stock"
                      )
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link onClick={() => handleOpeningbal()}>
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>

            {/* {permit?.add && ( */}
            <div className="page-btn">
              <Link
                to="#"
                className="btn btn-added"
                onClick={() => {
                  setModalMode("add"); // Set the modal mode to 'add'
                  setModalOpen(true); // Open the modal
                }}
              >
                <PlusCircle className="me-2" />
                New
              </Link>
            </div>
            {/* )} */}
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {/* {isFilterVisible && ( */}
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters rounded-2 h-100"
                      onClick={handleFilter}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>
                  {/* )} */}

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks ms-4">
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          selected={fromDate}
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  {isFilterVisible && (
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
                        <label>Ledger</label>
                        <div className="input-groupicon calender-input">
                          <Layers className="info-img" />
                          <Select
                            className="img-select"
                            options={formatedLedger}
                            value={selectedLedger}
                            onChange={handleLedgerChange}
                            classNamePrefix="react-select"
                            placeholder="Ledger"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* </div> */}
              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Opening Balance" key="1">
                    <Table columns={columns} dataSource={filteredData} />
                  </TabPane>
                  <TabPane tab="Summary Report" key="2">
                    <Table columns={Reportcolumns} dataSource={filteredData} />
                  </TabPane>
                  <TabPane tab="Detailed Reports" key="3">
                    <Table
                      columns={Itemwisecolumns}
                      dataSource={filtereditemData}
                    />
                    {/* <SalesSupplier
                      fromDate={fromDate?.$d}
                      toDate={toDate?.$d}
                      data={filteredData}
                      supplierColumns={supplierColumns}
                      /> */}
                  </TabPane>
                  {/* <TabPane tab="ItemWise" key="4">
                    <Table columns={columns} dataSource={filteredData} />
                  </TabPane> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Reason Modal */}
      {modalOpen && (
        <OpeningBalanceModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handleOpeningbal();
            setSearchText("");
          }}
        />
      )}
    </div>
  );
};

export default OpeningBalance;
