import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  PlusCircle,
  ChevronUp,
  Send,
} from "feather-icons-react/build/IconComponents";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { Pagination, Select } from "antd";
import Selectbranch from "../../components/master/modal/Selectbranch";
import {
  deleteProduct,
  getProductList,
} from "../../services/MasterApiServices";
import { ProductClass } from "../../core/json/ProductClass";
import {
  fetchCategory,
  fetchGroup,
  fetchProduct,
  fetchSection,
  fetchTax,
  fetchType,
  fetchUnit,
} from "../../redux/userSetting";
import AddProduct from "../../components/master/modal/AddProduct";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import EditProduct from "../../components/master/modal/Editproduct";
import {
  exportPDF,
  exportExcel,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import ProductBranchTransfer from "../../components/master/modal/Product/ProductBranchTransfer";
import LoadingUtils from "../../core/utils/LoadingUtils";
import {
  exportMasterExcel,
  exportMasterPDF,
  printMasterReport,
} from "../../core/utils/MasterReportUtils";
import { fetchBranchSettings } from "../../redux/settingbyBranch";
import toast from "react-hot-toast";

const Product = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [updateData, setUpdateData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setselectedType] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { branch } = useSelector((state) => state.branchlist);
  const { productList, productloading } = useSelector(
    (state) => state.usersetting
  );
  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);
  const { menuItems, specialpermission } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const [branchpermit, setbranchpermit] = useState();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const { formattedTypeList, formattedCategoryList } = useSelector(
    (state) => state.usersetting
  );
  const { sectionList, taxList, deliveyTypes, unitList, groupList } =
    useSelector((state) => state.usersetting);

  useEffect(() => {
    dispatch(fetchCategory(branch.guid));
    dispatch(fetchSection(branch.guid));
    dispatch(fetchGroup(branch.guid));
    dispatch(fetchProduct(branch));
    dispatch(fetchBranchSettings(branch.guid));
  }, [branch]);

  useEffect(() => {
    dispatch(fetchType());
    dispatch(fetchUnit());
    dispatch(fetchTax());
    setbranchpermit(
      specialpermission?.userSpecialPermissionList?.find(
        (e) => e.specialPermission.name == "Product Branch Transfer"
      )
    );
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Product")
    );
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  function handleAddProduct() {
    if (formattedTypeList.length <= 0) {
      toast.error("Please enter type before adding product");
      return;
    }
    if (formattedCategoryList.length <= 0) {
      toast.error("Please enter categroy before adding product");
      return;
    }
    if (unitList.length <= 0) {
      toast.error("Please enter unit before adding product");
      return;
    }
    if (groupList.length <= 0) {
      toast.error("Please enter group before adding product");
      return;
    }
    if (sectionList.length <= 0) {
      toast.error("Please enter section before adding product");
      return;
    } else {
      setModalMode("add");
      setModalOpen(true);
    }
  }
  function handleReset() {
    setselectedType(null);
    setSelectedCategory(null);
    setSearchText("");
  }
  const modifiedList = productList?.filter((product) => {
    const searchMatch =
      product.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
      product.code?.toLowerCase().includes(searchText?.toLowerCase());
    const categoryMatch = selectedCategory
      ? product?.category?.guid === selectedCategory
      : true;
    const itemMatch = selectedType
      ? product?.type?.guid === selectedType
      : true;
    console.log("selectedType", selectedType);
    console.log("selectedType?.value", selectedType?.value);
    console.log("product?.type?.guid", product?.type?.guid);
    return searchMatch && categoryMatch && itemMatch;
  });
  const handleCategoryChange = (selectedOption) => {
    console.log("Selected category:", selectedOption);
    setSelectedCategory(selectedOption);
  };

  const handleTypeChange = (selectedOption) => {
    console.log("Selected type:", selectedOption);
    setselectedType(selectedOption);
  };
  const handleEditClick = async (guid) => {
    try {
      const SingleGroup = getProductGuid(guid);
      setUpdateData(SingleGroup); // Set the data for editing
    } catch (error) {
      console.error("Error fetching promotion GUID:", error);
    }
  };
  // Function to get a single object from filtering a list of group objects
  function getProductGuid(guid) {
    const filteredData = productList?.filter((group) => group.guid === guid);
    return filteredData[0];
  }
  // Initialize SweetAlert with ReactContent

  const MySwal = withReactContent(Swal);
  // Handle the click event to delete a group
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objproduct = new ProductClass();
          objproduct.id = obj.id;
          objproduct.branch = branch;
          objproduct.guid = obj.guid;
          objproduct.updatedUser = `{${userId?.id}}`; //"{9b57619d-63c8-4e27-9a59-639f0c80e4a1}";
          const response = await deleteProduct(objproduct);
          console.log(objproduct);

          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The product has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              dispatch(fetchProduct(branch)); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting group:", error);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
    },
    {
      title: "Description",
      dataIndex: "otherDescription",
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-product"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };

    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);

    // Return the filtered data, columns, and field mapping
    return { data: modifiedList, columns: columns, fieldMapping };
  };
  return (
    <div>
      <LoadingUtils loading={productloading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Product</h4>
                <h6>Manage Product here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() => exportMasterPDF(getActiveTabData, "Product")}
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportMasterExcel(getActiveTabData, "Product")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      printMasterReport(getActiveTabData, "Product")
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => dispatch(fetchProduct(branch))}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <ul className="table-top-head"></ul>

            <div className="d-flex purchase-pg-btn">
              {branchpermit?.userRole.id !== 0 && (
                <div className="page-btn">
                  <Link
                    to="#"
                    className="btn btn-added"
                    data-bs-target="#product-branch-trans"
                    data-bs-toggle="modal"
                  >
                    <Send className="me-2" />
                    Branch Transfer
                  </Link>
                </div>
              )}
              <div className="d-flex purchase-pg-btn">
                {permit?.add && (
                  <div className="page-btn">
                    <Link
                      to="#"
                      className="btn btn-added"
                      onClick={handleAddProduct}
                      // onClick={() => {
                      //   setModalMode("add"); // Set the modal mode to 'add'
                      //   setModalOpen(true); // Open the modal
                      // }}
                    >
                      <PlusCircle className="me-2" />
                      New
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div
                className="row gx-2 gy-2 align-items-center"
                style={{ padding: "1rem" }}
              >
                {/* Search Input and Time Pickers */}
                <div className="col-lg-3 col-md-6 col-12 ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm me-2 formsearch "
                    aria-controls="DataTables_Table_0"
                    value={searchText}
                    onChange={handleSearch}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-12 d-flex ">
                  <Select
                    className="img-select me-2"
                    options={formattedTypeList}
                    value={selectedType}
                    onChange={handleTypeChange}
                    classNamePrefix="react-select"
                    placeholder="Select Type"
                    style={{ height: "38px", width: "100%" }}
                  />
                  {/* </div>
                  <div className="col-lg-2 col-md-6 col-12"> */}
                  <Select
                    className="img-select"
                    options={formattedCategoryList}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    classNamePrefix="react-select"
                    placeholder="Select Category"
                    style={{ height: "38px", width: "100%" }}
                  />
                </div>

                {/* Search and Reset Buttons Positioned on the Right */}
                <div className="col-lg-4 col-md-6 col-12  d-flex justify-content-end">
                  <button
                    className="btn btn-reset rounded-2"
                    onClick={handleReset}
                  >
                    <i
                      data-feather="refresh-cw"
                      className="feather-refresh-cw"
                    />{" "}
                    Reset
                  </button>
                </div>
              </div>
              {/* </div> */}
            </div>
            {/* /product list */}
          </div>
          <div className="table-responsive product-list">
            <Table columns={columns} dataSource={modifiedList} />
          </div>
        </div>
        <Pagination />
        {updateData && <EditProduct data={updateData} />}
        <Selectbranch />
        {modalOpen && <AddProduct handleClose={() => setModalOpen(false)} />}
        <ProductBranchTransfer />
        {/* <AddProduct handleClose={() => setModalOpen(false)} /> */}
      </div>
    </div>
  );
};

export default Product;
