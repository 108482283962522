import { DomainBase } from "./DomainBase";

export class LedgerBranchOpeningMaster extends DomainBase {
  constructor() {
    super();
    this.date = null;
    this.branch = null;
    this.remarks = "";
    this.financialYear = null;
    this.company = null;
    this.no = 0;
    this.ledgerBranchOpeningDetails = [];
  }
}
