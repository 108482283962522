import { DomainBase } from "./DomainBase";

export  class ProductImportEntry extends DomainBase {
    /**
     * list of Products for Import
     */
    constructor() {
        super();
        this.ProductImportItems = [];
        this.Branch = null;
    }
}