import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";

function AddProductSection({ register, getValues, watch, setValue ,errors}) {
  const { sectionList, taxList } = useSelector((state) => state.usersetting);
  const { branchsettings } = useSelector((state) => state.settings);
  const columns = [
    {
      title: "Is Active",
      dataIndex: "",
      render: (_, value, index) => (
        <input
          type="checkbox"
          className=""
          {...register(`sectionWiseProductDetailList[${index}].IsActive`)}
        />
      ),
    },
    {
      title: "Section",
      dataIndex: "name",
      render: (_, value, index) => (
        <div>
          <select
            name=""
            id=""
            className="form-control"
            {...register(`sectionWiseProductDetailList[${index}].Section`)}
          >
            <option value={value.guid} key={value.id}>
              {value.name}
            </option>
          </select>
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "",
      render: (_, val, index) => (
        <div>
          <input
            type="number"
            className="form-control w-50"
            step="any"
            min={0}
            {...register(`sectionWiseProductDetailList[${index}].Price`)}
          />
        </div>
      ),
    },
   
    ...(branchsettings.disableTaxInSale == false
      ? [
        {
          title: "Tax",
          dataIndex: "",
    
          render: (_, value, index) => (
            <div>
              <select
                name=""
                id=""
                className="form-control"
                {...register(`sectionWiseProductDetailList[${index}].Tax`)}
                value={watch().sectionWiseProductDetailList[index].Tax}
                onChange={(e) =>
                  setValue(
                    `sectionWiseProductDetailList[${index}].Tax`,
                    e.target.value
                  )
                }
              >
                <option value=""> select</option>
                {taxList &&
                  taxList?.map((obj) => {
                    return (
                      <option value={obj.guid} key={obj.id}>
                        {obj.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          ),
        },
        {
          title: "Tax Inclusive",
          dataIndex: "",
          render: (_, value, index) => {
            const taxSelected = watch(`sectionWiseProductDetailList[${index}].Tax`);
            return (
              <input
                type="checkbox"
                className=""
                {...register(
                  `sectionWiseProductDetailList[${index}].IsTaxIncludedInPrice`
                )}
                disabled={!taxSelected} // Disable if no tax is selected
              />
            );
          },
        },
      ]
      : [])
  ];

  return (
    <div>
         {
  Object.keys(errors || {}).map((key) => errors[key]?.message).find(Boolean) && (
    <span className="error text-danger text-sm">
      {Object.keys(errors || {}).map((key) => errors[key]?.message).find(Boolean)}
    </span>
  )
}
      <div className="table-responsive product-list">
        <Table columns={columns} dataSource={sectionList} pagination={false} />
      </div>
    </div>
  );
}

export default AddProductSection;
