import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ReasonClass } from "../../../core/json/Reason";
import { addReason, editReason } from "../../../services/MasterApiServices";
import {
  getReasonTypeList,
  getReasonTypeguid,
} from "../../../services/MasterApiServices";
import { useSelector } from "react-redux";

const ReasonModal = ({ mode, data, handleClose, handleRefresh }) => {
  const [type, setType] = useState([]);

  // Get user from redux
  const { userId } = useSelector((state) => state.userauth);

  // Fetch types on component mount
  const fetchtype = async () => {
    try {
      const response = await getReasonTypeList();
      setType(response);
    } catch (error) {
      console.error("Error fetching types:", error);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("reason", data.reason);
      setValue("shortReason", data.shortReason);
      setValue("type", data.type.guid);
      console.log(data.type, "TYPE VALUE");
    } else {
      reset();
    }
    fetchtype();
  }

  useEffect(() => {
    resetmodal();
  }, [data, mode, setValue]);

  // Handle form submission for adding or editing a reason
  const handleFormSubmit = async (formData) => {
    const objReason = new ReasonClass();
    objReason.reason = formData.reason;
    objReason.shortReason = formData.shortReason;
    const response = await getReasonTypeguid(formData.type);
    console.log(response.data, "getReasontypeObj");
    objReason.type = response.data;
    objReason.updatedUser = `{${userId?.id}}`;

    if (mode === "edit") {
      objReason.guid = data.guid;
      objReason.id = data.id;
    }

    try {
      const response =
        mode === "edit"
          ? await editReason(objReason)
          : await addReason(objReason);
      console.log(response);

      if (response.data == true) {
        toast.success("Success");
        handleRefresh(); // Refresh the list
        handleClose(); // Close the modal
      } else if (response.data == false) {
        toast.error("false");
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling category:", error?.response?.data?.Message);
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{mode === "edit" ? "Edit Reason" : "Add Reason"}</h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Reason<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Reason"
                            {...register("reason")}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Short Reason<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Short Reason"
                            {...register("shortReason")}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>
                            Type<span className="text-danger">*</span>
                          </label>
                          <select
                            {...register("type", {
                              required: "type is required", // Validation rule with a custom message
                            })}
                            value={watch("type") || data?.type?.guid || ""}
                            onChange={(e) => setValue("type", e.target.value)}
                            className={`form-control ${
                              errors.type ? "is-invalid" : ""
                            }`} // Add Bootstrap invalid class for error styling
                          >
                            <option value="" disabled>
                              Select type
                            </option>
                            {type &&
                              type?.map((eachType) => (
                                <option
                                  key={eachType.guid}
                                  value={eachType.guid}
                                >
                                  {eachType.name}
                                </option>
                              ))}
                          </select>

                          {errors.type && (
                            <div className="invalid-feedback">
                              {errors.type.message} {/* Error message */}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="modal-footer-btn">
                          <button
                            type="button"
                            className="btn btn-cancel me-2"
                            onClick={resetmodal}
                          >
                            Reset
                          </button>
                          <button type="submit" className="btn btn-submit">
                            {mode === "edit" ? "Save Changes" : "Save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReasonModal;
