import Select from "react-select";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { savesettings } from "../../services/AdminApiServices";
import toast from "react-hot-toast";
import { getcashorbank } from "../../services/AccountApiServices";
import { fetchCurrency } from "../../redux/userSetting";
import { useDispatch } from "react-redux";
const SettingsGeneral = () => {
  const { register, handleSubmit, setValue, watch } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { branchsettings } = useSelector((state) => state.settings);
  const { branch } = useSelector((state) => state.branchlist);
  const { userRoles, deliveyTypes, stateList, sectionList, currencyList,financialyear } =
    useSelector((state) => state.usersetting);
  const [bankorcash, setbankorcash] = useState([]);
  const dispatch = useDispatch();
  const [selectedledger, setSelectedledger] = useState(null);
  useEffect(() => {
    dispatch(fetchCurrency(branch));
    handlegetCashorbank();
  }, []);

  useEffect(() => {
    setValue("general", {
      timeToClearPoleDisplay: branchsettings?.timeToClearPoleDisplay,
      timeToRefreshPayScreen: branchsettings?.timeToRefreshPayScreen,
      totalAmountForDiscount: branchsettings?.totalAmountForDiscount,
      enableBillSearchByTable: branchsettings?.enableBillSearchByTable,
      tableAlignmentRowNumber: branchsettings?.tableAlignmentRowNumber,
      tableAlignmentColumnNumber: branchsettings?.tableAlignmentColumnNumber,
      showSaleAmountInPoleDisplay: branchsettings?.showSaleAmountInPoleDisplay,
      enableLoyalty: branchsettings?.enableLoyalty,
      enableChairInSale: branchsettings?.enableChairInSale,
      orderProductByReferenceCode: branchsettings?.orderProductByReferenceCode,
      showChangeSectionButtonInPay:
        branchsettings?.showChangeSectionButtonInPay,
      showSplitButtonInPay: branchsettings?.showSplitButtonInPay,
      showMergeButtonInPay: branchsettings?.showMergeButtonInPay,
      showPaxPopupInSale: branchsettings?.showPaxPopupInSale,
      numberOfKOTPrints: branchsettings?.numberOfKOTPrints,
      applicationBackgroundImageNumber:
        branchsettings?.applicationBackgroundImageNumber,
      changeApplicationDesign: branchsettings?.changeApplicationDesign,
      hideModifierDetailsInKOTPrint:
        branchsettings?.hideModifierDetailsInKOTPrint,
      enableDataAutoSyncWithServer:
        branchsettings?.enableDataAutoSyncWithServer,
      enableVirtualKeyboard: branchsettings?.enableVirtualKeyboard,
      enableCess: branchsettings?.enableCess,
      enableCounterSection: branchsettings?.enableCounterSection,
      isStockProduct: branchsettings?.isStockProduct,
      showUnitPriceOfProductInSale:
        branchsettings?.showUnitPriceOfProductInSale,
      enablePrintInKitchenManager: branchsettings?.enablePrintInKitchenManager,
      kitchenManagerPrinter: branchsettings?.kitchenManagerPrinter,
      disableMultipleOrderInTable: branchsettings?.disableMultipleOrderInTable,
      viewSalesOrderFromTable: branchsettings?.viewSalesOrderFromTable,
      showCategoryAsCoursewiseInKOT:
        branchsettings?.showCategoryAsCoursewiseInKOT,
      enableWaiterwiseLogin: branchsettings?.enableWaiterwiseLogin,
      generateQrCodeInBillPrint: branchsettings?.generateQrCodeInBillPrint,
      sendMailWhileDayClose: branchsettings?.sendMailWhileDayClose,
      emailToSendDailyCloseReport: branchsettings?.emailToSendDailyCloseReport,
      arrangePhoneNumberAboveNameInCustomerMenu:
        branchsettings?.arrangePhoneNumberAboveNameInCustomerMenu,
      disableTaxEditInCreditBill: branchsettings?.disableTaxEditInCreditBill,
      currencyFormat: branchsettings?.currencyFormat,
      dateFormat: branchsettings?.dateFormat,
      defaultUserType: branchsettings?.defaultUserType,
      enableGeneralKeypad: branchsettings?.enableGeneralKeypad,
      generateSaleBillPrint: branchsettings?.generateSaleBillPrint,
      numberOfDaysExtendForBillingAfterDailyClose:
        branchsettings?.numberOfDaysExtendForBillingAfterDailyClose,
      numberOfDaysForEditBill: branchsettings?.numberOfDaysForEditBill,
      generateSalesInvoiceInDotMatrixPrinter:
        branchsettings?.generateSalesInvoiceInDotMatrixPrinter,
      hideItemwiseSummaryInDailyClose:
        branchsettings?.hideItemwiseSummaryInDailyClose,
      hideSectionSummaryInDailyClose:
        branchsettings?.hideSectionSummaryInDailyClose,
      showDateInDailyClose: branchsettings?.showDateInDailyClose,
      billNumberSeriesType: branchsettings?.billNumberSeriesType,
      enableArabicMenuDescription: branchsettings?.enableArabicMenuDescription,
      defaultState: branchsettings?.defaultState,
      enableProductNameBilling: branchsettings?.enableProductNameBilling,
      generateKOTOnCashInsale: branchsettings?.generateKOTOnCashInsale,
      showAllProductsInKOTPrint: branchsettings?.showAllProductsInKOTPrint,
      generateKOTPrint: branchsettings?.generateKOTPrint,
      showCancelReasonPopupInPay: branchsettings?.showCancelReasonPopupInPay,
      disableTaxInSale: branchsettings?.disableTaxInSale,
      disableLastSaleBillPrintOnEnter:
        branchsettings?.disableLastSaleBillPrintOnEnter,
      hideInvoiceRePrintMenu: branchsettings?.hideInvoiceRePrintMenu,
      showWaiterPopupOnSaveSale: branchsettings?.showWaiterPopupOnSaveSale,
      enableContinuousBillNumberInSale:
        branchsettings?.enableContinuousBillNumberInSale,
      enableVAT: branchsettings?.enableVAT,
      generateDailyClosePrint: branchsettings?.generateDailyClosePrint,
      showCreditButtonInSales: branchsettings?.showCreditButtonInSales,
      showTaxButtonInSaleForSpecialCustomer:
        branchsettings?.showTaxButtonInSaleForSpecialCustomer,
      changeProductDetailsWhileBilling:
        branchsettings?.changeProductDetailsWhileBilling,
      enableTokenNumber: branchsettings?.enableTokenNumber,
      enableSectionWiseTokenNumber:
        branchsettings?.enableSectionWiseTokenNumber,
      enableOtherLanguage: branchsettings?.enableOtherLanguage,
      showSaleBillInAllCounters: branchsettings?.showSaleBillInAllCounters,
      showSaleBillInAllCounterAndSections:
        branchsettings?.showSaleBillInAllCounterAndSections,
      showSaleBillInAllSections: branchsettings?.showSaleBillInAllSections,
      deliverySection: branchsettings?.deliverySection,
      showTablePopupInSection: branchsettings?.showTablePopupInSection,
      enableDepartmentInSection: branchsettings?.enableDepartmentInSection,
      disableSalePrintInPayScreen: branchsettings?.disableSalePrintInPayScreen,
      enableShiftClose: branchsettings?.enableShiftClose,
      generatePrintInCashButton: branchsettings?.generatePrintInCashButton,
      enableDailyCloseSummaryPrint:
        branchsettings?.enableDailyCloseSummaryPrint,
      showWaiterPopupInSection: branchsettings?.showWaiterPopupInSection,
      showCustomerPopupInSection: branchsettings?.showCustomerPopupInSection,
      dailyClosePrintFormat: branchsettings?.dailyClosePrintFormat,
      showFieldToEnterArabicDescriptionInProduct:
        branchsettings?.showFieldToEnterArabicDescriptionInProduct,
      roundOffInSale: branchsettings?.roundOffInSale,
      comboType: branchsettings?.comboType,
      showKeyBoardForEnterQuantityManually:
        branchsettings?.showKeyBoardForEnterQuantityManually,
      ledDisplayPort: branchsettings?.ledDisplayPort,
      enablePoleDisplayAutoClear: branchsettings?.enablePoleDisplayAutoClear,
      primaryCurrencyGUID: branchsettings?.primaryCurrencyGUID,
      defaultFinancialYearGuID: branchsettings?.defaultFinancialYearGuID,
    });
  }, [branchsettings, branch]);
  const handlegetCashorbank = async () => {
    try {
      const response = await getcashorbank();
      const res = response.data || [];
      // const data =
      //   res
      //     ?.filter((item) => item.name === "DEFAULT BANK ACCOUNT")
      //     ?.map((item) => item.groupID.name) || [];
      const filteredData = res.filter((item) => {
        console.log("Checking item:", item);
        return item.name === "DEFAULT BANK ACCOUNT";
      });
      console.log("Filtered Data:", filteredData);

      const data =
        filteredData.map((item) => {
          console.log("Mapping item:", item);
          return { name: item.groupID.name, guid: item.groupID.guid };
        }) || [];

      console.log("Final Data:", data);

      if (Array.isArray(data)) {
        
        setbankorcash(data);
      } else {
        setbankorcash([]);
      }
    } catch (error) {
      setbankorcash([]);
    }
  };
  const closeButtonRef = useRef();

  const closemodal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  const handleGeneralSettings = async (data) => {
    try {
      const response = await savesettings({
        setting: data.general,
        branch: branch,
      });
      if (response == true) {
        toast.success("success");
        closemodal();
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const disableTaxInSale = watch("general.disableTaxInSale");

  // Automatically uncheck the second checkbox when `disableTaxInSale` is true
  useEffect(() => {
    if (disableTaxInSale) {
      setValue("general.showTaxButtonInSaleForSpecialCustomer", false);
      setValue("general.enableCess", false);
    }
  }, [disableTaxInSale, setValue]);
  return (
    <div className="modal-body custom-modal-body">
      <form onSubmit={handleSubmit(handleGeneralSettings)}>
        <div className="form_body">
          <div className="row">
            <label>
              <b>Formatting: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Date format</label>

                {/* <Select
                                options={customers}
                                classNamePrefix="react-select"
                                placeholder="Choose"
                              /> */}
                <select
                  name=""
                  id=""
                  className="form-control"
                  {...register("general.dateFormat")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="dd/MM/yyyy">DD/MM/YYYY</option>
                  <option value="MM/dd/yyyy">MM/DD/YYYY</option>
                  <option value="yyyy/MM/dd">YYYY/MM/DD</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Currency format</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  {...register("general.currencyFormat")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="0">0</option>
                  <option value="0.00">0.00</option>
                  <option value="0.000">0.000</option>
                </select>
              </div>
            </div>
            <hr />
          </div>
          <div className="row">
            <label>
              <b>Maximum number of days: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Extended for billing after day close</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  {...register(
                    "general.numberOfDaysExtendForBillingAfterDailyClose"
                  )}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>For Edit Daily Closed Bills</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  {...register("general.numberOfDaysForEditBill")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
            <hr />
          </div>
          <div className="row">
            <label>
              <b>Default Values: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Bank Account</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  {...register("general.defaultBankGuid")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {bankorcash?.map((e) => {
                    return (
                      <option value={e.guid} key={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
          
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>State</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  {...register("general.defaultState")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {stateList?.map((e) => {
                    return (
                      <option value={e.guid} key={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="input-blocks add-product">
                <label>Currency</label>

                <select
                  className="form-control"
                  {...register("general.primaryCurrencyGUID")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {currencyList?.map((e) => {
                    return (
                      <option value={e.guid} key={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="input-blocks add-product">
                <label>Financial Year</label>

                <select
                  className="form-control"
                  {...register("general.defaultFinancialYearGuID")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {financialyear?.map((e) => {
                    return (
                      <option value={e.guid} key={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>User Type</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  {...register("general.defaultUserType")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {userRoles?.map((e) => {
                    return (
                      <option value={e.id} key={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Delivery Section</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  {...register("general.deliverySection")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {sectionList?.map((e) => {
                    return (
                      <option value={e.guid} key={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Combo Type</label>

                <select
                  name=""
                  id=""
                  className="form-control"
                  {...register("general.comboType")}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {deliveyTypes?.map((e) => {
                    return (
                      <option value={e.guid} key={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <hr />
          </div>

          <div className="row">
            <label>
              <b>Arabic menu description: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input
                  type="checkbox"
                  {...register("general.enableArabicMenuDescription")}
                />
                <label className="m-3">Enable arabic menu description</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              Normally menus in the application appears in english. When this
              setting enabled, we can see menu names in arabic
            </div>
            <hr />
          </div>
          <div className="row">
            <label>
              <b>Invoice reprint menu: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input
                  type="checkbox"
                  {...register("general.hideInvoiceRePrintMenu")}
                />
                <label className="m-3">Hide invoice reprint menu</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              When this setting set true it will hide invoice reprint menu from
              the application{" "}
            </div>

            <hr />
          </div>
          <div className="row">
            <label>
              <b>Shift: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input
                  type="checkbox"
                  {...register("general.enableShiftClose")}
                />
                <label className="m-3">Enable shift</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              When this setting enabled, a box to enter shift number will show
              in sale summary report. Using this shift number can sort the
              summary report{" "}
            </div>
            <hr />
          </div>
          <div className="row">
            <label>
              <b>Waiter wise login: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input
                  type="checkbox"
                  {...register("general.enableWaiterwiseLogin")}
                />
                <label className="m-3">Enable waiter wise login</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              To login the application using waiter wise credentials{" "}
            </div>
            <hr />
          </div>
          <div className="row">
                        <label><b>Tax: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.disableTaxInSale")} />
                                <label className='m-3'>Disable tax </label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        When this setting set true, tax related details will not show </div>
                        {/* <label><b>Tax Button: </b> </label> */}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showTaxButtonInSaleForSpecialCustomer")} 
                                disabled={disableTaxInSale}/>
                                <label className='m-3'>Show tax button in sale For special customer</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        To show tax button in sale screen for special customers</div>
                       
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.enableCess")} 
                                disabled={disableTaxInSale}/>
                                <label className='m-3'>Enable cess</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                        Enabling this setting will show cess in billing</div>
                        <hr />
                    </div>
          <div className="row">
            <label>
              <b>VAT: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input type="checkbox" {...register("general.enableVAT")} />
                <label className="m-3">Enable VAT</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              To enable VAT tax instead of GST in the application{" "}
            </div>
            <hr />
          </div>
          <div className="row">
            <label>
              <b>Keyboard: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input
                  type="checkbox"
                  {...register("general.showKeyBoardForEnterQuantityManually")}
                />
                <label className="m-3">
                  Show keyboard for enter quantity manually
                </label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              When this setting enabled a keyboard will popup to enter quantty
              of product{" "}
            </div>
            <hr />
          </div>
          <div className="row">
            <label>
              <b>Enable virtual keyboard: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input
                  type="checkbox"
                  {...register("general.enableVirtualKeyboard")}
                />
                <label className="m-3">Enable enable virtual keyboard</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              Enabling this setting will show virtual keyboard{" "}
            </div>
            <hr />
          </div>
          <div className="row">
            <label>
              <b>Auto synchronization: </b>{" "}
            </label>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex">
                <input
                  type="checkbox"
                  {...register("general.enableDataAutoSyncWithServer")}
                />
                <label className="m-3">Enable data auto sync with server</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
              Enabling this setting will always synchronize local application
              data with server
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Table Alignment ColumnNumber</label>

                <input
                  type="number"
                  placeholder="Number of columns to set table position"
                  {...register("general.tableAlignmentColumnNumber")}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="input-blocks add-product">
                <label>Table Alignment Row Number </label>

                <input
                  type="number"
                  placeholder="Number of rows to set table position"
                  {...register("general.tableAlignmentRowNumber")}
                  className="form-control"
                />
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              data-bs-dismiss="modal"
              ref={closeButtonRef}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SettingsGeneral;
