import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import Select from "react-select";
import {
  Calendar,
  MinusCircle,
} from "feather-icons-react/build/IconComponents";
import toast from "react-hot-toast";
import { ProductClass } from "../../../core/json/Product";
import { getUnitList } from "../../../services/MasterApiServices";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import {
  getStockByBranch,
  addEditStock,
} from "../../../services/StockApiServices";
import { InvStockAdjustment } from "../../../core/json/invStockAdjust";
import { InvStockAdjustmentDetail } from "../../../core/json/invStockAdjustDetail";
import { useSelector, useDispatch } from "react-redux";
import PlusCircle from "feather-icons-react/build/IconComponents/PlusCircle";
import {
  fetchUnit,
  fetchProduct,
  fetchSettings,
} from "../../../redux/userSetting";

const StockAdjustmentModal = ({ mode, data, handleClose, handleRefresh }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: dayjs().format("YYYY-MM-DD"),
    },
    mode: "onBlur",
  });
  const { formatedproductList } = useSelector((state) => state.usersetting);
  const [unitData, setunitData] = useState([]);
  const [products, setProducts] = useState([]);
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const dispatch = useDispatch();
  const [isRowAdded, setIsRowAdded] = useState(false);
  const [decimalpoints, setDecimalPoints] = useState(2);
  const [selectedType, setSelectedType] = useState(0);

  useEffect(() => {
    dispatch(fetchUnit());
    dispatch(fetchProduct(branch));
    dispatch(fetchSettings(branch));
    const match = settingList?.currencyFormat?.toString()?.match(/\.0+/);
    const currfmt = match ? match[0]?.length - 1 : 0;
    setDecimalPoints(currfmt);
  }, []);

  const { productList, unitList, settingList } = useSelector(
    (state) => state.usersetting
  );

  const handleIncrease = (index) => {
    const currentQuantity = getValues(`items[${index}].adjQuantity`);
    const newQuantity = Number(currentQuantity || 0) + 1;
    setValue(`items[${index}].adjQuantity`, newQuantity);
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const handleDecrease = (index) => {
    const currentQuantity = getValues(`items[${index}].adjQuantity`);
    const newQuantity = Number(currentQuantity || 1) - 1;
    setValue(`items[${index}].adjQuantity`, newQuantity);
  };

  const type = [
    { value: 0, label: "Stock Adjustment" },
    { value: 1, label: "Damage" },
    { value: 2, label: "Wastage" },
  ];

  const handleUnitList = async () => {
    try {
      const formattedUnit = unitList?.map((unit) => ({
        label: unit.name,
        value: unit.guid,
      }));
      setunitData(formattedUnit);
    } catch (error) {
      console.error(
        "Error fetching unit",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  const handleEachProductfetch = async (selectedOption, index) => {
    setValue(`items[${index}].product`, selectedOption.value);

    try {
      const productData = await getSingleProduct(selectedOption.value);

      setValue(`items[${index}].code`, productData?.code || 0);
      setValue(`items[${index}].hsnCode`, productData?.hsnCode || 0);
      setValue(`items[${index}].unit`, productData?.unit.guid || 0);
      setValue(`items[${index}].price`, productData?.cost || 0);
    } catch (error) {
      console.error(
        "Error fetching product by GUID:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };

  async function getSingleProduct(guid) {
    try {
      const filteredData = productList?.find(
        (product) => product.guid === guid
      );
      return filteredData;
    } catch (error) {
      console.error(
        "Error fetching products",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  }

  // Fetch purchase invoices data
  const handleEntryNo = async () => {
    try {
      const response = await getStockByBranch(branch);
      const stockData = response?.data || [];
      console.log("stockData", stockData);

      const maxno =
        stockData?.length > 0
          ? stockData
              ?.map((data) => parseInt(data.no.replace(/\D/g, "")))
              ?.reduce((max, num) => Math?.max(max, num), 0)
          : 0;
      let counter = maxno + 1;
      console.log("counter", counter);
      setValue(`no`, counter);
    } catch (error) {
      console.error(
        "Failed to fetch stock adjustments",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };
  const handleAddRow = () => {
    append({
      batch: "0",
      baseQuantity: 0,
      adjQuantity: 0,
      unit: "",
      product: null,
    });
  };
  useEffect(() => {
    if (mode === "add") {
      handleEntryNo();
      if (!isRowAdded && fields?.length === 0) {
        handleAddRow();
        setIsRowAdded(true);
      }
    }
    // handleProductsList();
    handleUnitList();
  }, []);

  function resetmodal() {
    if (mode === "edit" && data) {
      const resetData = {
        no: data.no,
        remarks: data.remarks,
        type: data.type,
        date: dayjs(data.date),
        items: data?.stockAdjustmentDetails?.map((detail) => ({
          product: detail?.product?.guid,
          batch: detail?.batch,
          unit: detail.unit?.guid,
          baseQuantity: detail.baseQuantity,
          adjQuantity: detail.adjQuantity,
        })),
      };

      // Reset the form with the structured data
      reset(resetData);
    } else {
      reset();
      handleEntryNo();
    }
  }
  useEffect(() => {
    resetmodal();
  }, [data, setValue]);

  async function getUnitByGuid(guid) {
    const filteredData = unitList?.filter((unit) => unit.guid === guid);
    return filteredData[0];
  }

  async function getProductByGuid(guid) {
    const filteredData = productList?.filter(
      (product) => product.guid === guid
    );
    return filteredData[0];
  }

  const handleFormSubmit = async (formdata) => {
    console.log("inside form submit");
    console.log(formdata, "formdata-----");
    const objStockAdj = new InvStockAdjustment();
    if (mode === "edit") {
      console.log("edit mode on--------");
      console.log(data.guid, "data.guid");
      objStockAdj.guid = data.guid;
      objStockAdj.id = data.id;
    }
    objStockAdj.branch = branch;
    console.log(formdata.date, "date");
    objStockAdj.date = formdata.date
      ? dayjs(formdata.date).format("YYYY-MM-DDTHH:mm:ss")
      : null;
    objStockAdj.type = formdata.type;
    objStockAdj.updatedUser = `{${userId?.id}}`;
    // objStockAdj.createdUser = `{${userId?.id}}`;
    objStockAdj.remarks = formdata.remarks || "";

    objStockAdj.no = formdata.no ? String(formdata.no) : "0";

    const detailObj = formdata?.items?.map((e) => {
      console.log("Processing item:", e);
      let product;
      const productValue = e.product || e.product.value;
      console.log("productValue", productValue);
      product = productList?.find((item) => item.guid == productValue);
      const unit = unitList.find((item) => item.guid == e.unit);

      let dedQty, addQty, baseQuantity, adjQuantity;
      baseQuantity = Number(e.baseQuantity) || 0;
      adjQuantity = Number(e.adjQuantity) || 0;
      if (formdata.type === 0 && e.baseQuantity > e.adjQuantity) {
        dedQty = e.baseQuantity - e.adjQuantity;
        addQty = 0;
      } else if (formdata.type === 0 && e.baseQuantity < e.adjQuantity) {
        addQty = e.adjQuantity - e.baseQuantity;
        dedQty = 0;
      } else {
        dedQty = 0;
        addQty = 0;
      }
      const detail = {
        product,
        unit,
        batch: e.batch,
        adjQuantity,
        baseQuantity,
        dedQty,
        addQty,
      };
      console.log("Detail object constructed:", detail);

      return detail;
    });
    objStockAdj.stockAdjustmentDetails = detailObj;
    try {
      console.log(objStockAdj, "-------------------objStockAdj");
      const response = await addEditStock(objStockAdj);
      if (response) {
        toast.success("Success");
        handleRefresh();
        handleClose();
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message || "something went wrong");
      console.error(
        "Error handling :",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };
  function handleRemove(index) {
    if (fields.length > 1) {
      remove(index);
    } else {
      toast.error("At least one row entry is required");
    }
  }
  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit Stock Adjustments"
                        : "Add Stock Adjustments"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Entry No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("no")}
                              required
                              //disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>
                              Date
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="date"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    }
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              Type<span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <Controller
                                control={control}
                                name={`type`}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    classNamePrefix="react-select"
                                    options={type}
                                    value={type?.find(
                                      (type) => type?.value === value
                                    )}
                                    onChange={(selectedOption) => {
                                      setSelectedType(selectedOption.value);
                                      onChange(selectedOption.value);
                                    }}
                                    styles={{
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    required
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("remarks")}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive  no-pagination table-container">
                        <table className="table tablezind">
                          <thead>
                            <tr>
                              <th className="sticky-header">
                                Description
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                Unit<span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">Batch</th>
                              <th className="sticky-header">
                                Current Qty
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">
                                {(() => {
                                  let content = "";
                                  if (selectedType === 0) {
                                    content = "New Qty";
                                  } else if (selectedType === 1) {
                                    content = "Damage Qty";
                                  } else if (selectedType === 2) {
                                    content = "Wastage Qty";
                                  } else {
                                    content = "Unknown Type";
                                  }
                                  return content;
                                })()}
                                <span className="text-danger">*</span>
                              </th>
                              <th className="sticky-header">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fields &&
                              fields?.map((obj, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Controller
                                        control={control}
                                        name={`items[${index}].product`}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <Select
                                            classNamePrefix="react-select"
                                            options={formatedproductList}
                                            value={formatedproductList?.find(
                                              (option) => option.value === value
                                            )}
                                            onChange={(selectedOption) => {
                                              onChange(selectedOption.value);
                                              handleEachProductfetch(
                                                selectedOption,
                                                index
                                              );
                                            }}
                                            styles={{
                                              menu: (provided) => ({
                                                ...provided,
                                                zIndex: 9999,
                                                position: "relative",
                                              }),
                                            }}
                                            required
                                          />
                                        )}
                                      />
                                    </td>
                                    <td>
                                      <Controller
                                        control={control}
                                        name={`items[${index}].unit`}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <Select
                                            classNamePrefix="react-select"
                                            options={unitData}
                                            value={unitData?.find(
                                              (option) => option.value === value
                                            )}
                                            onChange={(selectedOption) => {
                                              onChange(selectedOption.value);
                                            }}
                                            isDisabled={true}
                                          />
                                        )}
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        {...register(`items[${index}].batch`)}
                                        disabled
                                      />
                                    </td>

                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        {...register(
                                          `items[${index}].baseQuantity`,
                                          {
                                            required: "Quantity is required",
                                            validate: (value) =>
                                              (!isNaN(value) && value > 0) ||
                                              "Quantity must be a positive number",
                                          }
                                        )}
                                      />
                                      {errors.items &&
                                        errors.items[index]?.baseQuantity && (
                                          <span className="text-danger small">
                                            {
                                              errors.items[index].baseQuantity
                                                .message
                                            }
                                          </span>
                                        )}
                                    </td>
                                    <td>
                                      <div className="product-quantity">
                                        <span
                                          className="quantity-btn"
                                          onClick={() => handleDecrease(index)}
                                        >
                                          <MinusCircle className="feather-search" />
                                        </span>
                                        <input
                                          style={{ minWidth: "3rem" }}
                                          type="text"
                                          className="form-control"
                                          defaultValue={1}
                                          {...register(
                                            `items[${index}].adjQuantity`,
                                            {
                                              required:
                                                "Adjustment Quantity is required",
                                              validate: (value) =>
                                                (!isNaN(value) && value > 0) ||
                                                "Adjustment Quantity must be a positive number",
                                            }
                                          )}
                                        />

                                        <span
                                          className="quantity-btn"
                                          onClick={() => handleIncrease(index)}
                                        >
                                          <PlusCircle className="plus-circle" />
                                        </span>
                                      </div>
                                      {errors.items &&
                                        errors.items[index]?.adjQuantity && (
                                          <span className="text-danger small">
                                            {
                                              errors.items[index].adjQuantity
                                                .message
                                            }
                                          </span>
                                        )}
                                    </td>
                                    <td>
                                      <Link
                                        className="confirm-text p-2"
                                        onClick={(index) => handleRemove(index)}
                                      >
                                        <i
                                          data-feather="trash-2"
                                          className="feather-trash-2"
                                        ></i>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-2 ps-0 ">
                        <button
                          className=" btn btn-dark add-icon tab  mt-3 "
                          type="button"
                          onClick={handleAddRow}
                        >
                          <PlusCircle className="feather-plus-circles" />
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-end">
                          <button
                            type="button"
                            className="btn btn-cancel add-cancel me-3"
                            onClick={() => resetmodal()}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-submit add-sale"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StockAdjustmentModal;
