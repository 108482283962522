import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { savesettings } from '../../services/AdminApiServices';
import toast from 'react-hot-toast';

const SettingOther = () => {
    const { register, handleSubmit, setValue } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
      });
     
      const { branchsettings } = useSelector((state) => state.settings);
      const { branch } = useSelector((state) => state.branchlist);
    
    
      useEffect(() => {
        setValue("general", {
          timeToClearPoleDisplay: branchsettings?.timeToClearPoleDisplay,
          timeToRefreshPayScreen: branchsettings?.timeToRefreshPayScreen,
          totalAmountForDiscount: branchsettings?.totalAmountForDiscount,
          enableBillSearchByTable: branchsettings?.enableBillSearchByTable,
          tableAlignmentRowNumber: branchsettings?.tableAlignmentRowNumber,
          tableAlignmentColumnNumber: branchsettings?.tableAlignmentColumnNumber,
          showSaleAmountInPoleDisplay: branchsettings?.showSaleAmountInPoleDisplay,
          enableLoyalty: branchsettings?.enableLoyalty,
          enableChairInSale: branchsettings?.enableChairInSale,
          orderProductByReferenceCode: branchsettings?.orderProductByReferenceCode,
          showChangeSectionButtonInPay: branchsettings?.showChangeSectionButtonInPay,
          showSplitButtonInPay: branchsettings?.showSplitButtonInPay,
          showMergeButtonInPay: branchsettings?.showMergeButtonInPay,
          showPaxPopupInSale: branchsettings?.showPaxPopupInSale,
          numberOfKOTPrints: branchsettings?.numberOfKOTPrints,
          applicationBackgroundImageNumber: branchsettings?.applicationBackgroundImageNumber,
          changeApplicationDesign: branchsettings?.changeApplicationDesign,
          hideModifierDetailsInKOTPrint: branchsettings?.hideModifierDetailsInKOTPrint,
          enableDataAutoSyncWithServer: branchsettings?.enableDataAutoSyncWithServer,
          enableVirtualKeyboard: branchsettings?.enableVirtualKeyboard,
          enableCess: branchsettings?.enableCess,
          enableCounterSection: branchsettings?.enableCounterSection,
          isStockProduct: branchsettings?.isStockProduct,
          showUnitPriceOfProductInSale: branchsettings?.showUnitPriceOfProductInSale,
          enablePrintInKitchenManager: branchsettings?.enablePrintInKitchenManager,
          kitchenManagerPrinter: branchsettings?.kitchenManagerPrinter,
          disableMultipleOrderInTable: branchsettings?.disableMultipleOrderInTable,
          viewSalesOrderFromTable: branchsettings?.viewSalesOrderFromTable,
          showCategoryAsCoursewiseInKOT: branchsettings?.showCategoryAsCoursewiseInKOT,
          enableWaiterwiseLogin: branchsettings?.enableWaiterwiseLogin,
          generateQrCodeInBillPrint: branchsettings?.generateQrCodeInBillPrint,
          sendMailWhileDayClose: branchsettings?.sendMailWhileDayClose,
          emailToSendDailyCloseReport: branchsettings?.emailToSendDailyCloseReport,
          arrangePhoneNumberAboveNameInCustomerMenu: branchsettings?.arrangePhoneNumberAboveNameInCustomerMenu,
          disableTaxEditInCreditBill: branchsettings?.disableTaxEditInCreditBill,
          currencyFormat: branchsettings?.currencyFormat,
          dateFormat: branchsettings?.dateFormat,
          defaultUserType: branchsettings?.defaultUserType,
          enableGeneralKeypad: branchsettings?.enableGeneralKeypad,
          generateSaleBillPrint: branchsettings?.generateSaleBillPrint,
          numberOfDaysExtendForBillingAfterDailyClose: branchsettings?.numberOfDaysExtendForBillingAfterDailyClose,
          numberOfDaysForEditBill: branchsettings?.numberOfDaysForEditBill,
          generateSalesInvoiceInDotMatrixPrinter: branchsettings?.generateSalesInvoiceInDotMatrixPrinter,
          hideItemwiseSummaryInDailyClose: branchsettings?.hideItemwiseSummaryInDailyClose,
          hideSectionSummaryInDailyClose: branchsettings?.hideSectionSummaryInDailyClose,
          showDateInDailyClose: branchsettings?.showDateInDailyClose,
          billNumberSeriesType: branchsettings?.billNumberSeriesType,
          enableArabicMenuDescription: branchsettings?.enableArabicMenuDescription,
          defaultState: branchsettings?.defaultState,
          enableProductNameBilling: branchsettings?.enableProductNameBilling,
          generateKOTOnCashInsale: branchsettings?.generateKOTOnCashInsale,
          showAllProductsInKOTPrint: branchsettings?.showAllProductsInKOTPrint,
          generateKOTPrint: branchsettings?.generateKOTPrint,
          showCancelReasonPopupInPay: branchsettings?.showCancelReasonPopupInPay,
          disableTaxInSale: branchsettings?.disableTaxInSale,
          disableLastSaleBillPrintOnEnter: branchsettings?.disableLastSaleBillPrintOnEnter,
          hideInvoiceRePrintMenu: branchsettings?.hideInvoiceRePrintMenu,
          showWaiterPopupOnSaveSale: branchsettings?.showWaiterPopupOnSaveSale,
          enableContinuousBillNumberInSale: branchsettings?.enableContinuousBillNumberInSale,
          enableVAT: branchsettings?.enableVAT,
          generateDailyClosePrint: branchsettings?.generateDailyClosePrint,
          showCreditButtonInSales: branchsettings?.showCreditButtonInSales,
          showTaxButtonInSaleForSpecialCustomer: branchsettings?.showTaxButtonInSaleForSpecialCustomer,
          changeProductDetailsWhileBilling: branchsettings?.changeProductDetailsWhileBilling,
          enableTokenNumber: branchsettings?.enableTokenNumber,
          enableSectionWiseTokenNumber: branchsettings?.enableSectionWiseTokenNumber,
          enableOtherLanguage: branchsettings?.enableOtherLanguage,
          showSaleBillInAllCounters: branchsettings?.showSaleBillInAllCounters,
          showSaleBillInAllCounterAndSections: branchsettings?.showSaleBillInAllCounterAndSections,
          showSaleBillInAllSections: branchsettings?.showSaleBillInAllSections,
          deliverySection: branchsettings?.deliverySection,
          showTablePopupInSection: branchsettings?.showTablePopupInSection,
          enableDepartmentInSection: branchsettings?.enableDepartmentInSection,
          disableSalePrintInPayScreen: branchsettings?.disableSalePrintInPayScreen,
          enableShiftClose: branchsettings?.enableShiftClose,
          generatePrintInCashButton: branchsettings?.generatePrintInCashButton,
          enableDailyCloseSummaryPrint: branchsettings?.enableDailyCloseSummaryPrint,
          showWaiterPopupInSection: branchsettings?.showWaiterPopupInSection,
          showCustomerPopupInSection: branchsettings?.showCustomerPopupInSection,
          dailyClosePrintFormat: branchsettings?.dailyClosePrintFormat,
          showFieldToEnterArabicDescriptionInProduct: branchsettings?.showFieldToEnterArabicDescriptionInProduct,
          roundOffInSale: branchsettings?.roundOffInSale,
          comboType: branchsettings?.comboType,
          showKeyBoardForEnterQuantityManually: branchsettings?.showKeyBoardForEnterQuantityManually,
          ledDisplayPort: branchsettings?.ledDisplayPort,
          enablePoleDisplayAutoClear: branchsettings?.enablePoleDisplayAutoClear,
          primaryCurrencyGUID: branchsettings?.primaryCurrencyGUID,
          defaultFinancialYearGuID: branchsettings?.defaultFinancialYearGuID,
        })
      }, [branchsettings,branch])
      const closeButtonRef = useRef();

      const closemodal = () => {
        if (closeButtonRef.current) {
          closeButtonRef.current.click();
        }
      };
      const handleGeneralSettings=async(data)=>{
        
        try {
          const response = await savesettings({setting: data.general, branch: branch});
          if(response==true){
            toast.success("success")
            closemodal()
          }
       
        } catch (error) {
          console.error("Error fetching states:", error);
        }
      }
    return (
        <div className="modal-body custom-modal-body">
            <form onSubmit={handleSubmit(handleGeneralSettings)}>
                <div className='form_body'>
                    <div className="row">
                        <label><b>LED Display: </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks add-product">
                                <label>LED Display Port</label>
                                <input type="text" placeholder='Option to enter the port number of customer display'
                                {...register("general.ledDisplayPort")} className="form-control"  />

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="input-blocks add-product">
                                <label>
                                Time to clear LED display in seconds </label>
                                <select name="" id="" className="form-control" {...register("general.timeToClearPoleDisplay")}>
                                <option value="" disabled >Select</option>
                                <option value="1" >1</option>
                                <option value="2" >2</option>
                                <option value="3" >3</option>
                                <option value="4" >4</option>
                                <option value="5" >5</option>
                                <option value="6" >6</option>
                                <option value="7" >7</option>
                                <option value="8" >8</option>
                                <option value="9" >9</option>
                                <option value="10" >10</option>
                                
                              </select>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showSaleAmountInPoleDisplay")}  />
                                <label className='m-3'>Show sale amount in LED display</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            Enabling this setting will show sales amount in LED display
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.enablePoleDisplayAutoClear")}  />
                                <label className='m-3'>Enable LED display auto clear</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            Enabling this setting will automatically clear sale amount from LED display
                        </div>
                        <hr />
                    </div>

                    <div className="row">
                        <label><b>Arrange customer menu fields: </b> </label>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.arrangePhoneNumberAboveNameInCustomerMenu")}  />
                                <label className='m-3'>Arrange phone number above name in customer menu</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            Normally name field will shows as first input in customer menu. When this setting enabled you can arrange phone number above name as first input field
                        </div>

                        <hr />
                    </div>
                    <div className="row">
                        <label><b>Arabic description in product: </b> </label>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.showFieldToEnterArabicDescriptionInProduct")}  />
                                <label className='m-3'>Show field to enter arabic description in product</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            When this setting enabled, a fieldwill show in product form to enter product description in arabic
                        </div>

                        <hr />
                    </div>
                    <div className="row">
                        <label><b>Stock Product: </b> </label>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.isStockProduct")}  />
                                <label className='m-3'>Is stock product</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            When this setting enabled, a dropdown for selecting erp product will show in product
                        </div>

                        <hr />
                    </div>
                    <div className="row">
                        <label><b>Product Ordering : </b> </label>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.OrderProductByReferenceCode")}  />
                                <label className='m-3'>Order product by reference code</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            Enabling this setting will show products in the order of reference code in sale screen
                        </div>
                        <label><b>Selection popup in section: </b> </label>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.ShowWaiterPopupInSection")}  />
                                <label className='m-3'>Show waiter selection in section</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            When this setting enabled, a checkbox to enable waiter popup in sale will show in section form
                        </div>



                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.ShowCustomerPopupInSection")}  />
                                <label className='m-3'>Show customer selection in section</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            When this setting enabled, a checkbox to enable customer popup in sale will show in section form
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex">
                                <input type="checkbox" {...register("general.ShowTablePopupInSection")}  />
                                <label className='m-3'>Show table selection in section</label>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 general_decription">
                            When this setting enabled, a checkbox to enable table popup in sale will show in section form
                        </div>


                        <hr />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="modal-footer-btn">
                        <button
                            type="button"
                            className="btn btn-cancel me-2"
                            data-bs-dismiss="modal"
                            ref={closeButtonRef}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SettingOther
