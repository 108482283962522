import React, { useEffect, useRef, useState } from "react";
import { fetchUserByBranch } from "../../../redux/userServices";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategorybyBranch,
  getGroupEntrybyBranch,
  getUserbyBranch,
} from "../../../services/CoreApiServices";
import { Trash2 } from "feather-icons-react/build/IconComponents";
import toast from "react-hot-toast";
import { BranchMapper } from "../../../core/json/BranchMapper";
import { branchTransfer } from "../../../services/AdminApiServices";
import { fetchAllGroup } from "../../../redux/getallmatser";
import { fetchGroup } from "../../../redux/userSetting";

const GroupEntryBranchTransfer = () => {
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [desUsers, setDesUsers] = useState([]);
  const [desBranch, setDesBranch] = useState("");

  const { branch } = useSelector((state) => state.branchlist);
  const { BranchByUser } = useSelector((state) => state.userBranch);
  const { groupgetall } = useSelector((state) => state.getallmaster);
  const { userId } = useSelector((state) => state.userauth);

  useEffect(() => {
    dispatch(fetchAllGroup());
  }, []);
  const handleAddSelect = (id) => {
    if (selectedData.includes(id)) {
      setSelectedData(selectedData?.filter((item) => item !== id));
    } else {
      setSelectedData([...selectedData, id]);
    }
  };

  const handleDestination = (e) => {
    setDesBranch(e.target.value);
    handleDesBranch(e.target.value);
  };

  const handleDesBranch = async (id) => {
    try {
      const response = await getGroupEntrybyBranch({ id });
      setDesUsers(response);
      console.log(response);
      setSelectedList([]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSelected = () => {
    if (!desBranch) {
      toast.error("Please select a destination branch");
    } else {
      const newSelected = selectedData?.filter((e) =>
        desUsers?.length > 0 ? !desUsers.some((obj) => obj.guid === e) : true
      );
      const newSelectedList = groupgetall?.filter((user) =>
        newSelected.includes(user.guid)
      );
      setSelectedList(newSelectedList);
    }
  };

  const handleAddAll = () => {
    if (!desBranch) {
      toast.error("Please select a destination branch");
    } else {
      const newSelected = groupgetall?.filter((e) =>
        desUsers?.length > 0
          ? !desUsers.some((obj) => obj.guid === e.guid)
          : true
      );
      setSelectedList(newSelected);
    }
  };

  const handleDelete = (id) => {
    const updatedList = selectedList?.filter((e) => e.guid !== id);
    setSelectedList(updatedList);
  };

  const handleBranchTranfer = async () => {
    if (!selectedList?.length) {
      toast.error("No groups selected for transfer.");
      return;
    }

    const destinationBranch = BranchByUser?.find(
      (e) => e.branch?.guid === desBranch
    )?.branch;

    // Check if source and destination branches are the same
    // if (branch.guid === desBranch) {
    //   toast.error("Source and destination branches cannot be the same.");
    //   return;
    // }

    const SelectedEntity = selectedList.map((e) => e.guid);

    const mapperObj = new BranchMapper();
    mapperObj.EntityID = 2;
    mapperObj.EntityType = "group";
    mapperObj.SelectedEntities = SelectedEntity;
    mapperObj.SourceBranch = branch;
    mapperObj.DestinationBranch = destinationBranch;
    mapperObj.updatedUser = `{${userId?.id}}`;
    mapperObj.createdUser = `{${userId?.id}}`;

    try {
      const response = await branchTransfer(mapperObj);
      if (response === true) {
        toast.success("Transfer successful");
        closeModal();
        dispatch(fetchGroup(branch.guid));
      }
    } catch (error) {
      toast.error("Transfer failed, please try again.");
      console.error("Error fetching states:", error);
    }
  };

  const closeButtonRef = useRef();

  const closeModal = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click();
    }
  };

  return (
    <div>
      <div className="modal fade" id="group-branch-trans">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Group Branch Transfer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeButtonRef}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="col-12 align-items-center justify-content-between">
                  <div className="modal-body custom-modal-body d-flex">
                    <div className="col-md-6 col-sm-12 col-lg-4">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Group</th>
                          </tr>
                        </thead>
                        <div style={{ maxHeight: "200px", overflow: "scroll" }}>
                          <tbody>
                            {groupgetall?.map((obj) => (
                              <tr key={obj?.guid}>
                                <td
                                  className={
                                    selectedData.includes(obj?.guid)
                                      ? "branch-transfer-active"
                                      : "branch-transfer"
                                  }
                                  onClick={() => handleAddSelect(obj?.guid)}
                                >
                                  {obj.name}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </div>
                      </table>
                    </div>

                    <div className="col-md-6 col-sm-12 col-lg-4">
                      <div>
                        <button
                          className="btn btn-submit"
                          onClick={handleAddSelected}
                        >
                          Add Selected
                        </button>
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-submit"
                          onClick={handleAddAll}
                        >
                          Add All
                        </button>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12 col-lg-4">
                      <label htmlFor="">Destination</label>
                      <select
                        className="form-control"
                        onChange={handleDestination}
                        value={desBranch}
                      >
                        <option value="" disabled key="">
                          ---Select Branch---
                        </option>
                        {BranchByUser?.map((obj) => (
                          <option value={obj.branch.guid} key={obj.id}>
                            {obj?.branch.name}
                          </option>
                        ))}
                      </select>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Group</th>
                          </tr>
                        </thead>
                        <div style={{ maxHeight: "200px", overflow: "scroll" }}>
                          <tbody>
                            {selectedList?.map((obj) => (
                              <tr key={obj?.guid} className="branch-transfer">
                                <td>{obj.name}</td>
                                <td
                                  style={{ fontSize: "10px", color: "red" }}
                                  onClick={() => handleDelete(obj?.guid)}
                                >
                                  <Trash2
                                    style={{
                                      fontSize: "inherit",
                                      color: "inherit",
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                            {desUsers &&
                              desUsers?.map((obj) => (
                                <tr key={obj?.guid} className="branch-transfer">
                                  <td>{obj.name}</td>
                                  <td></td>
                                </tr>
                              ))}
                          </tbody>
                        </div>
                      </table>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="modal-footer-btn mb-4">
                      <button
                        type="submit"
                        className="btn btn-submit"
                        onClick={handleBranchTranfer}
                        disabled={!selectedList?.length}
                        // disabled={
                        //   !selectedList?.length || branch.guid === desBranch
                        // }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupEntryBranchTransfer;
