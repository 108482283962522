import React, { useEffect, useRef, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  Download,
  File,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Archive,
  User,
  Calendar,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { Table } from "antd";
import { setheadertoggle } from "../../../redux/toggle";
import { useDispatch, useSelector } from "react-redux";
import { getPandLreport } from "../../../services/ReportApiService";
import {
  DatatablePagingParameter,
  Filterclass,
} from "../../../core/json/DatatablePagingParameters";
import { renderTooltip } from "../../../redux/helper";
import { Checkbox } from "antd";
import { DatePicker } from "antd";
// import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { fetchCounter, fetchSection } from "../../../redux/userSetting";
import { fetchUser } from "../../../redux/userServices";
import {
  exportExcel,
  exportPDF,
  printReport,
} from "../../../core/utils/StockReportUtils";
import LoadingUtils from "../../../core/utils/LoadingUtils";
import {
  exportpandlExcel,
  exportpandlPDF,
  printpandlReport,
} from "../../../core/utils/PandLexport";

const PandLReport = () => {
  const [searchText, setSearchText] = useState("");
  const [TpandlData, setTpandlData] = useState();
  const [pandlData, setpandlData] = useState();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [TradeCount, setTradeCount] = useState();
  const [pandlCount, setpandlCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branchlist);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const tableRef = useRef(null);

  useEffect(() => {
    handlegetsalesReport(currentPage, pageSize);
  }, [branch]);

  const handlegetsalesReport = async (page, size) => {
    const DatatablePagingParameters = new DatatablePagingParameter();
    DatatablePagingParameters.PageNumber = 1;
    DatatablePagingParameters.PageSize = -1;
    // const formattedfromDate = "2024-10-01T00:00:00";
    // const formattedtoDate = "2024-11-30T00:00:00";
    const formattedfromDate = dayjs(fromDate).format("YYYY-MM-DD");
    const formattedtoDate = dayjs(toDate).format("YYYY-MM-DD");
    const Filters = new Filterclass();
    Filters.Branch = branch;
    Filters.FromDate = formattedfromDate;
    Filters.ToDate = formattedtoDate;
    try {
      const response = await getPandLreport({
        DatatablePagingParameters,
        Filters,
      });

      const tdPandL = response?.data?.tdPandL;
      const pandL = response?.data?.pandL;

      const tmaxLength = Math.max(
        tdPandL.pandLExpense.length,
        tdPandL.pandLIncome.length
      );
      const maxLength = Math.max(
        pandL.pandLExpense.length,
        pandL.pandLIncome.length
      );
      const tdataSource = Array.from({ length: tmaxLength }, (_, index) => ({
        expParticulars: tdPandL.pandLExpense[index]?.expParticulars,
        expAmount: tdPandL.pandLExpense[index]?.expAmount,
        incmParticulars: tdPandL.pandLIncome[index]?.incmParticulars,
        incmAmount: tdPandL.pandLIncome[index]?.incmAmount,
      }));
      const dataSource = Array.from({ length: maxLength }, (_, index) => ({
        expParticulars: pandL.pandLExpense[index]?.expParticulars,
        expAmount: pandL.pandLExpense[index]?.expAmount,
        incmParticulars: pandL.pandLIncome[index]?.incmParticulars,
        incmAmount: pandL.pandLIncome[index]?.incmAmount,
      }));
      setTpandlData(tdataSource);
      setpandlData(dataSource);

      setTradeCount(tdataSource?.length);
      setpandlCount(dataSource?.length);
    } catch (error) {
      console.error(
        "Error Fetching Account Type!",
        error?.response?.data?.Message
      );
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const columns = [
    {
      title: "Particulars",
      dataIndex: "expParticulars",
    },
    {
      title: "Amount",
      dataIndex: "expAmount",
    },

    {
      title: "Particulars",
      dataIndex: "incmParticulars",
    },

    {
      title: "Amount",
      dataIndex: "incmAmount",
    },
  ];

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);

    setToDate(null); // reset to date when from date changes
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };
  console.log(TpandlData);

  const modifiedList = TpandlData?.filter((obj) => {
    const searchMatch =
      obj?.expParticulars?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      obj?.incmParticulars?.toLowerCase()?.includes(searchText?.toLowerCase());

    return searchMatch;
  });
  const modifiedpandList = pandlData?.filter((obj) => {
    const searchMatch =
      obj?.expParticulars?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      obj?.incmParticulars?.toLowerCase()?.includes(searchText?.toLowerCase());

    return searchMatch;
  });
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };

    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);

    // Return the filtered data, columns, and field mapping
    return {
      data1: modifiedList,
      data2: modifiedpandList,
      columns: columns,
      fieldMapping,
    };
  };
  const totalTexpens = modifiedList?.reduce(
    (sum, record) => sum + (record.expAmount || 0),
    0
  );
  const Tincome = modifiedList?.reduce(
    (sum, record) => sum + (record.incmAmount || 0),
    0
  );
  const TfooterTotals = {
    expAmount: totalTexpens?.toFixed(2) || 0,
    incmAmount: Tincome?.toFixed(2) || 0,
  };
  const totalexpens = modifiedpandList?.reduce(
    (sum, record) => sum + (record.expAmount || 0),
    0
  );
  const income = modifiedpandList?.reduce(
    (sum, record) => sum + (record.incmAmount || 0),
    0
  );
  const footerTotals = {
    expAmount: totalexpens?.toFixed(2) || 0,
    incmAmount: income?.toFixed(2) || 0,
  };

  const title = "P and L Report";
  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    setSearchText("");
    handlegetsalesReport(currentPage, pageSize);
  };
  return (
    <div>
      <LoadingUtils loading={loading} />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Profit and Loss Report</h4>
                <h6>Manage Profit and Loss Report here</h6>
              </div>
            </div>

            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <button
                    onClick={() =>
                      exportpandlPDF(
                        getActiveTabData,
                        footerTotals,
                        TfooterTotals,
                        title
                      )
                    }
                    disabled={loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <button
                    onClick={() =>
                      exportpandlExcel(
                        getActiveTabData,
                        footerTotals,
                        TfooterTotals,
                        title
                      )
                    }
                    disabled={loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="excel-icon"
                    />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <button
                    onClick={() =>
                      printpandlReport(
                        getActiveTabData,
                        footerTotals,
                        TfooterTotals,
                        title
                      )
                    }
                    disabled={loading}
                    className="btn btn-link bg-white rounded-lg border border-silver"
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </button>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters rounded-2 h-100 "
                      onClick={() => {
                        setCurrentPage(1);
                        setPageSize(20);
                        handlegetsalesReport(currentPage, pageSize);
                      }}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    onClick={toggleFilterVisibility}
                    id="filter_search"
                  >
                    <Filter className="filter-icon" />
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
              </div>

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ">
                        <label>From Date</label>
                        <div className="input-groupicon calender-input">
                          <Calendar className="info-img" />
                          <DatePicker
                            className="datetimepicker"
                            id="from-date"
                            placeholderText="From Date"
                            // selected={fromDate}
                            value={fromDate}
                            onChange={handleFromDateChange}
                            startDate={fromDate}
                            selectsStart
                            // dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>To Date</label>
                        <div className="input-groupicon calender-input">
                          <Calendar className="info-img" />
                          <DatePicker
                            id="to-date"
                            placeholderText="To Date"
                            // selected={toDate}
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate}
                            startDate={fromDate}
                            selectsEnd
                            disabled={!fromDate} // disable until from date is selected
                            // dateFormat="dd/MM/yyyy"
                            // className='form-control'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}

              <div className="table-responsive product-list">
                <h4 className="text-center mb-1">Trading Account</h4>
                <Table
                  columns={columns}
                  dataSource={modifiedList}
                  ref={tableRef}
                  id="table-to-export"
                  className="table datanew dataTable no-footer custom-table"
                  pagination={false}
                  summary={() => (
                    <Table.Summary.Row>
                      {columns?.map((col) => (
                        <Table.Summary.Cell key={col.dataIndex}>
                          <b>{TfooterTotals[col.dataIndex] || ""}</b>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  )}
                />
              </div>
              <div className="table-responsive product-list">
                <h4 className="text-center mb-1">Profit or Loss Account</h4>
                <Table
                  columns={columns}
                  dataSource={modifiedpandList}
                  ref={tableRef}
                  id="table-to-export"
                  className="table datanew dataTable no-footer custom-table"
                  pagination={false}
                  summary={() => (
                    <Table.Summary.Row>
                      {columns?.map((col) => (
                        <Table.Summary.Cell key={col.dataIndex}>
                          <b>{footerTotals[col.dataIndex] || ""}</b>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  )}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};
export default PandLReport;
