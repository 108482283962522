import axios from "axios";
import { getBaseUrl } from "../config";

// const base_path = getBaseUrl();
const base_path = window.APP_CONFIG?.BASE_URL
// const base_path = process.env.REACT_APP_API_URL;

//----------purchase api calls-------------

export const deletePurchase = async (purchaseData) => {
  console.log(purchaseData);
  const response = await axios.post(
    `${base_path}/Api/Purchase/Delete`,
    purchaseData
  );
  return response;
};
// Create a new purchase
export const addEditPurchase = async (purchaseData) => {
  console.log(JSON.stringify(purchaseData), "---------purchaseData--");
  console.log(purchaseData, "---------purchaseData--");
  const response = await axios.post(
    `${base_path}/Api/Purchase/SaveOrUpdate`,
    JSON.stringify(purchaseData)
  );
  return response;
};

//Get purchase invoice by transaction type and branch
export const getPurchaseByTransType = async (branch, transType) => {
  const response = axios.get(
    `${base_path}/Api/Purchase/GetAllByTransType?globalBranchID=${branch?.guid}&TransType=${transType}`
  );
  return response;
};
