// /src/core/utils/reportUtils.js
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

// Utility function to generate table headers
const generateTableHeaders = (columns) => {
  return columns
    ?.map((col) => col.title)
    .filter((header) => header !== "Actions" && header !== "Sl No");
};

// Utility function to generate table body rows
const generateTableBody = (data, tableHeaders, fieldMapping) => {
  return data?.map((item) => {
    return tableHeaders?.map((header) => {
      const field = fieldMapping[header];
      let value = field?.includes(".")
        ? field.split(".").reduce((obj, key) => obj && obj[key], item)
        : item[field];
      if (header === "Invoice Date") {
        console.log(
          "Invoice Date".toLowerCase()?.includes("date") && value,
          "------------------jjhgquweguvqweh"
        );
      }
      if (header.toLowerCase()?.includes("date") && value) {
        value = dayjs(value).format("DD-MM-YY");
      }

      if (field === "products") {
        value = item?.product?.name;
      }
      if (field === "units") {
        value = item?.unit?.name;
      }
      if (field === "salesPaymentType") {
        switch (value) {
          case 0:
            value = "Cash";
            break;
          case 1:
            value = "Credit";
            break;
          case 2:
            value = "UPI";
            break;
          case 3:
            value = "Card";
            break;
          default:
            value = "Unknown";
        }
      }
      if (field === "purchasePaymentType") {
        switch (value) {
          case 0:
            value = "Cash";
            break;
          case 1:
            value = "Credit";
            break;
          case 2:
            value = "UPI";
            break;
          case 3:
            value = "Card";
            break;
          default:
            value = "Unknown";
        }
      }
      if (field === "type") {
        switch (value) {
          case 0:
            value = "Stock Adjustment";
            break;
          case 1:
            value = "Damage";
            break;
          case 2:
            value = "Wastage";
            break;
          default:
            value = "Unknown";
        }
      }

      return value;
    });
  });
};

// Excel Export
export const exportExcel = (getActiveTabData, footerTotals,name) => {
  const { data, columns, fieldMapping } = getActiveTabData();
  const tableHeaders = generateTableHeaders(columns);
  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);

  const worksheet = XLSX.utils.aoa_to_sheet([tableHeaders, ...tableBody]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

  XLSX.writeFile(workbook, `${name}.xlsx`);
};

// Printable format
export const printReport = (getActiveTabData, footerTotals,name) => {
  const { data, columns, fieldMapping } = getActiveTabData();
  console.log(footerTotals, "footertotals from print");

  const printWindow = window.open("", "_blank");

  // Prepare the document for printing
  printWindow.document.write("<html><head><title>Print Report</title>");
  printWindow.document.write("<style>");
  printWindow.document.write(`
        table {
            width: 100%;
            border-collapse: collapse;
        }
        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }
        th {
            background-color: #f2f2f2;
        }
    `);
  printWindow.document.write("</style></head><body>");
  printWindow.document.write(`<h1>${name}</h1>`);

  const tableHeaders = generateTableHeaders(columns);
  let tableHTML = "<table><thead><tr>";
  tableHeaders.forEach((header) => {
    tableHTML += `<th>${header}</th>`;
  });
  tableHTML += "</tr></thead><tbody>";

  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);
  tableBody.forEach((row) => {
    tableHTML += "<tr>";
    row.forEach((cell) => {
      tableHTML += `<td>${cell !== undefined ? cell : ""}</td>`;
    });
    tableHTML += "</tr>";
  });
  tableHTML += "</tbody></table>";

  printWindow.document.write(tableHTML);
  printWindow.document.write("</body></html>");

  printWindow.document.close();
  printWindow.print();
};

// PDF Export
export const exportPDF = (getActiveTabData, footerTotals,name) => {
  const { data, columns, fieldMapping } = getActiveTabData();

  // Set orientation based on the number of columns (e.g., more than 5 columns -> landscape)
  const orientation = columns?.length > 9 ? "landscape" : "portrait";

  // Initialize jsPDF with the dynamic orientation
  const doc = new jsPDF({
    orientation: orientation,
  });

  // Generate table headers and body
  const tableHeaders = generateTableHeaders(columns);
  const tableBody = generateTableBody(data, tableHeaders, fieldMapping);

  // Use autoTable to export the table
  autoTable(doc, {
    head: [tableHeaders],
    body: tableBody,
  });

  // Save the PDF
  doc.save(`${name}.pdf`);
};
