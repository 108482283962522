import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  PlusCircle,
  RefreshCw,
  ChevronUp,
} from "feather-icons-react/build/IconComponents";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import {
  deletePromoproduct,
  getProductList,
  getPromoProductsList,
} from "../../services/MasterApiServices";
import {
  getPromotionList,
  getSectionList,
} from "../../services/MasterApiServices";
import AddPromoProduct from "../../components/master/modal/AddPromoProduct";
import EditPromoProduct from "../../components/master/modal/EditPromoProduct";
import { PromoProductsClass } from "../../core/json/PromoProductsClass";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import {
  exportExcel,
  exportPDF,
  HandlePrint,
  renderTooltip,
} from "../../redux/helper";
import { fetchProduct, fetchSection } from "../../redux/userSetting";
import {
  exportMasterExcel,
  exportMasterPDF,
  printMasterReport,
} from "../../core/utils/MasterReportUtils";

const PromoProducts = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [PromoProduct, setPromoProduct] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [Promotionlist, setPromotionlist] = useState([]);
  const { branch } = useSelector((state) => state.branchlist);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { userId } = useSelector((state) => state.userauth);
  const { sectionList, productList } = useSelector(
    (state) => state.usersetting
  );
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Promo Products")
    );
  }, []);

  useEffect(() => {
    handlePromoProductList();
    handlePromotionList();
    dispatch(fetchSection(branch.guid));
    dispatch(fetchProduct(branch));
  }, [branch]);
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const modifiedList = PromoProduct?.filter((value) =>
    value.promotion.name?.toLowerCase().includes(searchText?.toLowerCase())
  );

  const handlePromoProductList = async () => {
    try {
      const response = await getPromoProductsList(branch);
      if (Array.isArray(response?.data)) {
        setPromoProduct(response?.data);
      } else {
        setPromoProduct([]);
      }
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePromotionList = async () => {
    try {
      const response = await getPromotionList(branch);
      setPromotionlist(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditClick = async (guid) => {
    try {
      console.log("inside edit");

      // const sectionList =response
      const SinglePromoPrtoduct = PromoProduct?.find((e) => e.guid == guid);

      setUpdateData(SinglePromoPrtoduct); // Set the data for editing
      setModalOpen(true); // Open the modal
    } catch (error) {
      console.error(
        "Error fetching section by GUID:",
        error?.response?.data?.Message || "something went wrong"
      );
    }
  };
  function getPromoProductguid(guid, PromoProduct) {
    const filteredData = Array.isArray(PromoProduct)
      ? PromoProduct?.filter((promo) => promo.guid === guid)
      : [];
    return filteredData[0];
  }

  const handleDeleteClick = (obj) => {
    const promo = PromoProduct?.find((e) => e.id == obj);
    console.log(promo);

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objpromo = new PromoProductsClass();
          objpromo.id = promo.id;
          objpromo.guid = promo.guid;
          objpromo.Branch = branch;
          objpromo.promotion = promo.promotion;
          objpromo.section = promo.section;
          objpromo.promoProductsDetail = promo.promoProductsDetail;
          objpromo.updatedUser = `{${userId?.id}}`; //userId.id;
          const response = await deletePromoproduct(objpromo);
          console.log(objpromo);

          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The promo product has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              dispatch(fetchSection(branch.guid));
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting category:", error);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message,
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const columns = [
    {
      title: "Promotion",
      dataIndex: "name",
      render: (_, obj) => obj?.promotion?.name,
    },
    {
      title: "Section",
      dataIndex: "name",
      render: (_, obj) => obj?.section?.name,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {permit?.edit && (
              <Link
                className="me-2 p-2"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#edit-promo-product"
                onClick={() => handleEditClick(obj.guid)}
              >
                <i data-feather="edit" className="feather-edit"></i>
              </Link>
            )}
            {permit?.deletion && (
              <Link
                className="confirm-text p-2"
                to="#"
                onClick={() => handleDeleteClick(obj.id)}
              >
                <i data-feather="trash-2" className="feather-trash-2"></i>
              </Link>
            )}
          </div>
        </div>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };

    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);

    // Return the filtered data, columns, and field mapping
    return { data: modifiedList, columns: columns, fieldMapping };
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Promo Products</h4>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link
                    onClick={() =>
                      exportMasterPDF(getActiveTabData, "Promo Product")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link
                    onClick={() =>
                      exportMasterExcel(getActiveTabData, "Promo Product")
                    }
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link
                    onClick={() =>
                      printMasterReport(getActiveTabData, "Promo Product")
                    }
                  >
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={handlePromoProductList}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <ul className="table-top-head"></ul>
            <div className="d-flex purchase-pg-btn">
              {permit?.add && (
                <div className="page-btn">
                  <Link
                    className="btn btn-added"
                    data-bs-toggle="modal"
                    data-bs-target="#add-promo-product"
                  >
                    <PlusCircle className="me-2" />
                    New
                  </Link>
                </div>
              )}
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <line x1={21} y1={21} x2="16.65" y2="16.65" />
                      </svg>
                    </Link>
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                          aria-controls="DataTables_Table_0"
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}

              {/* /Filter */}
              <div className="table-responsive product-list">
                <Table columns={columns} dataSource={modifiedList} />
              </div>
            </div>
          </div>

          {/* /product list */}
        </div>
      </div>
      <AddPromoProduct
        Promotionlist={Promotionlist}
        handlePromoProductList={handlePromoProductList}
      />
      {updateData && (
        <EditPromoProduct
          data={updateData}
          Promotionlist={Promotionlist}
          handlePromoProductList={handlePromoProductList}
        />
      )}
    </div>
  );
};

export default PromoProducts;
