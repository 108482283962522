import { DomainBase } from "./DomainBase";

export class LedgerBranchOpeningDetail extends DomainBase {
  constructor() {
    super();
    this.ledger = null;
    this.credit = 0.0;
    this.debit = 0.0;
    this.refNo = "";
    this.remarks = "";
  }
}
