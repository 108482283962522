import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Settings, User, Award, GitBranch } from "react-feather";
import { all_routes } from "../../Router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBranchList,
  setBranch,
  setBranchId,
  setBranchName,
} from "../../redux/branchlist";
import { initialData } from "../../redux/initailvalues";
import logo from "../../core/img/dine-out-logo.png";
import {
  fetchAMCstatus,
  fetchmenuPermissions,
  fetchspecialpermission,
  setisBetweenAMCPeriod,
  setisTodayOrAfterAMCToDate,
  setremainingDaysToExtension,
} from "../../redux/amcstatus";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { fetchUserRole } from "../../redux/userSetting";

const Header = () => {
  const route = all_routes;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [toggle, SetToggle] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { BranchName, branch } = useSelector((state) => state.branchlist);
  const { BranchByUser } = useSelector((state) => state.userBranch);
  const { UserServicelist } = useSelector((state) => state.userservice);
  const [history, setHistory] = useState([]);
  const {
    amcdetails,
    isBetweenAMCPeriod,
    isTodayOrAfterAMCToDate,
    remainingDaysToExtension,
  } = useSelector((state) => state.amc);
  const { userRoles } = useSelector((state) => state.usersetting);

  const isElementVisible = (element) => {
    return element.offsetWidth > 0 || element.offsetHeight > 0;
  };
 

  useEffect(() => {
    dispatch(fetchUserRole());
    const flag = sessionStorage.getItem("signin");
    if (flag !== "true") {
      navigate("/");
    }

    dispatch(fetchAMCstatus(branch));
  }, []);
  useEffect(() => {
    dispatch(fetchmenuPermissions(UserServicelist.userRole));
    dispatch(fetchspecialpermission(UserServicelist.userRole));
  }, [UserServicelist]);

  useEffect(() => {
    if (location.pathname !== "/renew") {
      setHistory((prev) => [...prev, location.pathname]);
    }
  }, [location]);

  // Function to find the last valid path (excluding '/renew')
  const getLastValidPath = () => {
    const filteredHistory = history.filter((path) => path !== "/renew");
    return filteredHistory.length > 0
      ? filteredHistory[filteredHistory.length - 1]
      : "/";
  };
  console.log(history);

  const currentPath = location.pathname;
  // Navigate based on the AMC period and previous route
  // useEffect(() => {
  //   if (!isBetweenAMCPeriod) {

  //     navigate('/renew');
  //   } else if (isBetweenAMCPeriod && currentPath == '/renew') {
  //     const lastValidPath = getLastValidPath();

  //     navigate(lastValidPath);
  //   }
  // }, [isBetweenAMCPeriod, navigate,branch]);

  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  useEffect(() => {
    const handleAMC = () => {
      // Reset dispatches before calculations
      dispatch(setisBetweenAMCPeriod(false));
      dispatch(setisTodayOrAfterAMCToDate(false));
      dispatch(setremainingDaysToExtension(0));

      // Update the AMC date variables
      const amcFromDate = dayjs(amcdetails?.amcFromDate, "YYYY-MM-DD");
      const amcToDate = dayjs(amcdetails?.amcToDate, "YYYY-MM-DD");
      const amcExtensionDate = dayjs(
        amcdetails?.amcExtensionDate,
        "YYYY-MM-DD"
      );
      const today = dayjs();

      // Check if the new values exist and are valid
      if (
        amcFromDate.isValid() &&
        amcToDate.isValid() &&
        amcExtensionDate.isValid()
      ) {
        // Check if today is greater than AMC From Date and less than AMC Extension Date
        const isBetweenAMCPeriod =
          today.isSameOrAfter(amcFromDate) &&
          today.isSameOrBefore(amcExtensionDate);
        // Check if today is AMC To Date or after
        const isTodayOrAfterAMCToDate = today.isSameOrAfter(amcToDate);

        // Calculate the remaining days to AMC Extension Date
        const remainingDaysToExtension =
          isBetweenAMCPeriod && isTodayOrAfterAMCToDate
            ? amcExtensionDate.diff(today, "day")
            : 0;

      

        // Dispatch updated values
        dispatch(setisBetweenAMCPeriod(isBetweenAMCPeriod));
        dispatch(setisTodayOrAfterAMCToDate(isTodayOrAfterAMCToDate));
        dispatch(setremainingDaysToExtension(remainingDaysToExtension));
      } else {
        console.error("Invalid AMC dates:", {
          amcFromDate,
          amcToDate,
          amcExtensionDate,
        });
      }
    };

    // Call the function if `amcdetails` changes
    if (amcdetails) {
      handleAMC();
    }
  }, [amcdetails, dispatch, branch]);

  useEffect(() => {
    const handleMouseover = (e) => {
      e.stopPropagation();

      const body = document.body;
      const toggleBtn = document.getElementById("toggle_btn");

      if (
        body.classList.contains("mini-sidebar") &&
        isElementVisible(toggleBtn)
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("mouseover", handleMouseover);

    return () => {
      document.removeEventListener("mouseover", handleMouseover);
    };
  }, []);
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    SetToggle((current) => !current);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const sidebarOverlay = () => {
    document?.querySelector(".main-wrapper")?.classList?.toggle("slide-nav");
    document?.querySelector(".sidebar-overlay")?.classList?.toggle("opened");
    document?.querySelector("html")?.classList?.toggle("menu-opened");
  };

  let pathname = location.pathname;

  const exclusionArray = [
    "/reactjs/template/dream-pos/index-three",
    "/reactjs/template/dream-pos/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  const toggleFullscreen = (elem) => {
    elem = elem || document.documentElement;
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };
  const handleBranch = (id, name, branch) => {
    dispatch(fetchAMCstatus(branch));
    //  handleAMC()
    dispatch(setBranchId({ guid: id }));
    dispatch(setBranchName({ name }));
    dispatch(setBranch(branch));
  };
  return (
    <>
      <div className="header">
        {/* Logo */}
        <div
          className={`header-left ${toggle ? "" : "active"}`}
          onMouseLeave={expandMenu}
          onMouseOver={expandMenuOpen}
        >
          <Link to="/dashboard" className="logo logo-normal">
            <img src={logo} alt="" />
          </Link>

          <Link
            id="toggle_btn"
            to="#"
            style={{
              display:
                pathname.includes("tasks") || pathname.includes("pos")
                  ? "none"
                  : pathname.includes("compose")
                  ? "none"
                  : "",
            }}
            onClick={handlesidebar}
          >
            <FeatherIcon icon="chevrons-left" className="feather-16" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#"
          onClick={sidebarOverlay}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu " style={{ justifyContent: "flex-end" }}>
          {/* Select Store */}
          <li className="nav-item dropdown has-arrow main-drop select-store-dropdown">
            <Link
              to="#"
              className="dropdown-toggle nav-link select-store"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter">
                  <ImageWithBasePath
                    src="assets/img/store/store-04.png"
                    alt="Store Logo"
                    className="img-fluid"
                  />
                </span>
                <span className="user-detail">
                  <span className="user-name">
                    {BranchName ? BranchName?.name : "Select Store"}
                  </span>
                </span>
              </span>
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              {BranchByUser?.map((obj) => {
                return (
                  <Link
                    onClick={() =>
                      handleBranch(
                        obj?.branch.guid,
                        obj?.branch?.name,
                        obj?.branch
                      )
                    }
                    className="dropdown-item"
                    key={obj?.branch?.guid}
                  >
                    <ImageWithBasePath
                      src="assets/img/store/store-03.png"
                      alt="Store Logo"
                      className="img-fluid"
                    />{" "}
                    {obj?.branch?.name}
                  </Link>
                );
              })}
            </div>
          </li>
          {/* /Select Store */}

          <li className="nav-item nav-item-box">
            <Link
              to="#"
              id="btnFullscreen"
              onClick={() => toggleFullscreen()}
              className={isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}
            >
              {/* <i data-feather="maximize" /> */}
              <FeatherIcon icon="maximize" />
            </Link>
          </li>

          {/* Notifications */}
          {/* <li className="nav-item dropdown nav-item-box"> */}
          {/* <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              {/* <i data-feather="bell" /> */}
          {/* <FeatherIcon icon="bell" />
              <span className="badge rounded-pill">2</span>
            </Link> */}
          {/* <div className="dropdown-menu notifications"> */}
          {/* <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
                <Link to="#" className="clear-noti">
                  {" "}
                  Clear All{" "}
                </Link>
              </div> */}
          {/* <div className="noti-content">
                <ul className="notification-list">
                  <li className="notification-message active">
                    <Link to="/activities">
                      <div className="media d-flex">
                        <span className="avatar flex-shrink-0">
                          <ImageWithBasePath
                            alt="img"
                            src="assets/img/profiles/avatar-02.jpg"
                          />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            <span className="noti-title">John Doe</span> added
                            new task{" "}
                            <span className="noti-title">
                              Patient appointment booking
                            </span>
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">
                              4 mins ago
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div> */}
          {/* <div className="topnav-dropdown-footer">
                <Link to="/activities">View all Notifications</Link>
              </div> */}
          {/* </div>
          </li> */}
          {/* /Notifications */}

          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter">
                  <ImageWithBasePath
                    src="assets/img/icons/user-icon.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </span>
                <span className="user-detail">
                  <span className="user-name">{UserServicelist?.name}</span>
                  {/* <span className="user-role">Super Admin</span> */}
                </span>
              </span>
            </Link>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <div className="profileset">
                  <span className="user-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avator1.jpg"
                      alt="img"
                    />
                    <span className="status online" />
                  </span>
                  <div className="profilesets">
                    <h6>{UserServicelist?.name}</h6>
                    {/* <h5>Super Admin</h5> */}
                  </div>
                </div>
                <hr className="m-0" />
                <Link
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#default-branch"
                >
                  <GitBranch className="me-2" /> Default Branch
                </Link>
                <Link
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#change-password"
                >
                  <Settings className="me-2" />
                  Change Password
                </Link>
                <Link className="dropdown-item" to={route.aboutus}>
                  <Award className="me-2" />
                  About Us
                </Link>
                <hr className="m-0" />
                <Link className="dropdown-item logout pb-0" to="/signin">
                  <ImageWithBasePath
                    src="assets/img/icons/log-out.svg"
                    alt="img"
                    className="me-2"
                  />
                  Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className=" mobile-user-menu">
          <div className="nav-item dropdown has-arrow main-drop select-store-dropdown">
            <Link
              to="#"
              className="dropdown-toggle nav-link select-store"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter">
                  <ImageWithBasePath
                    src="assets/img/store/store-04.png"
                    alt="Store Logo"
                    className="img-fluid"
                  />
                </span>
                <span className="user-detail">
                  <span className="user-name">
                    {BranchName ? BranchName?.name : "Select Store"}
                  </span>
                </span>
              </span>
            </Link>
            <div className="dropdown-menu ">
              {BranchByUser?.map((obj) => {
                return (
                  <Link
                    onClick={() =>
                      handleBranch(
                        obj?.branch.guid,
                        obj?.branch?.name,
                        obj?.branch
                      )
                    }
                    className="dropdown-item"
                    key={obj?.branch?.guid}
                  >
                    <ImageWithBasePath
                      src="assets/img/store/store-03.png"
                      alt="Store Logo"
                      className="img-fluid"
                    />{" "}
                    {obj?.branch?.name}
                  </Link>
                );
              })}
            </div>
          </div>

          <div className="dropdown">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#default-branch"
              >
                Default Branch
              </Link>
              <Link
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#change-password"
              >
                Change Password
              </Link>
              <Link
                className="dropdown-item"
                to="/Administration/AboutUs/Index"
              >
                About Us
              </Link>
              <Link className="dropdown-item" to="signin">
                Logout
              </Link>
            </div>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
    </>
  );
};

export default Header;
