import axios from "axios";
import { getBaseUrl } from "../config";

// const base_path = getBaseUrl();
const base_path = window.APP_CONFIG?.BASE_URL
// const base_path = process.env.REACT_APP_API_URL;

//get all reports
export const getSalesReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  return response;
};
//get all status reports
export const getSalesStatusReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesStatusReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getitemWiseSalesReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/ItemWiseSalesReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getSalesAuditReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesLogReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  return response;
};

export const getConsolidatedReportSectionwise = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesReportConsol/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  console.log(response, "response");
  return response;
};

//itemwise
export const getConsolidatedReportItemwise = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/ItemWiseConsolReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  console.log(response, "response");
  return response;
};
//itemwise summary sales
export const getSummReportItemwise = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesItemSummaryReport/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  console.log(response, "response");
  return response;
};

//timebasedreport
export const getTimeBasedReport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/SalesReportTimeBased/GetAllLazyPagedData?criteria=${criteriaJsonString}`
  );
  console.log(response, "response");
  return response;
};
export const getstockin = async ({ DatatablePagingParameters, Filters }) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/StockInReport/GetAll?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getstockout = async ({ DatatablePagingParameters, Filters }) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  console.log(criteriaJsonString);
  const response = await axios.get(
    `${base_path}/Api/Reports/StockOutReport/GetAll?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getledgerreport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  const response = await axios.get(
    `${base_path}/Api/Reports/LedgerReport/GetAll?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getdaybookreport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  const response = await axios.get(
    `${base_path}/Api/Reports/DayBook/GetAll?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getstockvalue = async ({ DatatablePagingParameters, Filters }) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  const response = await axios.get(
    `${base_path}/Api/Reports/StockValueReport/GetAll?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getcustomerstatement = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  const response = await axios.get(
    `${base_path}/Api/Reports/Customer/GetAllCustomerStatement?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getsupstatement = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  const response = await axios.get(
    `${base_path}/Api/Reports/Supplier/GetAllSupplierStatement?criteria=${criteriaJsonString}`
  );
  return response;
};
export const getPandLreport = async ({
  DatatablePagingParameters,
  Filters,
}) => {
  var criteriaJsonString = JSON.stringify({
    DatatablePagingParameters,
    Filters,
  });
  const response = await axios.get(
    `${base_path}/Api/FinalAccounts/GetTradingPandL?criteria=${criteriaJsonString}`
  );
  return response;
};
